import { makeStyles } from "@mui/styles";
import React from "react";
import { convertFirstCharIntoSmallCase } from "../../../../../utils/functions";
import { DatePicker } from "antd";
import moment from "moment";
import { dateFormatter } from "../../../../../utils/common";
import Auth from "../../../../../utils/auth";

const useStyles = makeStyles({
  previewSendDiv: {
    width: "250px !important",
  },
  handSignedDiv: {
    marginTop: "-4px",
  },
  back_button_div: {
    paddingLeft: "37%",
    width: "50% !important",
  },
  arrow_sign: {
    float: "left",
  },
  back_icon: {
    marginBottom: "3px",
    marginRight: "1px",
  },
  back_icon_span: {
    marginRight: "75px",
    fontSize: "larger",
  },
});
const CustomActionButtons = ({
  role,
  signers,
  isValidSend,
  handleOpenViewDocumentModal,
  previewByStep,
  onAdditionalMessageModalOpen,
  onHandSignUploadModalOpen,
  taskType = "",
  dataTransaction,
  deals,
  isHandSignedOnly = false,
  updateDocumentObj,
  isValidPreview = null,
  section = null,
  currentSection = null,
  signerFinalSection = null,
  menuType = null
}) => {
  const classes = useStyles();

  const loginEmail = Auth.getInstance().getUserData().email;

  const getSignerByRole = () => {
    switch (role) {
      case 'Selling-Broker':
      case 'Listing-Broker':
        return dataTransaction.co_broker;
      case 'listing-employing-broker':
      case 'selling-employing-broker':
        return dataTransaction.employing_broker;
      case 'Broker':
        return dataTransaction.broker;

      default:
        return dataTransaction[convertFirstCharIntoSmallCase(role)];
    }
  }

  const isValidSection = (section) => {
    return section == null ? false : section > currentSection ? true : false;
  }

  const isInviteHideButtons = () => {
    const isHidden = ['Buyer', 'Co-Buyer', 'Selling-Broker', 'selling-employing-broker'].includes(role)
      ? dataTransaction?.co_broker?.listing_broker_email === loginEmail
      : ['Seller', 'Co-Seller', 'Listing-Broker', 'listing-employing-broker'].includes(role)
        ? dataTransaction?.co_broker?.selling_broker_email === loginEmail
        : false;

    return menuType === 'invitation' && isHidden;
  }

  let currentSigner = deals?.request_signature?.signers?.find(
    (signer) => signer?.request_signer?.role === role
  );

  // if (!currentSigner.is_hand_signed) {
  if (section) {
    currentSigner = currentSigner?.section_signers?.find(
      (signer) => signer?.section == section
    );
    if (!currentSigner) {
      currentSigner = deals?.request_signature?.signers?.find(
        (signer) => signer?.request_signer?.role === role
      );
    }
  }

  return (
    <div className="status-main-block">
      <div className="signer-status-block"></div>
      <div className="signer-button-block">
        <button
          className="uk-button uk-button-default"
          type="button"
          id={`preview-${taskType}-for-esign`}
          onClick={(evt) => {
            let signed_document = signers?.find(
              (signer) => signer?.request_signer?.role === role
            )?.hand_signed_docs?.length
              ? signers?.find((signer) => signer?.request_signer?.role === role)?.hand_signed_docs
              : null;

            if (section && signed_document) {

              if (currentSigner?.is_hand_signed) {
                // if id hand signed true then return max section count hand signed docs
                signed_document = [signed_document?.reduce((prev, current) => (prev && prev?.hand_doc_holder?.section > current?.hand_doc_holder?.section) ? prev : current)]
              } else {
                // else section vise hand signed documnet 
                signed_document = signed_document?.filter((doc) => doc?.hand_doc_holder?.section == section);
              }
            }

            signed_document?.length
              ? handleOpenViewDocumentModal(signed_document)
              : previewByStep(evt, role);
          }}
          disabled={!dataTransaction || (isValidPreview && !isValidPreview(deals.requiredFields)) || isValidSection(section)}
        >
          <i className="fa fa-cloud-download" area-hidden="true"></i>
          &nbsp;Preview
        </button>
        {/* <div>
          <button
            className="uk-button uk-button-primary"
            id={`send-${taskType}-for-esign`}
            onClick={(evt) => onAdditionalMessageModalOpen("send", role)}
            disabled={
              !dataTransaction || (isValidSend   && !isValidSend(deals?.requiredFields))
            }><i className='fa fa-pencil-square-o' area-hidden="true"></i>&nbsp;{isHandSignedOnly? "Send Link":"Send" } 
          </button>
          
        </div> */}
        {!isHandSignedOnly && (
          <div>
            <button
              className="uk-button uk-button-primary"
              // id="send-disbursement-of-funds---seller-for-esign"
              onClick={(evt) => onAdditionalMessageModalOpen("send", role, { ...getSignerByRole(), section: section, finalSection: signerFinalSection })}
              disabled={!dataTransaction || (isValidSend && !isValidSend(deals?.requiredFields)) || isValidSection(section) || isInviteHideButtons()}
            >
              <i className="fa fa-pencil-square-o" area-hidden="true"></i>
              &nbsp;Send
            </button>
          </div>
        )}

        {isHandSignedOnly && (
          <div>
            <button
              className={
                updateDocumentObj
                  ? "uk-button uk-button-primary"
                  : "uk-button uk-button-primary"
              }
              // id="send-disbursement-of-funds---seller-for-esign"
              onClick={(evt) => onAdditionalMessageModalOpen("send", role, { ...getSignerByRole(), section: section, finalSection: signerFinalSection })}
              disabled={!dataTransaction || (isValidSend && !isValidSend(deals?.requiredFields)) || isValidSection(section) || isInviteHideButtons()}
            >
              <i className="fa fa-pencil-square-o" area-hidden="true"></i>
              &nbsp;Send Link
            </button>
          </div>
        )}
        <div className={classes.handSignedDiv}>
          <label className="uk-form-label" htmlFor="buyer">
            Hand Signed?
          </label>
          <DatePicker
            className="uk-input uk-form-width-small"
            style={{ cursor: 'pointer', color: '#000' }}
            format={'MM/DD/YYYY'}
            disabled={!dataTransaction || (isValidSend && (isValidSend && !isValidSend(deals?.requiredFields))) || isValidSection(section) || isInviteHideButtons()}
            onChange={async (date, dateString) => {
                onHandSignUploadModalOpen(role, dateFormatter(dateString), {...getSignerByRole(), section:section, signerFinalSection:signerFinalSection});
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomActionButtons;
