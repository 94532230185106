import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { acceptanceOfVesselsRoles, billOfSaleNotaryRoles, billOfSaleRoles, BuyerClosingStmtRoles, coBrokerComissionRoles, commissionAgreementSellerCoBroker, CYBABuyerFinalStatement, CYBASellerFinalStatement, CYBATransactionSummary, DisbursementOfFundsBuyer, exemptOfBoatSoldRoles, flDealersSalesTaxStmtRoles, nybaPurchaseAndSalesRoles, FLRemovalAffidavit2024, payoffVerification, purchaseAndSaleAddendumRoles, purchaseAndSaleExclusionRoles, PurchaseAndSalesRoles, resolutionToBuyCorpLLC, resolutionToSellCorpLLC, sellerClosingStmtRoles, tradeInVesselRoles, TrialRunWaiverRoles, uscgBillOfSaleRoles, UsPowerOfAttorneyBuyer, UsPowerOfAttorneySeller, netToSeller, YBAAPurchaseAndSalesRoles, IYBAPurchaseSaleMakeOffer, warrantyOfTitleWithNotary, flSalesTaxExemptionAffidavitRoles, releaseOfLiabilityAndHoldHarmlessRoles, registrationWaiverRoles, potentialTaxLiabilityRoles, cancellationAndRefundRequestRoles, flHSMV82040ApplicationForCertificateOfTitleVesselRoles, uscg1340BillOfSaleRoles, coBrokerageCommissionAgreementRoles } from "../../../../../constants/documentWiseRoles";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { getTransactionEdocById } from "../../../../../redux/actions/transaction";
import TransactionService from "../../../../../services/TransactionService";
import { capitalizeFirstLetter, convertFirstCharIntoSmallCase, isEmptyObj } from "../../../../../utils/functions";
import AddEsign from "../../../Edit/layouts/forms/AddEsign";
import EsignAddendum from "../../options/steps/EsignAddendum";
import EsignBillOfSale from "../../options/steps/EsignBillOfSale";
import EsignBillOfSaleNotary from "../../options/steps/EsignBillOfSaleNotary";
import EsignBuyerClosing from "../../options/steps/EsignBuyerClosing";
import EsignCoBroker from "../../options/steps/EsignCoBroker";
import EsignExclusion from "../../options/steps/EsignExclusion";
import EsignSellerClosing from "../../options/steps/EsignSellerClosing";
import EsignTradeInVessel from "../../options/steps/EsignTradeInVessel";
import EsignUSCGBillOfSale from "../../options/steps/EsignUSCG1340BillOfSale";
import EsignVesselAcceptance from "../../options/steps/EsignVesselAcceptance";
import EsignYBAAPuchaseAndSale from "../../options/steps/EsignYBAAPuchaseAndSale";
import "./signature.css";
import PierOneAddESign from "../../../Edit/layouts/forms/PierOneAddESign";
import EsignWarrantyOfTitleWithNotary from "../../options/steps/EsignWarrantyOfTitleWithNotary";
import EsignBuyersAsIsAcknowledgement from "../../options/steps/EsignBuyer'sAsIsAcknowledgement";
import EsignAuthorizationOfReleaseOfFunds from "../../options/steps/EsignAuthorizationOfReleaseOfFunds";
import EsignYBAAAcceptanceofVessel from "../../options/steps/EsignYBAAAcceptanceofVessel";
import EsignSurveyWaiver from "../../options/steps/EsignSurveyWaiver";
import EsignTrialRunWaiver from "../../options/steps/EsignTrialRunWaiver";
import { EsignBillOfSaleEngineSingle } from "../../options/steps/EsignBillOfSaleEngineSingle";
import EsignBillOfSaleAllEngines from "../../options/steps/EsignBillOfSaleAllEngines";
import EsignBillOfSaleEngineOneToSix from "../../options/steps/EsignBillOfSaleEngineOneToSix";
import EsignNetToSeller from "../../options/steps/EsignNetToSeller";
import EsignYBAAPurchaseAndSaleAgreementCanada from "../../options/steps/EsignYBAAPurchaseAndSaleAgreementCanada";
import EsignUSPowerOfAttorneyBuyer from "../../options/steps/EsignUSPowerOfAttorneyBuyer";
import EsignUSPowerOfAttorneySeller from "../../options/steps/EsignUSPowerOfAttorneySeller";
import SignatureMessageModal from "../../../../common/SignatureMessageModal";
import EsignAsIsAcceptanceOfVessel from "../../options/steps/EsignAsIsAcceptanceOfVessel";
import EsignLoanPayoffAuthorization from "../../options/steps/EsignLoanPayoffAuthorization";
import { EsignAssignmentOfContract } from "../../options/steps/EsignAssignmentOfContract";
import { EsignFLHSMV82040ApplicationForCertificateOfTitleVessel } from "../../options/steps/EsignFLHSMV82040ApplicationForCertificateOfTitleVessel";
import { EsignCYBAPurchaseAgreement } from "../../options/steps/EsignCYBAPurchaseAgreement";
import { EsignFLPowerOfAttorneyForAVesselOrVesselWithTrailer } from "../../options/steps/EsignFLPowerOfAttorneyForAVesselOrVesselWithTrailer";
import { EsignFLDealersSalesTaxStmt } from "../../options/steps/EsignFLDealersSalesTaxStmt";
import { EsignCYBAPurchaseAgreementAmendment } from "../../options/steps/EsignCYBAPurchaseAgreementAmendment";
import { EsignExemptOfBoatSoldForRemovalFromFLByANonresPurch } from "../../options/steps/EsignExemptOfBoatSoldForRemovalFromFLByANonresPurch";
import { EsignDisbursementOfFundsSeller } from "../../options/steps/EsignDisbursementOfFundsSeller";
import EsignCYBABuyerFinalStatement from "../../options/steps/EsignCYBABuyerFinalStatement";
import { EsignCYBASellerFinalStatement } from "../../options/steps/EsignCYBASellerFinalStatement";
import EsignCYBACounterOffer from "../../options/steps/EsignCYBACounterOffer";
import EsignCYBATransactionSummary from "../../options/steps/EsignCYBATransactionSummary";
import EsignCYBACooperativeAgreement from "../../options/steps/EsignCYBACooperativeAgreement";
import EsignCYBARepairAllowanceAddendum from "../../options/steps/EsignCYBARepairAllowanceAddendum";
import EsignCYBATrialSurvey from "../../options/steps/EsignCYBATrialSurvey";
import EsignCancelationOfPurchaseBuyer from "../../options/steps/EsignCancelationOfPurchaseBuyer";
import EsignPayoffVerification from "../../options/steps/EsignPayoffVerification";
import EsignEscrowAgreementDisbursementAuthorization from "../../options/steps/EsignEscrowAgreementDisbursementAuthorization";
import { EsignBillOfSaleCommon } from "../../options/steps/EsignBillOfSaleCommon";
import EsignYBAAEscrowAgreement from "../../options/steps/EsignYBAAEscrowAgreement";
import EsignBillOfSaleNotarySellerOnly from "../../options/steps/EsignBillOfSaleNotarySellerOnly";
import EsignYBAAAmendmentToPSA from "../../options/steps/EsignYBAAAmendmentToPSA";
import { EsignYBAAAmendmentToPSAShortForm } from "../../options/steps/EsignYBAAAmendmentToPSAShortForm";
import EsignCounterOffer from "../../options/steps/EsignCounterOffer";
import EsignNYBACounterOffer from "../../options/steps/EsignNYBACounterOffer";
import EsignCommissionAgreementSeller from "../../options/steps/EsignCommissionAgreementSeller";
import EsignCommissionAgreementSellerCoBroker from "../../options/steps/EsignCommissionAgreementSellerCoBroker";
import EsignResolutionToBuyCorpLLC from "../../options/steps/EsignResolutionToBuyCorpLLC";
import EsignResolutionToSaleCorpLLC from "../../options/steps/EsignResolutionToSaleCorpLLC";
import { EsignFLAffidavitForAcceptanceOutsideFlorida } from "../../options/steps/EsignFLAffidavitForAcceptanceOutsideFlorida";
import { EsignFLSalesTaxExemptionAffidavit } from "../../options/steps/EsignFLSalesTaxExemptionAffidavit";
import EsignReleaseOfLiabilityAndHoldHarmless from "../../options/steps/EsignReleaseofLiabilityandHoldHarmless";
import EsignRegistrationWaiver from "../../options/steps/EsignRegistrationWaiver";
import EsignPotentialTaxLiability from "../../options/steps/EsignPotentialTaxLiability";
import EsignCancellationAndRefundRequest from "../../options/steps/EsignCancellationAndRefundRequest";
import EsignNYBAPurchaseAndSales from "../../options/steps/EsignNYBAPurchaseAndSales";
import EsignDealSummary from "../../options/steps/EsignDealSummary";
import EsignAffidavitOfNonExport from "../../options/steps/EsignAffidavitOfNonExport";
import EsignUSCGDeletionRequest from "../../options/steps/EsignUSCGDeletionRequest";
import EsignDisbursementOfFundsBuyer from "../../options/steps/EsignDisbursementOfFundsBuyer";
import EsignFlRemovalAffidavit2024 from "../../options/steps/EsignFlRemovalAffidavit2024";
import EsignEscrowAgreement from "../../options/steps/EsignEscrowAgreement";
import Auth from "../../../../../utils/auth";
import EsignTexasPwd143 from "../../options/steps/EsignTexasPwd143";
import EsignTexasPwd144 from "../../options/steps/EsignTexasPwd144";

const Signatures = ({ menuType }) => {

    const dispatch = useDispatch();
    const { id } = useParams();

    const { state: locationState } = useLocation();

    //Fetch transaction from redux state
    const deals = useSelector((state) => state.transactions);
    const dataTransaction = deals.transaction || {};
    const dataEdocuments = dataTransaction.t_edocuments || [];
    const docData = dataEdocuments
        .sort((a, b) => {
            if (a.sort_order == b.sort_order) {
                if (a.duplicate_occurrence) {
                    return a.duplicate_occurrence === b.duplicate_occurrence ? 0 : a.duplicate_occurrence > b.duplicate_occurrence ? 1 : -1;
                } else {
                    return 0
                }
            }
            return a.sort_order > b.sort_order ? 1 : -1
        });
    const docNameArr = docData.map((doc) => doc.task_type);

    const addendumDocArr = dataEdocuments.filter((doc) => doc.task_type == "purchase-&-sale-agreement-addendum");
    const exclusionArr = dataEdocuments.filter((doc) => doc.task_type == "purchase-&-sale-agreement-exclusion");
    const acceptanceArray = dataEdocuments.filter((doc) => doc.task_type == "acceptance-of-vessel");
    const CYBACounterOfferArray = dataEdocuments.filter((doc) => doc.task_type == "cyba-counter-offer");
    const billOfSaleNoatrySellerOnlyArray = dataEdocuments.filter((doc) => doc.task_type == "bill-of-sale---notary-(seller-only)");
    const billOfSaleNoatryArray = dataEdocuments.filter((doc) => doc.task_type == "bill-of-sale---notary");
    const YBAAAmendmentToPSAArray = dataEdocuments.filter((doc) => doc.task_type == "ybaa-amendment-to-psa");
    const YBAAAmendmentToPSAArrayShortForm = dataEdocuments.filter((doc) => doc.task_type == "ybaa-amendment-to-psa-(short-form)");
    const counterOfferArray = dataEdocuments.filter((doc) => doc.task_type == "counter-offer");
    const NYBACounterOffer = dataEdocuments.filter((doc) => doc.task_type == "nyba-counter-offer");
    //Define state for showing document one by one
    const [docState, setDocState] = useState([]);
    const [multiDocState, setMultiDocState] = useState([]);

    //Make a state to create the list of signers for displaying on the drop-down
    const [dropDownRoles, setDropDownRoles] = useState([]);
    const [successDocuments, setSuccessDoc] = useState([]);
    const [failedDocuments, setFailedDoc] = useState([]);
    const [showAdditionalModal, setShowAdditionalModal] = useState(false);
    const [toggleAccordionList, setToggleAccordionList] = useState([]);
    const loginEmail = Auth.getInstance().getUserData().email;
    /**
     * Update the state as dropdownRoles which contains only unique signers array
     * @param {Array} signersArr 
    */
    const makeDropDownSigners = (signersArr) => {

        // if buyer details is hidden from broker then buyer will not include in selectionDropdown
        if (signersArr.includes("buyer")) {
            if ((menuType === "invitation" && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_buyer_hide_by_invited_broker)) {
                signersArr = signersArr.filter(signer => signer !== "buyer");
            }
        }
        // if co_buyer details is hidden from broker then buyer will not include in selectionDropdown
        if (signersArr.includes("co_buyer")) {
            if ((menuType === "invitation" && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_co_buyer_hide_by_invited_broker)) {
                signersArr = signersArr.filter(signer => signer !== "co_buyer");
            }
        }
        // if seller details is hidden from broker then buyer will not include in selectionDropdown
        if (signersArr.includes("seller")) {
            if ((menuType === "invitation" && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_seller_hide_by_invited_broker)) {
                signersArr = signersArr.filter(signer => signer !== "seller");
            }
        }
        // if co_seller details is hidden from broker then buyer will not include in selectionDropdown
        if (signersArr.includes("co_seller")) {
            if ((menuType === "invitation" && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction?.is_co_seller_hide_by_invited_broker)) {
                signersArr = signersArr.filter(signer => signer !== "co_seller");
            }
        }
        // if logger is listing broker then hide selling broker selection
        if (signersArr.includes("selling_broker")) {
            if ((menuType === "invitation" && dataTransaction?.co_broker?.listing_broker_email === loginEmail)) {
                signersArr = signersArr.filter(signer => signer !== "selling_broker");
            }
        }
        // if logger is selling broker then hide listing broker selection
        if (signersArr.includes("listing_broker")) {
            if ((menuType === "invitation" && dataTransaction?.co_broker?.selling_broker_email === loginEmail)) {
                signersArr = signersArr.filter(signer => signer !== "listing_broker");
            }
        }

        setDropDownRoles((state) => (
            sortRoles([
                ...new Set([...state, ...signersArr])
            ])
        ))
    }

    /**
     * Returns the sorted array with values like
     * Buyer
     * Co-Buyer
     * Seller
     * Co-Seller
     * Broker
     * @param {*} roles 
     */
    const sortRoles = (roles) => {
        const clinetRoles = ['buyer', 'co_buyer', 'listing_broker', 'listing_employing_broker', 'seller', 'co_seller', 'selling_broker', 'selling_employing_broker', 'broker', 'both'];
        let selectedRoles = [];
        clinetRoles.map(role => {
            if (roles.includes(`${role}`)) {
                selectedRoles.push(role);
            }
        });
        return selectedRoles
    }

    const [doc, setSelectedDoc] = useState([]);

    useEffect(() => {
        if (locationState?.task_type) {
            setToggleAccordionList((state) => [...state, locationState.task_type]);
        }
    }, [locationState?.task_type])

    //UseEffect to start the timer of the document to be rendered
    useEffect(() => {
        startTimer();
        multiDocTImer();
    }, [])

    //Function to make a null state
    const nullState = () => {
        setDocState([]);
        setMultiDocState([]);
        setDocumentObj({});
        startTimer();
        multiDocTImer();
    }

    //Fuction is used for rendering document after some amount of time.
    const startTimer = () => {
        docNameArr.map((doc, index) => {
            setTimeout(() => {
                setDocState((state) => [...state, doc])
            }, index * 0.00001)
        })
    }

    const multiDocTImer = () => {
        [...addendumDocArr,
        ...exclusionArr,
        ...acceptanceArray,
        ...CYBACounterOfferArray,
        ...billOfSaleNoatrySellerOnlyArray,
        ...billOfSaleNoatryArray,
        ...YBAAAmendmentToPSAArray,
        ...YBAAAmendmentToPSAArrayShortForm,
        ...counterOfferArray,
        ...NYBACounterOffer
        ].map((doc, index) => {
            setTimeout(() => {
                setMultiDocState((state) => [...state, doc?.id])
            }, index * 0.00005)
        })
    }

    //Make a state for the payload
    const [documentObj, setDocumentObj] = useState({});

    //Make a state to select role from role dropdown
    const [selectedRole, setSelectedRole] = useState('');

    // State for the selected role to send multiple document email
    const [signerData, setSignerData] = useState({})

    //Function for update the payload
    const updateDocumentObj = (obj, role, isDeleted = false, isCheckboxChange = false) => {

        //Check payload for the particular role is editable or not
        const isEditable = !documentObj[role]?.some((doc) => doc.document_name == obj[role]?.document_name && doc.id == obj[role].id);
        if (isEditable) {
            let arr = documentObj[role] || [];

            arr.push(obj[role]);
            const filteredArr = arr.filter((el) => el.id);
            setDocumentObj({
                [role]: filteredArr
            })

            setSelectedDoc(filteredArr.map((el) => el.document_name));

            if (!isCheckboxChange) {
                setToggleAccordionList((prev) =>
                    ([...prev, ...filteredArr.map((el) => el.document_name)].filter((el, index, arr) => arr.indexOf(el) === index))
                );
            }

            return;
        }

        //If deleted true then remove that particular document
        if (isDeleted) {
            if (documentObj[role]?.filter((doc) => doc.document_name != obj[role]?.document_name || doc.id != obj[role].id).length == 0) {
                setDocumentObj({});
                setSelectedDoc([]);
                return;
            }
            setDocumentObj((state) => {
                return {
                    ...state,
                    [role]: documentObj[role]?.filter((doc) => doc.document_name != obj[role]?.document_name || doc.id != obj[role].id)
                }
            });
            setSelectedDoc(documentObj[role]?.filter((doc) => doc.document_name != obj[role]?.document_name || doc.id != obj[role].id))
            return;
        }
    }

    //Fuction for the update role state
    const handleSelectedRole = (event) => {
        setToggleAccordionList([]);
        setSelectedDoc([]);
        setSelectedRole(event.target.value == 'both' ? 'selling_broker' : event.target.value);
        nullState();
    }

    /**
     * @param {String} string 
     * @returns It will convert `-` into ` ` and also campitalize all words
     */
    const capitalize = (string) => {
        const arr = string.split("-");
        let a = []
        for (let name of arr) {
            a.push(name.charAt(0).toUpperCase() + name.slice(1))
        }
        return a.join(" ")
    }

    //Fucntion for the send signature request with payload
    const sendSignature = async (additionalContent) => {
        try {
            let payload = {}
            payload = renameKey(documentObj, Object.keys(documentObj)[0], capitalizeFirstLetter(Object.keys(documentObj)[0]));
            payload["additional_email_content"] = additionalContent.additional_email_content || '';
            payload["selected_recipients"] = additionalContent.selected_recipients || [];
            dispatch(setLoadingStatus(true));
            if ('Selling-Employing-Broker' in payload) {
                payload['Selling-Employing-Broker'.toLowerCase()] = payload['Selling-Employing-Broker'];
                delete payload['Selling-Employing-Broker'];
            }
            if ('Listing-Employing-Broker' in payload) {
                payload['Listing-Employing-Broker'.toLowerCase()] = payload['Listing-Employing-Broker']
                delete payload['Listing-Employing-Broker'];
            }
            const { data } = await TransactionService.sendSignatures("purchase-and-sale", payload);
            let { successDocument, failedDocument } = data;
            setSuccessDoc(successDocument.map((docName) => capitalize(docName)));
            setFailedDoc(failedDocument.map((docName) => capitalize(docName)));
            setTimeout(() => {
                setSuccessDoc([]);
                setFailedDoc([]);
            }, 15000);
            nullState();
            setSelectedRole("");
            setToggleAccordionList([])
            // getTransactionEdocuments();
            dispatch(setLoadingStatus(false));
            dispatch(getTransactionEdocById(id, menuType === 'invitation', dataTransaction.broker?.company_id))
                .then(() => {
                    dispatch(setLoadingStatus(false));
                })
                .catch(() => {
                    dispatch(setLoadingStatus(false));
                });
        } catch (error) {
            nullState();
            setSelectedRole("")
            dispatch(setLoadingStatus(false));
            switch (error.status) {
                case 401:
                    toast.error("You are not authorized to access this action.")
                    break;
                default:
                    toast.error("Error occurs, please try again.", {
                        autoClose: 2000
                    })
                    break;
            }
        }
        setSelectedDoc([]);
    }

    const getTransactionEdocuments = () => {
        dispatch(setLoadingStatus(true));
        dispatch(getTransactionEdocById(id, menuType === 'invitation', dataTransaction.broker?.company_id))
            .then((response) => {
                dispatch(setLoadingStatus(false));
            }).catch((error) => {
                console.log(error);
                dispatch(setLoadingStatus(false));
            });
    }

    const getSignerByRole = (role) => {
        switch (role) {
            case 'selling_broker':
            case 'listing_broker':
                return dataTransaction.co_broker;

            case 'employing_broker':
            case 'selling_employing_broker':
            case 'listing_employing_broker':
                return dataTransaction.employing_broker;

            default:
                return dataTransaction[convertFirstCharIntoSmallCase(role)];
        }
    }

    const onAdditionalMessageModalOpen = () => {
        const role = Object.keys(documentObj)[0];
        setSignerData({ role: capitalizeFirstLetter(role), data: { ...getSignerByRole(role), section: documentObj[role][0].section, final_section_no: documentObj[role][0].final_section_no } });
        setShowAdditionalModal(true);
    }

    const onAdditionalMessageModalClose = (isEmailSend, emailAdditionalContent = null) => {
        setShowAdditionalModal(false);
        setSignerData({});
        if (isEmailSend) {
            sendSignature({ ...emailAdditionalContent.data })
        }
    }

    function renameKey(obj, oldKey, newKey) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
        return obj
    }

    useEffect(() => {
        // dispatch(setLoadingStatus(true));
        // dispatch(getTransactionEdocById(id))
        //     .then(() => {
        //         dispatch(setLoadingStatus(false));
        //     })
        //     .catch(() => {
        //         dispatch(setLoadingStatus(false));
        //     })
        getTransactionEdocuments();
    }, [window.location.href]);

    let arr = [];

    /**
     * is document exist in list of opened document
     * @param {*} docName 
     * @returns 
     */
    const isDocumentOpenClose = (docName) => {
        return toggleAccordionList.includes(docName);
    }

    /**
     * on accordion open/close and add/remove document name 
     * @param {*} docName 
     * @returns 
     */
    const onToggleAccordion = (docName) => {
        setToggleAccordionList((state) => {
            return state.includes(docName) ? state.filter(doc => doc != docName) : [...state, docName];
        });
    }

    return (
        <>
            <div className="uk-container uk-container-small uk-position-relative">
                <div className="form-container">
                    <div className="row align-items-center mb-3 pb-2">
                        <div className="col-12 col-md-12 col-lg-6 col-xl-4 mb-3">
                            <h2 className="mb-0">Send for eSignature</h2>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-4 text-center mb-2">
                            <div className="half-column uk-padding-small">
                                <select
                                    className="uk-select"
                                    onChange={handleSelectedRole}
                                    value={dropDownRoles.includes('both') && selectedRole == 'selling_broker' ? 'both' : selectedRole}>
                                    <option key={`role_dfault`} value="">Select a role</option>
                                    {
                                        dropDownRoles?.length > 0 &&
                                        dropDownRoles.map((docName, index) => (
                                            <option key={`${docName}_${index}`} value={docName}>{capitalizeFirstLetter(docName)}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-4 btn-div">
                            <button className="uk-button uk-button-primary" disabled={isEmptyObj(documentObj)} onClick={onAdditionalMessageModalOpen}>Send Selected</button>
                            <button className="uk-button uk-button-dark" onClick={getTransactionEdocuments}><i class="fa fa-refresh" aria-hidden="true"></i> Refresh</button>
                        </div>
                    </div>
                    <div className="doc_div pt-3">
                        {
                            successDocuments.length > 0 &&
                            successDocuments.map((doc) => (
                                <div className="uk-alert uk-alert-primary" data-uk-alert>
                                    <p className="text-capitalize" >{doc}</p>
                                </div>
                            ))
                        }
                        {
                            failedDocuments.length > 0 &&
                            failedDocuments.map((doc) => (
                                <div className="uk-alert uk-alert-danger" data-uk-alert>
                                    <p>{doc}</p>
                                </div>
                            ))
                        }
                    </div>
                    <ul uk-accordion="multiple:true;animation:false">
                        {
                            docData.map((docObj) => {
                                if (arr.includes(docObj.task_type)) {
                                    return <></>
                                }
                                arr.push(docObj.task_type);
                                switch (docObj.task_type) {
                                    case "purchase-&-sale-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("purchase-&-sale-agreement") && docNameArr.includes("purchase-&-sale-agreement") &&
                                                    <li className={toggleAccordionList.includes("purchase-&-sale-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("purchase-&-sale-agreement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "purchase-&-sale-agreement")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("purchase-&-sale-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <AddEsign
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={IYBAPurchaseSaleMakeOffer}
                                                                menuType={menuType}
                                                                taskType={'purchase-&-sale-agreement'}
                                                                taskTitle={'Purchase & Sale Agreement'}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "iyba-purchase-&-sale---make-offer":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("iyba-purchase-&-sale---make-offer") && docNameArr.includes("iyba-purchase-&-sale---make-offer") &&
                                                    <li className={toggleAccordionList.includes("iyba-purchase-&-sale---make-offer") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("iyba-purchase-&-sale---make-offer")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "iyba-purchase-&-sale---make-offer")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("iyba-purchase-&-sale---make-offer") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <AddEsign
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={IYBAPurchaseSaleMakeOffer}
                                                                menuType={menuType}
                                                                taskType={'iyba-purchase-&-sale---make-offer'}
                                                                taskTitle={'IYBA Purchase & Sale - Make Offer'}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "ybaa-purchase-and-sale-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("ybaa-purchase-and-sale-agreement") && docNameArr.includes("ybaa-purchase-and-sale-agreement") &&
                                                    <li className={toggleAccordionList.includes("ybaa-purchase-and-sale-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("ybaa-purchase-and-sale-agreement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "ybaa-purchase-and-sale-agreement")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("ybaa-purchase-and-sale-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignYBAAPuchaseAndSale
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={YBAAPurchaseAndSalesRoles}
                                                                menuType={menuType}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "purchase-&-sale-agreement-addendum":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("purchase-&-sale-agreement-addendum") && docNameArr.includes("purchase-&-sale-agreement-addendum") &&
                                                    <li className={toggleAccordionList.includes("purchase-&-sale-agreement-addendum") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("purchase-&-sale-agreement-addendum")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "purchase-&-sale-agreement-addendum")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("purchase-&-sale-agreement-addendum") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                {addendumDocArr.map((doc) => (
                                                                    multiDocState.includes(doc?.id) &&
                                                                    <EsignAddendum
                                                                        updateDocumentObj={updateDocumentObj}
                                                                        makeDropDownSigners={makeDropDownSigners}
                                                                        payload={documentObj}
                                                                        selectedRole={selectedRole}
                                                                        docId={doc.id}
                                                                        menuType={menuType}
                                                                        roles={purchaseAndSaleAddendumRoles}
                                                                    />
                                                                ))}
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "purchase-&-sale-agreement-exclusion":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {docState.includes("purchase-&-sale-agreement-exclusion") && docNameArr.includes("purchase-&-sale-agreement-exclusion") &&
                                                    <li className={toggleAccordionList.includes("purchase-&-sale-agreement-exclusion") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("purchase-&-sale-agreement-exclusion")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "purchase-&-sale-agreement-exclusion")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("purchase-&-sale-agreement-exclusion") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                {exclusionArr.map((doc) => (
                                                                    multiDocState.includes(doc?.id) &&
                                                                    <EsignExclusion
                                                                        updateDocumentObj={updateDocumentObj}
                                                                        makeDropDownSigners={makeDropDownSigners}
                                                                        payload={documentObj}
                                                                        selectedRole={selectedRole}
                                                                        docId={doc.id}
                                                                        menuType={menuType}
                                                                        roles={purchaseAndSaleExclusionRoles}
                                                                    />
                                                                ))}
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "buyer-closing-statement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("buyer-closing-statement") && docNameArr.includes("buyer-closing-statement") &&
                                                    <li className={toggleAccordionList.includes("buyer-closing-statement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("buyer-closing-statement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "buyer-closing-statement")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("buyer-closing-statement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignBuyerClosing
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={BuyerClosingStmtRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "seller-closing-statement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("seller-closing-statement") && docNameArr.includes("seller-closing-statement") &&
                                                    <li className={toggleAccordionList.includes("seller-closing-statement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("seller-closing-statement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "seller-closing-statement")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("seller-closing-statement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignSellerClosing
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={sellerClosingStmtRoles}
                                                                menuType={menuType}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "trade-in-vessel":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("trade-in-vessel") && docNameArr.includes("trade-in-vessel") &&
                                                    <li className={toggleAccordionList.includes("trade-in-vessel") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("trade-in-vessel")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "trade-in-vessel")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("trade-in-vessel") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignTradeInVessel
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={tradeInVesselRoles}
                                                                menuType={menuType}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "acceptance-of-vessel":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("acceptance-of-vessel") && docNameArr.includes("acceptance-of-vessel") &&
                                                    <li className={toggleAccordionList.includes("acceptance-of-vessel") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("acceptance-of-vessel")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "acceptance-of-vessel")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("acceptance-of-vessel") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                {acceptanceArray.map((doc) => (
                                                                    multiDocState.includes(doc?.id) &&
                                                                    <EsignVesselAcceptance
                                                                        updateDocumentObj={updateDocumentObj}
                                                                        makeDropDownSigners={makeDropDownSigners}
                                                                        payload={documentObj}
                                                                        selectedRole={selectedRole}
                                                                        docId={doc.id}
                                                                        menuType={menuType}
                                                                        roles={acceptanceOfVesselsRoles}
                                                                    />
                                                                ))
                                                                }
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "bill-of-sale":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("bill-of-sale") && docNameArr.includes("bill-of-sale") &&
                                                    <li className={toggleAccordionList.includes("bill-of-sale") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("bill-of-sale")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "bill-of-sale")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("bill-of-sale") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignBillOfSale
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={billOfSaleRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "bill-of-sale---notary":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("bill-of-sale---notary") && docNameArr.includes("bill-of-sale---notary") &&
                                                    <li className={toggleAccordionList.includes("bill-of-sale---notary") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("bill-of-sale---notary")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "bill-of-sale---notary")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "bill-of-sale---notary")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("bill-of-sale---notary") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                {billOfSaleNoatryArray.map((doc) => (
                                                                    multiDocState.includes(doc?.id) &&
                                                                    <EsignBillOfSaleNotary
                                                                        updateDocumentObj={updateDocumentObj}
                                                                        makeDropDownSigners={makeDropDownSigners}
                                                                        payload={documentObj}
                                                                        selectedRole={selectedRole}
                                                                        docId={doc.id}
                                                                        menuType={menuType}
                                                                        roles={billOfSaleNotaryRoles}
                                                                    />
                                                                ))}
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "bill-of-sale---notary-(seller-only)":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("bill-of-sale---notary-(seller-only)") && docNameArr.includes("bill-of-sale---notary-(seller-only)") &&
                                                    <li className={toggleAccordionList.includes("bill-of-sale---notary-(seller-only)") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("bill-of-sale---notary-(seller-only)")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "bill-of-sale---notary-(seller-only)")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "bill-of-sale---notary-(seller-only)")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("bill-of-sale---notary-(seller-only)") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                {billOfSaleNoatrySellerOnlyArray.map((doc) => (
                                                                    multiDocState.includes(doc?.id) &&
                                                                    <EsignBillOfSaleNotarySellerOnly
                                                                        updateDocumentObj={updateDocumentObj}
                                                                        makeDropDownSigners={makeDropDownSigners}
                                                                        payload={documentObj}
                                                                        selectedRole={selectedRole}
                                                                        docId={doc.id}
                                                                        menuType={menuType}
                                                                        roles={UsPowerOfAttorneySeller}
                                                                    />
                                                                ))
                                                                }
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "warranty-of-title---with-notary":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("warranty-of-title---with-notary") && docNameArr.includes("warranty-of-title---with-notary") &&
                                                    <li className={toggleAccordionList.includes("warranty-of-title---with-notary") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("warranty-of-title---with-notary")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "warranty-of-title---with-notary")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "warranty-of-title---with-notary")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("warranty-of-title---with-notary") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignWarrantyOfTitleWithNotary
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={warrantyOfTitleWithNotary}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "uscg-bill-of-sale":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("uscg-bill-of-sale") && docNameArr.includes("uscg-bill-of-sale") &&
                                                    <li className={toggleAccordionList.includes("uscg-bill-of-sale") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("uscg-bill-of-sale")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "uscg-bill-of-sale")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "uscg-bill-of-sale")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("uscg-bill-of-sale") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignUSCGBillOfSale
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={uscg1340BillOfSaleRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )
                                    
                                    case "texas-pwd-144":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("texas-pwd-144") && docNameArr.includes("texas-pwd-144") &&
                                                    <li className={toggleAccordionList.includes("texas-pwd-144") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("texas-pwd-144")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "texas-pwd-144")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "texas-pwd-144")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("texas-pwd-144") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignTexasPwd144
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={uscg1340BillOfSaleRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "texas-pwd-143":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("texas-pwd-143") && docNameArr.includes("texas-pwd-143") &&
                                                    <li className={toggleAccordionList.includes("texas-pwd-143") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("texas-pwd-143")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "texas-pwd-143")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "texas-pwd-143")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("texas-pwd-143") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignTexasPwd143
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={uscg1340BillOfSaleRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "co-broker-commission-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("co-broker-commission-agreement") && docNameArr.includes("co-broker-commission-agreement") &&
                                                    <li className={toggleAccordionList.includes("co-broker-commission-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("co-broker-commission-agreement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "co-broker-commission-agreement")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("co-broker-commission-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignCoBroker
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={coBrokerageCommissionAgreementRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "cyba-cooperative-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("cyba-cooperative-agreement") && docNameArr.includes("cyba-cooperative-agreement") &&
                                                    <li className={toggleAccordionList.includes("cyba-cooperative-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("cyba-cooperative-agreement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "cyba-cooperative-agreement")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("cyba-cooperative-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignCYBACooperativeAgreement
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={coBrokerComissionRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "pier-one-purchase-and-sale-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("pier-one-purchase-and-sale-agreement") && docNameArr.includes("pier-one-purchase-and-sale-agreement") &&
                                                    <li className={toggleAccordionList.includes("pier-one-purchase-and-sale-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("pier-one-purchase-and-sale-agreement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "pier-one-purchase-and-sale-agreement")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("pier-one-purchase-and-sale-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <PierOneAddESign
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={PurchaseAndSalesRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "buyer's-“as-is”-acknowledgement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("buyer's-“as-is”-acknowledgement") && docNameArr.includes("buyer's-“as-is”-acknowledgement") &&
                                                    <li className={toggleAccordionList.includes("buyer's-“as-is”-acknowledgement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("buyer's-“as-is”-acknowledgement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "buyer's-“as-is”-acknowledgement")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("buyer's-“as-is”-acknowledgement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignBuyersAsIsAcknowledgement
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={billOfSaleNotaryRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "ybaa-acceptance-of-vessel":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("ybaa-acceptance-of-vessel") && docNameArr.includes("ybaa-acceptance-of-vessel") &&
                                                    <li className={toggleAccordionList.includes("ybaa-acceptance-of-vessel") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("ybaa-acceptance-of-vessel")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "ybaa-acceptance-of-vessel")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("ybaa-acceptance-of-vessel") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignYBAAAcceptanceofVessel
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={TrialRunWaiverRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "authorization-of-release-of-funds":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("authorization-of-release-of-funds") && docNameArr.includes("authorization-of-release-of-funds") &&
                                                    <li className={toggleAccordionList.includes("authorization-of-release-of-funds") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("authorization-of-release-of-funds")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "authorization-of-release-of-funds")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("authorization-of-release-of-funds") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignAuthorizationOfReleaseOfFunds
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={TrialRunWaiverRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "survey-waiver":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("survey-waiver") && docNameArr.includes("survey-waiver") &&
                                                    <li className={
                                                        toggleAccordionList.includes("survey-waiver") && "uk-open"
                                                    }>
                                                        <a onClick={() => onToggleAccordion("survey-waiver")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "survey-waiver")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("survey-waiver") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignSurveyWaiver
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={UsPowerOfAttorneyBuyer}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    // case for Disbursement Of Funds - Buyer Document
                                    case "disbursement-of-funds---buyer":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("disbursement-of-funds---buyer") && docNameArr.includes("disbursement-of-funds---buyer") &&
                                                    <li className={toggleAccordionList.includes("disbursement-of-funds---buyer") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("disbursement-of-funds---buyer")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "disbursement-of-funds---buyer")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("disbursement-of-funds---buyer") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignDisbursementOfFundsBuyer
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={DisbursementOfFundsBuyer}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    // case for FL Removal Affidavit - 2024 Document
                                    case "fl-removal-affidavit---2024":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("fl-removal-affidavit---2024") && docNameArr.includes("fl-removal-affidavit---2024") &&
                                                    <li className={toggleAccordionList.includes("fl-removal-affidavit---2024") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("fl-removal-affidavit---2024")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "fl-removal-affidavit---2024")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("fl-removal-affidavit---2024") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignFlRemovalAffidavit2024
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={FLRemovalAffidavit2024}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )


                                    case "trial-run-waiver":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("trial-run-waiver") && docNameArr.includes("trial-run-waiver") &&
                                                    <li className={toggleAccordionList.includes("trial-run-waiver") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("trial-run-waiver")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "trial-run-waiver")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("trial-run-waiver") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignTrialRunWaiver
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={TrialRunWaiverRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "bill-of-sale---engine-(single)":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("bill-of-sale---engine-(single)") && docNameArr.includes("bill-of-sale---engine-(single)") &&
                                                    <li className={toggleAccordionList.includes("bill-of-sale---engine-(single)") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("bill-of-sale---engine-(single)")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "bill-of-sale---engine-(single)")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("bill-of-sale---engine-(single)") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignBillOfSaleEngineSingle
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={billOfSaleNotaryRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "bill-of-sale---all-engines":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("bill-of-sale---all-engines") && docNameArr.includes("bill-of-sale---all-engines") &&
                                                    <li className={toggleAccordionList.includes("bill-of-sale---all-engines") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("bill-of-sale---all-engines")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "bill-of-sale---all-engines")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("bill-of-sale---all-engines") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignBillOfSaleAllEngines
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={billOfSaleNotaryRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "bill-of-sale---engine-1":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("bill-of-sale---engine-1") && docNameArr.includes("bill-of-sale---engine-1") &&
                                                    <li className={toggleAccordionList.includes("bill-of-sale---engine-1") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("bill-of-sale---engine-1")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "bill-of-sale---engine-1")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("bill-of-sale---engine-1") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignBillOfSaleEngineOneToSix
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={billOfSaleNotaryRoles}
                                                                    menuType={menuType}
                                                                    taskType={'bill-of-sale---engine-1'}
                                                                    taskTitle={'Bill of Sale - Engine 1'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "bill-of-sale---engine-2":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("bill-of-sale---engine-2") && docNameArr.includes("bill-of-sale---engine-2") &&
                                                    <li className={toggleAccordionList.includes("bill-of-sale---engine-2") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("bill-of-sale---engine-2")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "bill-of-sale---engine-2")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("bill-of-sale---engine-2") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignBillOfSaleEngineOneToSix
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={billOfSaleNotaryRoles}
                                                                    menuType={menuType}
                                                                    taskType={'bill-of-sale---engine-2'}
                                                                    taskTitle={'Bill of Sale - Engine 2'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "bill-of-sale---engine-3":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("bill-of-sale---engine-3") && docNameArr.includes("bill-of-sale---engine-3") &&
                                                    <li className={toggleAccordionList.includes("bill-of-sale---engine-3") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("bill-of-sale---engine-3")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "bill-of-sale---engine-3")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("bill-of-sale---engine-3") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignBillOfSaleEngineOneToSix
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={billOfSaleNotaryRoles}
                                                                    menuType={menuType}
                                                                    taskType={'bill-of-sale---engine-3'}
                                                                    taskTitle={'Bill of Sale - Engine 3'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "bill-of-sale---engine-4":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("bill-of-sale---engine-4") && docNameArr.includes("bill-of-sale---engine-4") &&
                                                    <li className={toggleAccordionList.includes("bill-of-sale---engine-4") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("bill-of-sale---engine-4")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "bill-of-sale---engine-4")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("bill-of-sale---engine-4") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignBillOfSaleEngineOneToSix
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={billOfSaleNotaryRoles}
                                                                    menuType={menuType}
                                                                    taskType={'bill-of-sale---engine-4'}
                                                                    taskTitle={'Bill of Sale - Engine 4'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "bill-of-sale---engine-5":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("bill-of-sale---engine-5") && docNameArr.includes("bill-of-sale---engine-5") &&
                                                    <li className={toggleAccordionList.includes("bill-of-sale---engine-5") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("bill-of-sale---engine-5")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "bill-of-sale---engine-5")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("bill-of-sale---engine-5") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignBillOfSaleEngineOneToSix
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={billOfSaleNotaryRoles}
                                                                    menuType={menuType}
                                                                    taskType={'bill-of-sale---engine-5'}
                                                                    taskTitle={'Bill of Sale - Engine 5'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "bill-of-sale---engine-6":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("bill-of-sale---engine-6") && docNameArr.includes("bill-of-sale---engine-6") &&
                                                    <li className={toggleAccordionList.includes("bill-of-sale---engine-6") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("bill-of-sale---engine-6")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "bill-of-sale---engine-6")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("bill-of-sale---engine-6") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignBillOfSaleEngineOneToSix
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={billOfSaleNotaryRoles}
                                                                    menuType={menuType}
                                                                    taskType={'bill-of-sale---engine-6'}
                                                                    taskTitle={'Bill of Sale - Engine 6'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "net-to-seller":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("net-to-seller") && docNameArr.includes("net-to-seller") &&
                                                    <li className={toggleAccordionList.includes("net-to-seller") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("net-to-seller")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "net-to-seller")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("net-to-seller") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignNetToSeller
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={netToSeller}
                                                                menuType={menuType}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "ybaa-purchase-and-sale-agreement-(canada)":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("ybaa-purchase-and-sale-agreement-(canada)") && docNameArr.includes("ybaa-purchase-and-sale-agreement-(canada)") &&
                                                    <li className={toggleAccordionList.includes("ybaa-purchase-and-sale-agreement-(canada)") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("ybaa-purchase-and-sale-agreement-(canada)")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "ybaa-purchase-and-sale-agreement-(canada)")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("ybaa-purchase-and-sale-agreement-(canada)") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignYBAAPurchaseAndSaleAgreementCanada
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={YBAAPurchaseAndSalesRoles}
                                                                menuType={menuType}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "us-power-of-attorney---buyer":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("us-power-of-attorney---buyer") && docNameArr.includes("us-power-of-attorney---buyer") &&
                                                    <li className={toggleAccordionList.includes("us-power-of-attorney---buyer") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("us-power-of-attorney---buyer")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "us-power-of-attorney---buyer")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "us-power-of-attorney---buyer")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("us-power-of-attorney---buyer") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignUSPowerOfAttorneyBuyer
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={UsPowerOfAttorneyBuyer}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "us-power-of-attorney---seller":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("us-power-of-attorney---seller") && docNameArr.includes("us-power-of-attorney---seller") &&
                                                    <li className={toggleAccordionList.includes("us-power-of-attorney---seller") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("us-power-of-attorney---seller")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "us-power-of-attorney---seller")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "us-power-of-attorney---seller")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("us-power-of-attorney---seller") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignUSPowerOfAttorneySeller
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={UsPowerOfAttorneySeller}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "as---is-acceptance-of-vessel":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("as---is-acceptance-of-vessel") && docNameArr.includes("as---is-acceptance-of-vessel") &&
                                                    <li className={toggleAccordionList.includes("as---is-acceptance-of-vessel") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("as---is-acceptance-of-vessel")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "as---is-acceptance-of-vessel")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("as---is-acceptance-of-vessel") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignAsIsAcceptanceOfVessel
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={UsPowerOfAttorneyBuyer}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "loan-payoff-authorization":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("loan-payoff-authorization") && docNameArr.includes("loan-payoff-authorization") &&
                                                    <li className={toggleAccordionList.includes("loan-payoff-authorization") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("loan-payoff-authorization")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "loan-payoff-authorization")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "loan-payoff-authorization")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("loan-payoff-authorization") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignLoanPayoffAuthorization
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={UsPowerOfAttorneySeller}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "assignment-of-contract":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("assignment-of-contract") && docNameArr.includes("assignment-of-contract") &&
                                                    <li className={toggleAccordionList.includes("assignment-of-contract") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("assignment-of-contract")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "assignment-of-contract")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "assignment-of-contract")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("assignment-of-contract") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignAssignmentOfContract
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={tradeInVesselRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "fl-hsmv-82040-application-for-certificate-of-title-(vessel)":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("fl-hsmv-82040-application-for-certificate-of-title-(vessel)") && docNameArr.includes("fl-hsmv-82040-application-for-certificate-of-title-(vessel)") &&
                                                    <li className={toggleAccordionList.includes("fl-hsmv-82040-application-for-certificate-of-title-(vessel)") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("fl-hsmv-82040-application-for-certificate-of-title-(vessel)")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "fl-hsmv-82040-application-for-certificate-of-title-(vessel)")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "fl-hsmv-82040-application-for-certificate-of-title-(vessel)")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("fl-hsmv-82040-application-for-certificate-of-title-(vessel)") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignFLHSMV82040ApplicationForCertificateOfTitleVessel
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={flHSMV82040ApplicationForCertificateOfTitleVesselRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "cyba-purchase-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("cyba-purchase-agreement") && docNameArr.includes("cyba-purchase-agreement") &&
                                                    <li className={toggleAccordionList.includes("cyba-purchase-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("cyba-purchase-agreement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "cyba-purchase-agreement")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("cyba-purchase-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignCYBAPurchaseAgreement
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={purchaseAndSaleAddendumRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )


                                    case "fl-power-of-attorney-for-a-vessel-or-vessel-with-trailer-82053":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("fl-power-of-attorney-for-a-vessel-or-vessel-with-trailer-82053") && docNameArr.includes("fl-power-of-attorney-for-a-vessel-or-vessel-with-trailer-82053") &&
                                                    <li className={toggleAccordionList.includes("fl-power-of-attorney-for-a-vessel-or-vessel-with-trailer-82053") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("fl-power-of-attorney-for-a-vessel-or-vessel-with-trailer-82053")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "fl-power-of-attorney-for-a-vessel-or-vessel-with-trailer-82053")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "fl-power-of-attorney-for-a-vessel-or-vessel-with-trailer-82053")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("fl-power-of-attorney-for-a-vessel-or-vessel-with-trailer-82053") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignFLPowerOfAttorneyForAVesselOrVesselWithTrailer
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={UsPowerOfAttorneySeller}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "fl-dealers-sales-tax-stmt---dr41c":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("fl-dealers-sales-tax-stmt---dr41c") && docNameArr.includes("fl-dealers-sales-tax-stmt---dr41c") &&
                                                    <li className={toggleAccordionList.includes("fl-dealers-sales-tax-stmt---dr41c") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("fl-dealers-sales-tax-stmt---dr41c")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "fl-dealers-sales-tax-stmt---dr41c")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "fl-dealers-sales-tax-stmt---dr41c")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("fl-dealers-sales-tax-stmt---dr41c") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignFLDealersSalesTaxStmt
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={flDealersSalesTaxStmtRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "cyba-purchase-agreement-amendment":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("cyba-purchase-agreement-amendment") && docNameArr.includes("cyba-purchase-agreement-amendment") &&
                                                    <li className={toggleAccordionList.includes("cyba-purchase-agreement-amendment") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("cyba-purchase-agreement-amendment")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "cyba-purchase-agreement-amendment")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("cyba-purchase-agreement-amendment") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignCYBAPurchaseAgreementAmendment
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={purchaseAndSaleAddendumRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "exempt-of-boat-sold-for-removal-from-fl-by-a-nonres-purch---gt500003":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("exempt-of-boat-sold-for-removal-from-fl-by-a-nonres-purch---gt500003") && docNameArr.includes("exempt-of-boat-sold-for-removal-from-fl-by-a-nonres-purch---gt500003") &&
                                                    <li className={toggleAccordionList.includes("exempt-of-boat-sold-for-removal-from-fl-by-a-nonres-purch---gt500003") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("exempt-of-boat-sold-for-removal-from-fl-by-a-nonres-purch---gt500003")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "exempt-of-boat-sold-for-removal-from-fl-by-a-nonres-purch---gt500003")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "exempt-of-boat-sold-for-removal-from-fl-by-a-nonres-purch---gt500003")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("exempt-of-boat-sold-for-removal-from-fl-by-a-nonres-purch---gt500003") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignExemptOfBoatSoldForRemovalFromFLByANonresPurch
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={exemptOfBoatSoldRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "disbursement-of-funds---seller":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("disbursement-of-funds---seller") && docNameArr.includes("disbursement-of-funds---seller") &&
                                                    <li className={toggleAccordionList.includes("disbursement-of-funds---seller") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("disbursement-of-funds---seller")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "disbursement-of-funds---seller")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "disbursement-of-funds---seller")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("disbursement-of-funds---seller") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignDisbursementOfFundsSeller
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={UsPowerOfAttorneySeller}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "cyba-buyer-final-statement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("cyba-buyer-final-statement") && docNameArr.includes("cyba-buyer-final-statement") &&
                                                    <li className={toggleAccordionList.includes("cyba-buyer-final-statement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("cyba-buyer-final-statement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "cyba-buyer-final-statement")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("cyba-buyer-final-statement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignCYBABuyerFinalStatement
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={CYBABuyerFinalStatement}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "cyba-seller-final-statement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("cyba-seller-final-statement") && docNameArr.includes("cyba-seller-final-statement") &&
                                                    <li className={toggleAccordionList.includes("cyba-seller-final-statement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("cyba-seller-final-statement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "cyba-seller-final-statement")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("cyba-seller-final-statement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignCYBASellerFinalStatement
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={CYBASellerFinalStatement}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "cyba-counter-offer":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {docState.includes("cyba-counter-offer") && docNameArr.includes("cyba-counter-offer") &&
                                                    <li className={toggleAccordionList.includes("cyba-counter-offer") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("cyba-counter-offer")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "cyba-counter-offer")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("cyba-counter-offer") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                {CYBACounterOfferArray.map((doc) => (
                                                                    multiDocState.includes(doc?.id) &&
                                                                    <EsignCYBACounterOffer
                                                                        updateDocumentObj={updateDocumentObj}
                                                                        makeDropDownSigners={makeDropDownSigners}
                                                                        payload={documentObj}
                                                                        selectedRole={selectedRole}
                                                                        docId={doc.id}
                                                                        menuType={menuType}
                                                                        roles={acceptanceOfVesselsRoles}
                                                                    />
                                                                ))
                                                                }
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "counter-offer":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {docState.includes("counter-offer") && docNameArr.includes("counter-offer") &&
                                                    <li className={toggleAccordionList.includes("counter-offer") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("counter-offer")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "counter-offer")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("counter-offer") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                {counterOfferArray.map((doc) => (
                                                                    multiDocState.includes(doc?.id) &&
                                                                    <EsignCounterOffer
                                                                        updateDocumentObj={updateDocumentObj}
                                                                        makeDropDownSigners={makeDropDownSigners}
                                                                        payload={documentObj}
                                                                        selectedRole={selectedRole}
                                                                        docId={doc.id}
                                                                        menuType={menuType}
                                                                        roles={acceptanceOfVesselsRoles}
                                                                    />
                                                                ))}
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "ybaa-amendment-to-psa":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("ybaa-amendment-to-psa") && docNameArr.includes("ybaa-amendment-to-psa") &&
                                                    <li className={toggleAccordionList.includes("ybaa-amendment-to-psa") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("ybaa-amendment-to-psa")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "ybaa-amendment-to-psa")?.t_esign_template?.template?.template_name} </b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("ybaa-amendment-to-psa") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                {YBAAAmendmentToPSAArray.map((doc) => (
                                                                    multiDocState.includes(doc?.id) &&
                                                                    <EsignYBAAAmendmentToPSA
                                                                        updateDocumentObj={updateDocumentObj}
                                                                        makeDropDownSigners={makeDropDownSigners}
                                                                        payload={documentObj}
                                                                        selectedRole={selectedRole}
                                                                        docId={doc.id}
                                                                        menuType={menuType}
                                                                        roles={acceptanceOfVesselsRoles}
                                                                    />
                                                                ))
                                                                }
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "ybaa-amendment-to-psa-(short-form)":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("ybaa-amendment-to-psa-(short-form)") && docNameArr.includes("ybaa-amendment-to-psa-(short-form)") &&
                                                    <li className={toggleAccordionList.includes("ybaa-amendment-to-psa-(short-form)") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("ybaa-amendment-to-psa-(short-form)")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "ybaa-amendment-to-psa-(short-form)")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("ybaa-amendment-to-psa-(short-form)") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                {YBAAAmendmentToPSAArrayShortForm.map((doc) => (
                                                                    multiDocState.includes(doc?.id) &&
                                                                    <EsignYBAAAmendmentToPSAShortForm
                                                                        updateDocumentObj={updateDocumentObj}
                                                                        makeDropDownSigners={makeDropDownSigners}
                                                                        payload={documentObj}
                                                                        selectedRole={selectedRole}
                                                                        docId={doc.id}
                                                                        menuType={menuType}
                                                                        roles={acceptanceOfVesselsRoles}
                                                                    />
                                                                ))
                                                                }
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "nyba-counter-offer":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("nyba-counter-offer") && docNameArr.includes("nyba-counter-offer") &&
                                                    <li className={toggleAccordionList.includes("nyba-counter-offer") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("nyba-counter-offer")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "nyba-counter-offer")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("nyba-counter-offer") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                {NYBACounterOffer.map((doc) => (
                                                                    multiDocState.includes(doc?.id) &&
                                                                    <EsignNYBACounterOffer
                                                                        updateDocumentObj={updateDocumentObj}
                                                                        makeDropDownSigners={makeDropDownSigners}
                                                                        payload={documentObj}
                                                                        selectedRole={selectedRole}
                                                                        docId={doc.id}
                                                                        menuType={menuType}
                                                                        roles={acceptanceOfVesselsRoles}
                                                                    />
                                                                ))
                                                                }
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "cyba-transaction-summary":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("cyba-transaction-summary") && docNameArr.includes("cyba-transaction-summary") &&
                                                    <li className={toggleAccordionList.includes("cyba-transaction-summary") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("cyba-transaction-summary")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "cyba-transaction-summary")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("cyba-transaction-summary") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignCYBATransactionSummary
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={CYBATransactionSummary}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "cyba-repair-allowance-addendum":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("cyba-repair-allowance-addendum") && docNameArr.includes("cyba-repair-allowance-addendum") &&
                                                    <li className={toggleAccordionList.includes("cyba-repair-allowance-addendum") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("cyba-repair-allowance-addendum")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "cyba-repair-allowance-addendum")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("cyba-repair-allowance-addendum") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignCYBARepairAllowanceAddendum
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={acceptanceOfVesselsRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "cyba-trial-survey":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("cyba-trial-survey") && docNameArr.includes("cyba-trial-survey") &&
                                                    <li className={toggleAccordionList.includes("cyba-trial-survey") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("cyba-trial-survey")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "cyba-trial-survey")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("cyba-trial-survey") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignCYBATrialSurvey
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={uscgBillOfSaleRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "cancelation-of-purchase---buyer":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("cancelation-of-purchase---buyer") && docNameArr.includes("cancelation-of-purchase---buyer") &&
                                                    <li className={toggleAccordionList.includes("cancelation-of-purchase---buyer") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("cancelation-of-purchase---buyer")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "cancelation-of-purchase---buyer")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("cancelation-of-purchase---buyer") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignCancelationOfPurchaseBuyer
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={uscgBillOfSaleRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "payoff-verification":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("payoff-verification") && docNameArr.includes("payoff-verification") &&
                                                    <li className={toggleAccordionList.includes("payoff-verification") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("payoff-verification")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "payoff-verification")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("payoff-verification") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignPayoffVerification
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={payoffVerification}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "escrow-agreement---disbursement-authorization":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("escrow-agreement---disbursement-authorization") && docNameArr.includes("escrow-agreement---disbursement-authorization") &&
                                                    <li className={toggleAccordionList.includes("escrow-agreement---disbursement-authorization") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("escrow-agreement---disbursement-authorization")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "escrow-agreement---disbursement-authorization")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("escrow-agreement---disbursement-authorization") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignEscrowAgreementDisbursementAuthorization
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={acceptanceOfVesselsRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "ybaa-escrow-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("ybaa-escrow-agreement") && docNameArr.includes("ybaa-escrow-agreement") &&
                                                    <li className={toggleAccordionList.includes("ybaa-escrow-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("ybaa-escrow-agreement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "ybaa-escrow-agreement")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("ybaa-escrow-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignYBAAEscrowAgreement
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={acceptanceOfVesselsRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "bill-of-sale---vessel":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("bill-of-sale---vessel") && docNameArr.includes("bill-of-sale---vessel") &&
                                                    <li className={toggleAccordionList.includes("bill-of-sale---vessel") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("bill-of-sale---vessel")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "bill-of-sale---vessel")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("bill-of-sale---vessel") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignBillOfSaleCommon
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={acceptanceOfVesselsRoles}
                                                                    menuType={menuType}
                                                                    taskType={'bill-of-sale---vessel'}
                                                                    taskTitle={'Bill of Sale - Vessel'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "bill-of-sale---trailer":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("bill-of-sale---trailer") && docNameArr.includes("bill-of-sale---trailer") &&
                                                    <li className={toggleAccordionList.includes("bill-of-sale---trailer") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("bill-of-sale---trailer")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "bill-of-sale---trailer")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("bill-of-sale---trailer") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignBillOfSaleCommon
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={acceptanceOfVesselsRoles}
                                                                    menuType={menuType}
                                                                    taskType={'bill-of-sale---trailer'}
                                                                    taskTitle={'Bill of Sale - Trailer'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "bill-of-sale---tender":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("bill-of-sale---tender") && docNameArr.includes("bill-of-sale---tender") &&
                                                    <li className={toggleAccordionList.includes("bill-of-sale---tender") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("bill-of-sale---tender")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "bill-of-sale---tender")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("bill-of-sale---tender") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignBillOfSaleCommon
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={acceptanceOfVesselsRoles}
                                                                    menuType={menuType}
                                                                    taskType={'bill-of-sale---tender'}
                                                                    taskTitle={'Bill of Sale - Tender'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "bill-of-sale---trade-in":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("bill-of-sale---trade-in") && docNameArr.includes("bill-of-sale---trade-in") &&
                                                    <li className={toggleAccordionList.includes("bill-of-sale---trade-in") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("bill-of-sale---trade-in")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "bill-of-sale---trade-in")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("bill-of-sale---trade-in") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignBillOfSaleCommon
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={acceptanceOfVesselsRoles}
                                                                    menuType={menuType}
                                                                    taskType={'bill-of-sale---trade-in'}
                                                                    taskTitle={'Bill of Sale - Trade In'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "bill-of-sale-trade-in---trailer":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("bill-of-sale-trade-in---trailer") && docNameArr.includes("bill-of-sale-trade-in---trailer") &&
                                                    <li className={toggleAccordionList.includes("bill-of-sale-trade-in---trailer") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("bill-of-sale-trade-in---trailer")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "bill-of-sale-trade-in---trailer")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("bill-of-sale-trade-in---trailer") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignBillOfSaleCommon
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={acceptanceOfVesselsRoles}
                                                                    menuType={menuType}
                                                                    taskType={'bill-of-sale-trade-in---trailer'}
                                                                    taskTitle={'Bill of Sale Trade In - Trailer'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "commission-agreement-(seller)":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("commission-agreement-(seller)") && docNameArr.includes("commission-agreement-(seller)") &&
                                                    <li className={toggleAccordionList.includes("commission-agreement-(seller)") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("commission-agreement-(seller)")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "commission-agreement-(seller)")?.t_esign_template?.template?.template_name}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("commission-agreement-(seller)") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignCommissionAgreementSeller
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={sellerClosingStmtRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "commission-agreement-(seller,co-broker)":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("commission-agreement-(seller,co-broker)") && docNameArr.includes("commission-agreement-(seller,co-broker)") &&
                                                    <li className={toggleAccordionList.includes("commission-agreement-(seller,co-broker)") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("commission-agreement-(seller,co-broker)")} className="uk-accordion-title"><b>{(dataEdocuments.find((doc) => doc.task_type == "commission-agreement-(seller,co-broker)")?.t_esign_template?.template?.template_name)?.replace(',', '/')}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("commission-agreement-(seller,co-broker)") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignCommissionAgreementSellerCoBroker
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={commissionAgreementSellerCoBroker}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "resolutions-to-buy---llc":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("resolutions-to-buy---llc") && docNameArr.includes("resolutions-to-buy---llc") &&
                                                    <li className={toggleAccordionList.includes("resolutions-to-buy---llc") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("resolutions-to-buy---llc")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "resolutions-to-buy---llc")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "resolutions-to-buy---llc")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("resolutions-to-buy---llc") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignResolutionToBuyCorpLLC
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={resolutionToBuyCorpLLC}
                                                                    menuType={menuType}
                                                                    taskType={'resolutions-to-buy---llc'}
                                                                    taskTitle={'Resolutions to Buy - LLC'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "resolution-to-buy---corp":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("resolution-to-buy---corp") && docNameArr.includes("resolution-to-buy---corp") &&
                                                    <li className={toggleAccordionList.includes("resolution-to-buy---corp") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("resolution-to-buy---corp")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "resolution-to-buy---corp")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "resolution-to-buy---corp")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("resolution-to-buy---corp") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignResolutionToBuyCorpLLC
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={resolutionToBuyCorpLLC}
                                                                    menuType={menuType}
                                                                    taskType={'resolution-to-buy---corp'}
                                                                    taskTitle={'Resolution to Buy - Corp'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "resolutions-to-sell---llc":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("resolutions-to-sell---llc") && docNameArr.includes("resolutions-to-sell---llc") &&
                                                    <li className={toggleAccordionList.includes("resolutions-to-sell---llc") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("resolutions-to-sell---llc")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "resolutions-to-sell---llc")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "resolutions-to-sell---llc")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("resolutions-to-sell---llc") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignResolutionToSaleCorpLLC
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={resolutionToSellCorpLLC}
                                                                    menuType={menuType}
                                                                    taskType={'resolutions-to-sell---llc'}
                                                                    taskTitle={'Resolutions to Sell - LLC'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "resolution-to-sell---corp":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("resolution-to-sell---corp") && docNameArr.includes("resolution-to-sell---corp") &&
                                                    <li className={toggleAccordionList.includes("resolution-to-sell---corp") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("resolution-to-sell---corp")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "resolution-to-sell---corp")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "resolution-to-sell---corp")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("resolution-to-sell---corp") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignResolutionToSaleCorpLLC
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={resolutionToSellCorpLLC}
                                                                    menuType={menuType}
                                                                    taskType={'resolution-to-sell---corp'}
                                                                    taskTitle={'Resolution to Sell - Corp'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )
                                    case "fl-affidavit-for-acceptance-outside-florida":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("fl-affidavit-for-acceptance-outside-florida") && docNameArr.includes("fl-affidavit-for-acceptance-outside-florida") &&
                                                    <li className={toggleAccordionList.includes("fl-affidavit-for-acceptance-outside-florida") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("fl-affidavit-for-acceptance-outside-florida")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "fl-affidavit-for-acceptance-outside-florida")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "fl-affidavit-for-acceptance-outside-florida")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("fl-affidavit-for-acceptance-outside-florida") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignFLAffidavitForAcceptanceOutsideFlorida
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={tradeInVesselRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )
                                    case "fl-sales-tax-exemption-affidavit":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("fl-sales-tax-exemption-affidavit") && docNameArr.includes("fl-sales-tax-exemption-affidavit") &&
                                                    <li className={toggleAccordionList.includes("fl-sales-tax-exemption-affidavit") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("fl-sales-tax-exemption-affidavit")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "fl-sales-tax-exemption-affidavit")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "fl-sales-tax-exemption-affidavit")?.t_esign_template?.template?.is_hand_signed ? "( Hand Signed Only )" : ""}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("fl-sales-tax-exemption-affidavit") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignFLSalesTaxExemptionAffidavit
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={flSalesTaxExemptionAffidavitRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )
                                    case "release-of-liability-and-hold-harmless":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("release-of-liability-and-hold-harmless") && docNameArr.includes("release-of-liability-and-hold-harmless") &&
                                                    <li className={toggleAccordionList.includes("release-of-liability-and-hold-harmless") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("release-of-liability-and-hold-harmless")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "release-of-liability-and-hold-harmless")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "release-of-liability-and-hold-harmless")?.t_esign_template?.template?.is_hand_signed ? "( Hand Signed Only )" : ""}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("release-of-liability-and-hold-harmless") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignReleaseOfLiabilityAndHoldHarmless
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={releaseOfLiabilityAndHoldHarmlessRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )
                                    case "registration-waiver":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("registration-waiver") && docNameArr.includes("registration-waiver") &&
                                                    <li className={toggleAccordionList.includes("registration-waiver") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("registration-waiver")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "registration-waiver")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "registration-waiver")?.t_esign_template?.template?.is_hand_signed ? "( Hand Signed Only )" : ""}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("registration-waiver") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignRegistrationWaiver
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={registrationWaiverRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )
                                    case "potential-tax-liability":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("potential-tax-liability") && docNameArr.includes("potential-tax-liability") &&
                                                    <li className={toggleAccordionList.includes("potential-tax-liability") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("potential-tax-liability")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "potential-tax-liability")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "potential-tax-liability")?.t_esign_template?.template?.is_hand_signed ? "( Hand Signed Only )" : ""}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("potential-tax-liability") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignPotentialTaxLiability
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={potentialTaxLiabilityRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )
                                    case "cancellation-and-refund-request":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("cancellation-and-refund-request") && docNameArr.includes("cancellation-and-refund-request") &&
                                                    <li className={toggleAccordionList.includes("cancellation-and-refund-request") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("cancellation-and-refund-request")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "cancellation-and-refund-request")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "cancellation-and-refund-request")?.t_esign_template?.template?.is_hand_signed ? "( Hand Signed Only )" : ""}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("cancellation-and-refund-request") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignCancellationAndRefundRequest
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={cancellationAndRefundRequestRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )
                                    case "nyba-purchase-and-sale-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("nyba-purchase-and-sale-agreement") && docNameArr.includes("nyba-purchase-and-sale-agreement") &&
                                                    <li className={toggleAccordionList.includes("nyba-purchase-and-sale-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("nyba-purchase-and-sale-agreement")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "nyba-purchase-and-sale-agreement")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "nyba-purchase-and-sale-agreement")?.t_esign_template?.template?.is_hand_signed ? "( Hand Signed Only )" : ""}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("nyba-purchase-and-sale-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignNYBAPurchaseAndSales
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={nybaPurchaseAndSalesRoles}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )
                                    case "deal-summary":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("deal-summary") && docNameArr.includes("deal-summary") &&
                                                    <li className={toggleAccordionList.includes("deal-summary") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("deal-summary")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "deal-summary")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "deal-summary")?.t_esign_template?.template?.is_hand_signed ? "( Hand Signed Only )" : ""}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("deal-summary") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignDealSummary
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={CYBATransactionSummary}
                                                                    menuType={menuType}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "affidavit-of-non-export---company":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("affidavit-of-non-export---company") && docNameArr.includes("affidavit-of-non-export---company") &&
                                                    <li className={toggleAccordionList.includes("affidavit-of-non-export---company") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("affidavit-of-non-export---company")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "affidavit-of-non-export---company")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "affidavit-of-non-export---company")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("affidavit-of-non-export---company") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignAffidavitOfNonExport
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={resolutionToSellCorpLLC}
                                                                    menuType={menuType}
                                                                    taskType={'affidavit-of-non-export---company'}
                                                                    taskTitle={'Affidavit of Non Export - Company'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "affidavit-of-non-export---individual":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("affidavit-of-non-export---individual") && docNameArr.includes("affidavit-of-non-export---individual") &&
                                                    <li className={toggleAccordionList.includes("affidavit-of-non-export---individual") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("affidavit-of-non-export---individual")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "affidavit-of-non-export---individual")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "affidavit-of-non-export---individual")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("affidavit-of-non-export---individual") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignAffidavitOfNonExport
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={resolutionToSellCorpLLC}
                                                                    menuType={menuType}
                                                                    taskType={'affidavit-of-non-export---individual'}
                                                                    taskTitle={'Affidavit of Non Export - Individual'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "uscg---deletion-request":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("uscg---deletion-request") && docNameArr.includes("uscg---deletion-request") &&
                                                    <li className={toggleAccordionList.includes("uscg---deletion-request") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("uscg---deletion-request")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "uscg---deletion-request")?.t_esign_template?.template?.template_name} ${dataEdocuments.find((doc) => doc.task_type == "uscg---deletion-request")?.t_esign_template?.template?.is_hand_signed && "( Hand Signed Only )"}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("uscg---deletion-request") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignUSCGDeletionRequest
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={UsPowerOfAttorneyBuyer}
                                                                    menuType={menuType}
                                                                    taskType={'uscg---deletion-request'}
                                                                    taskTitle={'USCG - Deletion Request'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )
                                    case "escrow-holdback-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("escrow-holdback-agreement") && docNameArr.includes("escrow-holdback-agreement") &&
                                                    <li className={toggleAccordionList.includes("escrow-holdback-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("escrow-holdback-agreement")} className="uk-accordion-title"><b>{`${dataEdocuments.find((doc) => doc.task_type == "escrow-holdback-agreement")?.t_esign_template?.template?.template_name}`}</b><label className="pull-right" style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("escrow-holdback-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                <EsignEscrowAgreement
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={acceptanceOfVesselsRoles}
                                                                    menuType={menuType}
                                                                    taskType={'escrow-holdback-agreement'}
                                                                    taskTitle={'Escrow Holdback Agreement'}
                                                                />
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )
                                    default:
                                        break;
                                }
                            })
                        }
                    </ul>

                </div>
            </div>
            <SignatureMessageModal show={showAdditionalModal} onClose={(isEmailSend, emailAdditionalContent = null) => onAdditionalMessageModalClose(isEmailSend, emailAdditionalContent)} emailDetail={signerData} isNoGenerateLink={true} />
        </>
    )
}

export default Signatures