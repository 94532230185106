import { makeStyles } from "@mui/styles";
import React from "react";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { dateFormatter, momentDateWithTimezone } from "../../../../../utils/common";
import { convertFirstCharIntoSmallCase, isSigned } from "../../../../../utils/functions";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';

const useStyles = makeStyles({
  previewSendDiv: {
    width: "250px !important",
  },
  handSignedDiv: {
    marginTop: "-4px",
  },
  back_button_div: {
    paddingLeft: "37%",
    width: "50% !important",
  },
  arrow_sign: {
    float: "left",
  },
  back_icon: {
    marginBottom: "3px",
    marginRight: "1px",
  },
  back_icon_span: {
    marginRight: "75px",
    fontSize: "larger",
  },
});
const CustomRenderStatus = ({
  role,
  signers,
  isValidSend,
  handleOpenViewDocumentModal,
  previewByStep,
  onAdditionalMessageModalOpen,
  onHandSignUploadModalOpen,
  taskType = "",
  dataTransaction,
  deals,
  eDoc,
  getEsignLink,
  renderLinkButtons,
  handleSignedDate,
  signedDate,
  isHandSignedOnly = false,
  updateDocumentObj,
  isValidPreview = null,
  section = null,
  currentSection = null,
  signerFinalSection = null
}) => {
  const classes = useStyles();

  const getSignerByRole = () => {
    switch (role) {
      case 'Selling-Broker':
      case 'Listing-Broker':
        return dataTransaction.co_broker;
      case 'listing-employing-broker':
      case 'selling-employing-broker':
        return dataTransaction.employing_broker;
      case 'Broker':
        return dataTransaction.broker;

      default:
        return dataTransaction[convertFirstCharIntoSmallCase(role)];
    }
  }
  let currentSigner = eDoc.request_signature.signers.find(
    (signer) => signer?.request_signer.role === role
  );
  // if (!currentSigner.is_hand_signed) {
  if (section) {
    currentSigner = currentSigner?.section_signers?.find(
      (signer) => signer?.section == section
    );
    if (!currentSigner) {
      currentSigner = eDoc?.request_signature.signers?.find(
        (signer) => signer?.request_signer?.role === role
      );
    }
  }
  // }

  return (
    <>
      <div className="status-main-block">
        <div className="signer-status-block">
          <label className="uk-form-label" htmlFor="buyer">
            Status
          </label>
          <span>
            {
              currentSigner?.status
            }
            {currentSigner?.status == "Declined" && (
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="right"
                overlay={
                  <Popover id={`popover-positioned-`}>
                    <Popover.Title as="h6">Reason</Popover.Title>
                    <Popover.Content>
                      {
                        currentSigner?.note
                      }
                    </Popover.Content>
                  </Popover>
                }
              >
                <img
                  src="/icons/comment.svg"
                  alt="comment"
                  className="rejectReason"
                />
              </OverlayTrigger>
            )}
          </span>
          <span className="d-block" style={{ fontSize: "10px" }}>
            {currentSigner?.status == "Sent"
              ? `${momentDateWithTimezone(
                currentSigner?.updatedAt
              )}`
              : ""}
          </span>
        </div>
        <div className="signer-button-block">
          <div>
            <button
              className="uk-button uk-button-default"
              type="button"
              id={`preview-${taskType}-for-esign`}
              onClick={(evt) => {
                let signed_document = signers.find(
                  (signer) => signer.request_signer.role === role
                )?.hand_signed_docs?.length
                  ? signers.find(
                    (signer) => signer.request_signer.role === role
                  )?.hand_signed_docs
                  : null;
                if (section && signed_document?.length) {

                  if (currentSigner?.is_hand_signed) {
                    // if id hand signed true then return max section count hand signed docs
                    signed_document = [signed_document?.reduce((prev, current) => (prev && prev?.hand_doc_holder?.section > current?.hand_doc_holder?.section) ? prev : current)]
                  } else {
                    // else section vise hand signed documnet 
                    signed_document = signed_document?.filter((doc) => doc?.hand_doc_holder?.section == section);
                  }
                }

                signed_document?.length
                  ? handleOpenViewDocumentModal(signed_document)
                  : previewByStep(evt, role);
              }}
              disabled={!dataTransaction || (isValidPreview && !isValidPreview(deals.requiredFields)) || (section > currentSection)}
            >
              <i className="fa fa-cloud-download" area-hidden="true"></i>
              &nbsp;Preview
            </button>
          </div>

          {!isHandSignedOnly && (
            <>
              {currentSigner?.status !== 'Signed' && (
                <div className='d-flex flex-column'>
                  <button style={getEsignLink(role) ? { height: '26px', marginBottom: '5px', padding: '0 12px 0' } : {}}
                    className="uk-button uk-button-primary"
                    id="send-pier-one-purchase-and-sale-agreement-for-esign"
                    onClick={(evt) => onAdditionalMessageModalOpen("send", role, { ...getSignerByRole(), section: section, finalSection: signerFinalSection })}
                    disabled={
                      !dataTransaction || !isValidSend(deals.requiredFields) || (section > currentSection)
                    }> <i className='fa fa-pencil-square-o' area-hidden="true"></i>&nbsp;Resend </button>
                  {getEsignLink(role)
                    ? renderLinkButtons(role)
                    : <></>
                  }
                </div>
              )}

              {currentSigner?.status === 'Signed' && (
                <div>
                  <button
                    className="uk-button uk-button-primary"
                    id="send-pier-one-purchase-and-sale-agreement-for-esign"
                    onClick={(evt) => onAdditionalMessageModalOpen("send", role, { ...getSignerByRole(), section: section, finalSection: signerFinalSection })}
                    disabled={true}
                  ><i className='fa fa-pencil-square-o' area-hidden="true"></i>&nbsp;Send
                  </button>
                </div>
              )}
            </>
          )}

          {isHandSignedOnly && (
            <div>
              <button
                className={
                  updateDocumentObj
                    ? "uk-button uk-button-primary"
                    : "uk-button uk-button-primary"
                }
                id="send-us-power-of-attorney-seller-for-esign"
                onClick={(evt) => onAdditionalMessageModalOpen("send", role, { ...getSignerByRole(), section: section, finalSection: signerFinalSection })}
                disabled={
                  !dataTransaction || !isValidSend() || isSigned(signers, role) || (section > currentSection)
                }
              >
                <i className="fa fa-pencil-square-o" area-hidden="true"></i>
                &nbsp;Send Link
              </button>
            </div>
          )}

          <div className={classes.handSignedDiv}>
            <label className="uk-form-label" htmlFor="buyer">
              {(signedDate && !currentSection) || (currentSection && currentSigner.signed_date) ? "Hand Signed" : ""}
              {currentSigner?.status !== "Signed" && "Hand Signed?"}

              {currentSigner?.status === "Signed" ?
                !signedDate || !currentSigner.signed_date ? "Signed" : "" : ""}
            </label>
            {currentSigner?.status === "Signed" ? (
              <>
                <span style={{ fontSize: ".8rem" }}>
                  {handleSignedDate(role, (currentSigner.is_hand_signed && currentSigner.signed_date) ? currentSigner.signed_date : !section && signedDate, section)}
                </span>
                <p style={{ fontSize: ".8rem" }}>
                  Signer IP:{" "}
                  {currentSigner?.section ? currentSigner?.e_signed_from_ip || "N/A" : currentSigner?.signed_from_ip || "N/A"}
                </p>
              </>
            ) : (
              <DatePicker
                className="uk-input uk-form-width-small"
                style={{ cursor: 'pointer', color: '#000' }}
                format={'MM/DD/YYYY'}
                onChange={async (date, dateString) => {
                  onHandSignUploadModalOpen(role, dateFormatter(dateString), { ...getSignerByRole(), section: section, signerFinalSection: signerFinalSection });
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomRenderStatus;
