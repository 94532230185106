import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import FormPage from "../../../../common/FormPage";
import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import AsterixTypo from "../../../../common/AsterixTypo";
import SubmitButton from "../../../../common/buttons/SubmitButton";
import LinkButton from "../../../../common/buttons/LinkButton";
import ThemeButton from "../../../../common/buttons/ThemeButton";
import Country from "../../../../../constants/Country";
import { makeStyles } from "@mui/styles";
import StatesForUSA from "../../../../../constants/StatesForUSA";
import {
  getTransactionInitialById,
  updateTransaction,
  upsertTransaction,
} from "../../../../../redux/actions/transaction";
import { clearClient, deleteClient, findClientByName, getClient, getTransactionClient } from "../../../../../redux/actions/clients";
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";
import AutoCompleteSearch from "../../../../common/Autocomplete";
import PhoneInput from "react-phone-input-2";
import Auth from "../../../../../utils/auth";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import clsx from "clsx";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { CLIENT_REQUIRED_FIELDS } from "../../../../../utils/signer";
// import { phoneFigure, phoneNumber } from "../../../../../utils/phonenumber";

const schema = yup.object().shape({});

const useStyles = makeStyles({
  divider: {
    marginBottom: "15px",
  },
  phone_input: {
    padding: '12px 12px 12px 50px !important',
    width: '100% !important'
  },
  phoneInputDiv: {
    padding: "8px",
    marginLeft: "-7px",
    marginRight: "7px",
    marginTop: "-7px"
  }
});

const AddCoBuyer = ({ step, setStep, asterix }) => {
  /* Define State Variables */
  const classes = useStyles();
  const [isSelectedUSA, setSelectedUSA] = useState(true);
  const [searchName, setSearchName] = useState(null);
  const [isSameAddress, setIsSameAddress] = useState(false);
  const [isCobuyer, setIsCobuyer] = useState(false);

  const [requiredFields, setRequiredFields] = useState(CLIENT_REQUIRED_FIELDS);

  /* Get Datas from Store State */
  const dataClient = useSelector((state) => state.clients.client);
  const dataTransaction = useSelector((state) => state.transactions.transaction);
  const dataBroker = useSelector((state) => dataTransaction?.broker || state.transactions.broker);
  const dataClients = useSelector((state) => state.clients.list);

  const [isEditCoBuyer, setIsEditCoBuyer] = useState(false);
  const logged_user = Auth.getInstance().getUserData();

  const optionsForAutocomplete = dataClients
    .map((client) => {
      return { id: client.id, value: `${client.first_name} ${client.last_name}` };
    })
    .sort((prev, next) => prev.value.localeCompare(next.value))
    .reduce((newArray, cur) => newArray.length && newArray.at(-1).value === cur.value ? newArray : [...newArray, cur], []);

  const dataDeal = dataTransaction || {};

  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, setValue, getValues, reset, watch } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: false,
  });

  useEffect(() => {
    if (dataDeal && dataDeal.co_buyer_contact_id) {
      dispatch(getTransactionClient(dataDeal.co_buyer_contact_id));
      setIsCobuyer(true);
      setIsEditCoBuyer(true);
    } else {
      setValue("country", "");
      setSelectedUSA(true);
      setIsEditCoBuyer(false);
    }
  }, []);

  useEffect(() => {
    if (isSameAddress && dataDeal.buyer_contact_id) {
      const buyer = dataDeal.buyer;
      if (buyer.country === "USA") {
        setSelectedUSA(true);
      }
      setValue("address_1", buyer.address_1);
      setValue("address_2", buyer.address_2);
      setValue("address_3", buyer.address_3);
      // setValue("unit_apt", buyer.unit_apt);
      setValue("country", buyer.country);
      setValue("city", buyer.city);
      setValue("state", buyer.state);
      setValue("postal_code", buyer.postal_code);
    }
    if (!isSameAddress) {
      setValue("address_1", null);
      setValue("address_2", null);
      setValue("address_3", null);
      // setValue("unit_apt", null);
      setValue("country", "");
      setValue("city", null);
      setValue("state", null);
      setValue("postal_code", null);
    }
  }, [isSameAddress]);

  useEffect(() => {
    dispatch(findClientByName());

    return () => {
      dispatch(clearClient());
    };
  }, []);

  useEffect(() => {
    if (dataClient) {
      Object.keys(dataClient).forEach((key) => setValue(key, dataClient[key]));
      requiredFields.map((value) => {
        manageRequireFields(value, getValues(value));
      });
    } else {
      reset({});
      setSelectedUSA(true);
      setValue("country", "");
    }
  }, [dataClient]);

  useEffect(() => {
    setIsSameAddress(dataDeal?.is_buyer_same_address || false);
  }, [dataDeal]);

  // useEffect(() => {
  //   let number = phoneFigure(getValues("mobile_phone"));
  //   setValue("mobile_phone", phoneNumber(number));
  // }, [watch("mobile_phone")]);

  useEffect(() => {
    if (getValues("country") === "USA") {
      setSelectedUSA(true);
    } else {
      setSelectedUSA(false);
    }
  }, [watch("country")]);

  const handleRegistration = (co_buyer, isFinishLater = false) => {
    dispatch(setLoadingStatus(true));
    co_buyer = getFormattedData(co_buyer);
    if (dataDeal && dataDeal.co_buyer_contact_id) {
      co_buyer.id = dataDeal.co_buyer_contact_id;
    }

    if (!isEditCoBuyer) {
      delete co_buyer.id;
    }
    // co_buyer.mobile_phone = phoneFigure(co_buyer.mobile_phone);
    dispatch(
      upsertTransaction({
        step: {
          db: "cobuyer",
          main: {
            ...co_buyer,
            broker_user_id: dataBroker?.id,
            company_id: dataBroker?.company_id,
            company_office_id: dataBroker?.company_office_id,
            createdBy: logged_user?.id,
            updatedBy: logged_user?.id
          },
        },
        transaction: {
          isBelonged: true,
          main: {
            ...getPlainTransactionData(dataDeal),
            task_type: "deal",
            is_buyer_same_address: isSameAddress,
            broker_user_id: dataBroker?.id,
            company_id: dataBroker?.company_id,
            company_office_id: dataBroker?.company_office_id,
          },
          foreignkeyName: "co_buyer_contact_id",
        },
      },"Co-Buyer's")
    ).then((data) => {
      dispatch(setLoadingStatus(false));
      if (isFinishLater) {
        history.push(`/deals/view/${data.id}/main`);
      } else {
        dispatch(getTransactionInitialById(data.id));
        dispatch(clearClient());
        setStep(step + 1);
      }
    }).catch((err) => {
      console.error('ERROR::', err);
      dispatch(setLoadingStatus(false));
    });
  };

  const handleContinue = () => {
    setStep(step + 1);
  };

  const removeCoBuyer = () => {
    if (dataDeal && dataDeal.co_buyer_contact_id) {
      dispatch(deleteClient(dataDeal.co_buyer_contact_id)).finally(() => {
        dispatch(updateTransaction(dataDeal.id, { co_buyer_contact_id: null })).finally(() => {
          setStep(step + 1);
        });
      });
    } else {
      setStep(step + 1);
    }
  };

  const handleSelect = (value, option) => {
    dispatch(getClient(option.id));
  };

  const links = [
    { label: "Deals", link: "/deals" },
    { label: "Add a Deal", link: null },
  ];

  const manageRequireFields = (fieldName, fieldValue) => {
    setRequiredFields(state => {
      if (fieldValue) {
        return state.filter(field => field != fieldName);
      }
      return state.includes(fieldName) ? state : [...state, fieldName];
    })
  }

  const vesselName = `${dataTransaction?.t_vessel?.year || ""} ${dataTransaction?.t_vessel?.make || ""} ${dataTransaction?.t_vessel?.model || ""} ${dataTransaction?.t_vessel?.length || ""}`;
  const uniqueTransactionId = dataTransaction?.unique_transaction_id ? `PS-${dataTransaction?.unique_transaction_id}` : '';

  return (
    <FormPage links={links} title="Co-Buyer" vesselName={vesselName} uniqueId={uniqueTransactionId}>
      <FormRow>
        <FormInputWrapper label="Is there a Co-Buyer/Joint Owner?">
          <div className="radio-toolbar">
            <input
              type="radio"
              id="radioToysYes"
              name="radioToys"
              checked={isCobuyer}
              onClick={() => setIsCobuyer(!isCobuyer)}
              onChange={() => { }}
            />
            <label className="uk-form-label radio">Yes</label>
            <input
              type="radio"
              id="radioToysNo"
              name="radioToys"
              checked={!isCobuyer}
              onClick={() => setIsCobuyer(!isCobuyer)}
              onChange={() => { }}
            />
            <label className="uk-form-label radio">No</label>
          </div>
        </FormInputWrapper>
      </FormRow>
      {isCobuyer && (
        <>
          <FormRow>
            <AutoCompleteSearch options={optionsForAutocomplete} onSelect={handleSelect} placeholder="Search Your Client List" />
          </FormRow>

          <Form>
            <FormRow>
              <FormInputWrapper label="First Name *">
                <input value={getValues('first_name')}
                  className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('first_name') })}
                  {...register('first_name', {
                    onChange: e => {
                      manageRequireFields('first_name', e.target.value);
                    }
                  })} type="text" />
              </FormInputWrapper>
              <FormInputWrapper label="Middle Name">
                <input className="uk-input succeed" type="text" {...register("middle_name")} />
              </FormInputWrapper>
              <FormInputWrapper label="Last Name *">
                <input value={getValues('last_name')}
                  className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('last_name') })}
                  {...register('last_name', {
                    onChange: e => {
                      manageRequireFields('last_name', e.target.value);
                    }
                  })} type="text" />
              </FormInputWrapper>
            </FormRow>
            <FormRow>
              <FormInputWrapper label="Email *">
                <input value={getValues('email')}
                  className={clsx("uk-input", { 'uk-form-danger': requiredFields.includes('email') })}
                  {...register('email', {
                    onChange: e => {
                      manageRequireFields('email', e.target.value);
                    }
                  })} type="email" />
              </FormInputWrapper>
              <div className={`one-column uk-padding-small ${classes.phoneInputDiv}`}>
                <label className="uk-form-label" htmlFor="name">Mobile Phone *</label>
                <input type="hidden" required name="mobile_phone"  {...register("mobile_phone")} />
                <PhoneInput
                  placeholder='+'
                  country={'us'}
                  inputClass={classes.phone_input}
                  containerClass={classes.phone_container}
                  autoFocus={false}
                  onFocus={() => { }}
                  inputStyle={{
                    background: `${requiredFields.includes('mobile_phone') ? '#fff7f8' : ''}`,
                    borderColor: `${requiredFields.includes('mobile_phone') ? '#dc8d99' : ''}`
                  }}
                  buttonStyle={{ borderColor: `${requiredFields.includes('mobile_phone') ? '#dc8d99' : ''}` }}
                  value={getValues("mobile_phone")}
                  onChange={phone => {
                    setValue('mobile_phone', phone, { shouldValidate: true })
                    manageRequireFields('mobile_phone', phone);
                  }}
                />
              </div>
              <FormInputWrapper label="Citizen Of">
                <input className="uk-input succeed" type="text" {...register("nationality")} />
              </FormInputWrapper>
            </FormRow>
            <FormRow>
              <FormInputWrapper label="">
                <input
                  type="checkbox"
                  checked={isSameAddress}
                  onClick={() => setIsSameAddress(!isSameAddress)}
                  onChange={() => { }}
                />
                <span className="checkmark">Address same as buyer</span>
              </FormInputWrapper>
            </FormRow>
            <FormRow>
              <FormInputWrapper label="Address">
                <input className="uk-input succeed" type="text" {...register("address_1")} />
              </FormInputWrapper>
              <FormInputWrapper label="Address 2">
                <input className="uk-input" type="text" {...register("address_2")} />
              </FormInputWrapper>
              <FormInputWrapper label="Address 3">
                <input className="uk-input" type="text" {...register("address_3")} />
              </FormInputWrapper>
              <FormInputWrapper label="Country">
                <select className="uk-select" {...register("country")} defaultValue="">
                  {Country.map((country) => {
                    return (
                      <option value={country.code} key={country.code}>
                        {country.name}
                      </option>
                    );
                  })}
                </select>
              </FormInputWrapper>
            </FormRow>
            <FormRow>
              <FormInputWrapper label="City">
                <input className="uk-input succeed" type="text" {...register("city")} />
              </FormInputWrapper>
              <FormInputWrapper label="State">
                {isSelectedUSA ? (
                  <select className="uk-select" {...register("state")}>
                    <option defaultValue={undefined}></option>
                    {StatesForUSA.map((state) => {
                      return (
                        <option value={state.code} key={state.code}>
                          {state.name}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <input className="uk-input" type="text" {...register("state")} />
                )}
              </FormInputWrapper>
              <FormInputWrapper label="Zip/Postal Code">
                <input className="uk-input succeed" type="text" {...register("postal_code")} />
              </FormInputWrapper>
            </FormRow>
            <FormRow>
              <button type="button" className='uk-button uk-button-primary' onClick={() => handleRegistration(getValues(), false)}>Save & Continue</button>
              <ThemeButton theme="primary" onClick={handleContinue}>
                Continue
              </ThemeButton>
              <button type="button" className='uk-button uk-button-default' onClick={() => handleRegistration(getValues(), true)}>
                Save & Finish Later
              </button>
            </FormRow>
          </Form>
        </>
      )}
      {!isCobuyer && (
        <FormRow>
          <ThemeButton theme="primary" onClick={removeCoBuyer}>
            Continue
          </ThemeButton>
          <LinkButton to="/deals">Finish Later</LinkButton>
        </FormRow>
      )}
      <AsterixTypo isVisible={!!asterix} />
    </FormPage>
  );
};

export default AddCoBuyer;
