import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CYBAOpenListingAgreement, NYBAListingAgreement, YBAAListingAgreementRoles, csaListingAgreementsRoles, cybaListingAgreementAmendmentRoles, cybaListingAgreementsRoles, listingAgreementAddendumRoles, listingAgreementExclusionRoles, listingAgreementsRoles, materialDamageDisclosureRoles } from "../../../../../constants/documentWiseRoles";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { getTransactionEdocById } from "../../../../../redux/actions/transaction";
import TransactionService from "../../../../../services/TransactionService";
import { capitalizeFirstLetter, convertFirstCharIntoSmallCase, isEmptyObj } from "../../../../../utils/functions";
import SignatureMessageModal from "../../../../common/SignatureMessageModal";
import AddEsign from "../../../Edit/layouts/forms/AddEsign";
import PierOneAddESign from "../../../Edit/layouts/forms/PierOneAddESign";
import EsignAddendum from "../../options/steps/EsignAddendum";
import EsignCSAListingAgreement from "../../options/steps/EsignCSAListingAgreement";
import EsignCYBAListingAgreement from "../../options/steps/EsignCYBAListingAgreement";
import EsignCYBAListingAgreementAmendment from "../../options/steps/EsignCYBAListingAgreementAmendment";
import EsignCYBAOpenListingAgreement from "../../options/steps/EsignCYBAOpenListingAgreement";
import EsignExclusion from "../../options/steps/EsignExclusion";
import EsignMaterialDamage from "../../options/steps/EsignMaterialDamage";
import EsignNYBAListingAgreement from "../../options/steps/EsignNYBAListingAgreement";
import EsignYBAAAmendmentToCentralListingAgreement from "../../options/steps/EsignYBAAAmendmentToCentralListingAgreement";
import EsignYBAACanadaCentralListingAgreement from "../../options/steps/EsignYBAACanadaCentralListingAgreement";
import EsignYBAACanadaOpenListingAgreement from "../../options/steps/EsignYBAACanadaOpenListingAgreement";
import EsignYBAAListingAgreement from "../../options/steps/EsignYBAAListingAgreement";
import EsignYBAAUSOpenListingAgreement from "../../options/steps/EsignYBAAUSOpenListingAgreement";
import "./signature.css";
import EsignCYBAExclusiveListingAgreementNet from "../../options/steps/EsignCYBAExclusiveListingAgreementNet";
import EsignCYBAExclusiveListingAgreementGross from "../../options/steps/EsignCYBAExclusiveListingAgreementGross";
import EsignMaterialDamageDisclosureSellerFills from "../../options/steps/EsignMaterialDamageDisclosureSellerFills";
import EsignListingDisclosureSellerFills from "../../options/steps/EsignListingDisclosureSellerFills";

const Signatures = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const { state: locationState } = useLocation();

    //Fetch transaction from redux state
    const deals = useSelector((state) => state.transactions);
    const dataTransaction = deals.transaction || {};
    const dataEdocuments = dataTransaction.t_edocuments || [];
    const docData = dataEdocuments
        .sort((a, b) => {
            if (a.sort_order == b.sort_order) {
                if (a.duplicate_occurrence) {
                    return a.duplicate_occurrence === b.duplicate_occurrence ? 0 : a.duplicate_occurrence > b.duplicate_occurrence ? 1 : -1;
                } else {
                    return 0
                }
            }
            return a.sort_order > b.sort_order ? 1 : -1
        });
    const docNameArr = docData.map((doc) => doc.task_type);


    const addendumDocArr = dataEdocuments.filter((doc) => doc.task_type == "listing-agreement-addendum");
    const exclusionArr = dataEdocuments.filter((doc) => doc.task_type == "listing-agreement-exclusion");
    //Define state for showing document one by one
    const [docState, setDocState] = useState([]);
    const [multiDocState, setMultiDocState] = useState([]);

    //Make a state to create the list of signers for displaying on the drop-down
    const [dropDownRoles, setDropDownRoles] = useState([]);
    const [successDocuments, setSuccessDoc] = useState([]);
    const [failedDocuments, setFailedDoc] = useState([]);
    const [showAdditionalModal, setShowAdditionalModal] = useState(false);
    const [toggleAccordionList, setToggleAccordionList] = useState([]);

    /**
     * Update the state as dropdownRoles which contains only unique signers array
     * @param {Array} signersArr 
    */
    const makeDropDownSigners = (signersArr) => {
        setDropDownRoles((state) => (
            sortRoles([
                ...new Set([...state, ...signersArr])
            ])
        ))
    }

    /**
     * Returns the sorted array with values like
     * Buyer
     * Co-Buyer
     * Seller
     * Co-Seller
     * Broker
     * @param {*} roles 
    */
    const sortRoles = (roles) => {
        const clinetRoles = ['owner', 'co_owner', 'employing_broker', 'listing_broker', 'broker', 'principal'];
        let selectedRoles = [];
        clinetRoles.map(role => {
            if (roles.includes(`${role}`)) {
                selectedRoles.push(role);
            }
        });
        return selectedRoles
    }

    const [doc, setSelectedDoc] = useState([]);

    useEffect(() => {
        if (locationState?.task_type) {
            setToggleAccordionList((state) => [...state, locationState.task_type]);
        }
    }, [locationState?.task_type])

    //UseEffect to start the timer of the document to be rendered
    useEffect(() => {
        startTimer();
        multiDocTImer();
    }, [])

    //Function to make a null state
    const nullState = () => {
        setDocState([]);
        setMultiDocState([]);
        setDocumentObj({});
        startTimer();
        multiDocTImer();
    }

    //Fuction is used for rendering document after some amount of time.
    const startTimer = () => {
        docNameArr.map((doc, index) => {
            setTimeout(() => {
                setDocState((state) => [...state, doc])
            }, index * 0.00001)
        })
    }

    const multiDocTImer = () => {
        [...addendumDocArr, ...exclusionArr].map((doc, index) => {
            setTimeout(() => {
                setMultiDocState((state) => [...state, doc?.id]);
            }, index * 0.00005)
        })
    }

    //Make a state for the payload
    const [documentObj, setDocumentObj] = useState({});

    //Make a state to select role from role dropdown
    const [selectedRole, setSelectedRole] = useState('');

    // State for the selected role to send multiple document email
    const [signerData, setSignerData] = useState({})

    //Fuction for update the payload
    const updateDocumentObj = (obj, role, isDeleted = false, isCheckboxChange = false) => {

        //Check payload for the particular role is editable or not
        const isEditable = !documentObj[role]?.some((doc) => doc.document_name == obj[role]?.document_name && doc.id == obj[role].id);
        if (isEditable) {
            let arr = documentObj[role] || [];
            arr.push(obj[role]);
            const filteredArr = arr.filter((el) => el.id)
            setDocumentObj({
                [role]: filteredArr
            })
            setSelectedDoc(filteredArr.map((el) => el.document_name));

            if (!isCheckboxChange) {
                setToggleAccordionList((prev) =>
                    ([...prev, ...filteredArr.map((el) => el.document_name)].filter((el, index, arr) => arr.indexOf(el) === index))
                );
            }

            return;
        }

        //If deleted true then remove that particular document
        if (isDeleted) {
            if (documentObj[role]?.filter((doc) => doc.document_name !== obj[role]?.document_name || doc.id != obj[role].id).length == 0) {
                setDocumentObj({});
                setSelectedDoc([]);
                return;
            }
            setDocumentObj((state) => {
                return {
                    ...state,
                    [role]: documentObj[role]?.filter((doc) => doc.document_name != obj[role]?.document_name || doc.id != obj[role].id)
                }
            });
            setSelectedDoc(documentObj[role]?.filter((doc) => doc.document_name != obj[role]?.document_name || doc.id != obj[role].id))
            return;
        }
    }

    //Fuction for the update role state
    const handleSelectedRole = (event) => {
        setToggleAccordionList([]);
        setSelectedDoc([]);
        setSelectedRole(event.target.value);
        nullState();
    }

    /**
     * @param {String} string 
     * @returns It will convert `-` into ` ` and also campitalize all words
     */
    const capitalize = (string) => {
        const arr = string.split("-");
        let a = []
        for (let name of arr) {
            a.push(name.charAt(0).toUpperCase() + name.slice(1))
        }
        return a.join(" ")
    }

    //Fucntion for the send signature request with payload
    const sendSignature = async (additionalContent) => {
        try {
            let payload = {}
            payload = renameKey(documentObj, Object.keys(documentObj)[0], capitalizeFirstLetter(Object.keys(documentObj)[0]));
            payload["additional_email_content"] = additionalContent.additional_email_content || '';
            payload["selected_recipients"] = additionalContent.selected_recipients || [];
            dispatch(setLoadingStatus(true));
            const { data } = await TransactionService.sendSignatures("listing", payload);
            let { successDocument, failedDocument } = data;
            setSuccessDoc(successDocument.map((docName) => capitalize(docName)));
            setFailedDoc(failedDocument.map((docName) => capitalize(docName)));
            setTimeout(() => {
                setSuccessDoc([]);
                setFailedDoc([]);
            }, 15000);
            nullState();
            setSelectedRole("")
            dispatch(setLoadingStatus(false));
            dispatch(getTransactionEdocById(id))
                .then(() => {
                    dispatch(setLoadingStatus(false));
                })
                .catch(() => {
                    dispatch(setLoadingStatus(false));
                });
        } catch (error) {
            nullState();
            setSelectedRole("")
            dispatch(setLoadingStatus(false));
            toast.error("Error occurs, please try again.", {
                autoClose: 2000
            })
        }
        setSelectedDoc([]);
    }

    const getTransactionEdocuments = () => {
        dispatch(setLoadingStatus(true));
        dispatch(getTransactionEdocById(id))
            .then((response) => {
                dispatch(setLoadingStatus(false));
            }).catch((error) => {
                console.log(error);
                dispatch(setLoadingStatus(false));
            });
    }

    const getSignerByRole = (role) => {
        switch (role) {
            case 'selling_broker':
            case 'listing_broker':
                return dataTransaction.broker;

            case 'owner':
                return dataTransaction.seller;

            case 'co_owner':
                return dataTransaction.co_seller;

            default:
                return dataTransaction[convertFirstCharIntoSmallCase(role)];
        }
    }

    const onAdditionalMessageModalOpen = () => {
        const role = Object.keys(documentObj)[0];
        setSignerData({ role: capitalizeFirstLetter(role), data: getSignerByRole(role) });
        setShowAdditionalModal(true);
    }

    const onAdditionalMessageModalClose = (isEmailSend, emailAdditionalContent = null) => {
        setShowAdditionalModal(false);
        setSignerData({});
        if (isEmailSend) {
            sendSignature({ ...emailAdditionalContent.data })
        }
    }

    function renameKey(obj, oldKey, newKey) {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
        return obj
    }

    useEffect(() => {
        // dispatch(setLoadingStatus(true));
        // dispatch(getTransactionEdocById(id))
        //     .then(() => {
        //         dispatch(setLoadingStatus(false));
        //     })
        //     .catch(() => {
        //         dispatch(setLoadingStatus(false));
        //     })
        getTransactionEdocuments();
    }, [window.location.href]);

    let arr = [];

    /**
     * is document exist in list of opened document
     * @param {*} docName 
     * @returns 
     */
    const isDocumentOpenClose = (docName) => {
        return toggleAccordionList.includes(docName);
    }

    /**
     * on accordion open/close and add/remove document name 
     * @param {*} docName 
     * @returns 
     */
    const onToggleAccordion = (docName) => {
        setToggleAccordionList((state) => {
            return state.includes(docName) ? state.filter(doc => doc != docName) : [...state, docName];
        });
    }

    return (
        <>
            <div className="uk-container uk-container-small uk-position-relative">
                <div className="form-container">
                    <div className="row align-items-center mb-3 pb-2">
                        <div className="col-12 col-md-12 col-lg-6 col-xl-4 mb-3">
                            <h2 className="mb-0">Send for eSignature</h2>
                        </div>
                        <div className="col-4 text-right">

                            <div className="half-column uk-padding-small signatureDropDown">
                                <select
                                    className="uk-select"
                                    onChange={handleSelectedRole}
                                    value={selectedRole}>
                                    <option key={`role_dfault`} value="">Select a role</option>
                                    {
                                        dropDownRoles?.length > 0 &&
                                        dropDownRoles.map((docName) => (
                                            <option key={docName} value={docName}>{capitalizeFirstLetter(docName)}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-6 col-xl-4 btn-div">
                            <button className="uk-button uk-button-primary" disabled={isEmptyObj(documentObj)} onClick={onAdditionalMessageModalOpen}>Send Selected</button>
                            <button className="uk-button uk-button-dark" onClick={getTransactionEdocuments}><i class="fa fa-refresh" aria-hidden="true"></i> Refresh</button>
                        </div>
                    </div>
                    <div className="doc_div pt-3">
                        {
                            successDocuments.length > 0 &&
                            successDocuments.map((doc) => (
                                <div key={doc} className="uk-alert uk-alert-primary" data-uk-alert>
                                    <p>{doc}</p>
                                </div>
                            ))
                        }
                        {
                            failedDocuments.length > 0 &&
                            failedDocuments.map((doc) => (
                                <div key={doc} className="uk-alert uk-alert-danger" data-uk-alert>
                                    <p>{doc}</p>
                                </div>
                            ))
                        }
                    </div>
                    <ul uk-accordion="multiple:true;animation:false">
                        {
                            docData.map((docObj) => {
                                if (arr.includes(docObj.task_type)) {
                                    return <></>
                                }
                                arr.push(docObj.task_type);
                                switch (docObj.task_type) {
                                    case "listing-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("listing-agreement") && docNameArr.includes("listing-agreement") &&
                                                    <li className={toggleAccordionList.includes("listing-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("listing-agreement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "listing-agreement")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("listing-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <AddEsign
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={listingAgreementsRoles}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "listing-agreement-exclusion":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("listing-agreement-exclusion") && docNameArr.includes("listing-agreement-exclusion") &&
                                                    <li className={toggleAccordionList.includes("listing-agreement-exclusion") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("listing-agreement-exclusion")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "listing-agreement-exclusion")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("listing-agreement-exclusion") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                {exclusionArr?.map((doc) => (
                                                                    multiDocState.includes(doc?.id) &&
                                                                    <EsignExclusion
                                                                        updateDocumentObj={updateDocumentObj}
                                                                        makeDropDownSigners={makeDropDownSigners}
                                                                        payload={documentObj}
                                                                        selectedRole={selectedRole}
                                                                        docId={doc.id}
                                                                        roles={listingAgreementExclusionRoles}
                                                                    />
                                                                ))
                                                                }
                                                            </>
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "listing-agreement-addendum":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("listing-agreement-addendum") && docNameArr.includes("listing-agreement-addendum") &&
                                                    <li className={toggleAccordionList.includes("listing-agreement-addendum") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("listing-agreement-addendum")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "listing-agreement-addendum")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("listing-agreement-addendum") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <>
                                                                {addendumDocArr?.map((doc) => (
                                                                    multiDocState.includes(doc?.id) &&
                                                                    <EsignAddendum
                                                                        updateDocumentObj={updateDocumentObj}
                                                                        makeDropDownSigners={makeDropDownSigners}
                                                                        payload={documentObj}
                                                                        selectedRole={selectedRole}
                                                                        docId={doc.id}
                                                                        roles={listingAgreementAddendumRoles}
                                                                    />
                                                                ))
                                                                }
                                                            </>
                                                        </div>
                                                    </li>
                                                }

                                            </Fragment>
                                        )

                                    case "material-damage-disclosure":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("material-damage-disclosure") && docNameArr.includes("material-damage-disclosure") &&
                                                    <li className={toggleAccordionList.includes("material-damage-disclosure") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("material-damage-disclosure")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "material-damage-disclosure")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("material-damage-disclosure") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignMaterialDamage
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={materialDamageDisclosureRoles}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "material-damage-disclosure---seller-fills":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("material-damage-disclosure---seller-fills") && docNameArr.includes("material-damage-disclosure---seller-fills") &&
                                                    <li className={toggleAccordionList.includes("material-damage-disclosure---seller-fills") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("material-damage-disclosure---seller-fills")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "material-damage-disclosure---seller-fills")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("material-damage-disclosure---seller-fills") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignMaterialDamageDisclosureSellerFills
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={materialDamageDisclosureRoles}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )
                                        case "listing-disclosure-(seller-fills-form)":
                                            return (
                                                <Fragment key={docObj.id}>
                                                    {
                                                        docState.includes("listing-disclosure-(seller-fills-form)") && docNameArr.includes("listing-disclosure-(seller-fills-form)") &&
                                                        <li className={toggleAccordionList.includes("listing-disclosure-(seller-fills-form)") && "uk-open"}>
                                                            <a onClick={() => onToggleAccordion("listing-disclosure-(seller-fills-form)")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "listing-disclosure-(seller-fills-form)")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("listing-disclosure-(seller-fills-form)") ? 'Close' : 'Open'}</label></a>
                                                            <div className="uk-accordion-content">
                                                                <EsignListingDisclosureSellerFills
                                                                    updateDocumentObj={updateDocumentObj}
                                                                    makeDropDownSigners={makeDropDownSigners}
                                                                    payload={documentObj}
                                                                    selectedRole={selectedRole}
                                                                    roles={materialDamageDisclosureRoles}
                                                                />
                                                            </div>
                                                        </li>
                                                    }
                                                </Fragment>
                                            )
    
                                        
                                        case "ybaa-us-central-listing-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("ybaa-us-central-listing-agreement") && docNameArr.includes("ybaa-us-central-listing-agreement") &&
                                                    <li className={toggleAccordionList.includes("ybaa-us-central-listing-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("ybaa-us-central-listing-agreement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "ybaa-us-central-listing-agreement")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("ybaa-us-central-listing-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignYBAAListingAgreement
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={YBAAListingAgreementRoles}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "pier-one-listing-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("pier-one-listing-agreement") && docNameArr.includes("pier-one-listing-agreement") &&
                                                    <li className={toggleAccordionList.includes("pier-one-listing-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("pier-one-listing-agreement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "pier-one-listing-agreement")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("pier-one-listing-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <PierOneAddESign
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={listingAgreementsRoles}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "ybaa-us-open-listing-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("ybaa-us-open-listing-agreement") && docNameArr.includes("ybaa-us-open-listing-agreement") &&
                                                    <li className={toggleAccordionList.includes("ybaa-us-open-listing-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("ybaa-us-open-listing-agreement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "ybaa-us-open-listing-agreement")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("ybaa-us-open-listing-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignYBAAUSOpenListingAgreement
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={YBAAListingAgreementRoles}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "ybaa-canada-central-listing-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("ybaa-canada-central-listing-agreement") && docNameArr.includes("ybaa-canada-central-listing-agreement") &&
                                                    <li className={toggleAccordionList.includes("ybaa-canada-central-listing-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("ybaa-canada-central-listing-agreement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "ybaa-canada-central-listing-agreement")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("ybaa-canada-central-listing-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignYBAACanadaCentralListingAgreement
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={YBAAListingAgreementRoles}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "ybaa-canada-open-listing-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("ybaa-canada-open-listing-agreement") && docNameArr.includes("ybaa-canada-open-listing-agreement") &&
                                                    <li className={toggleAccordionList.includes("ybaa-canada-open-listing-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("ybaa-canada-open-listing-agreement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "ybaa-canada-open-listing-agreement")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("ybaa-canada-open-listing-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignYBAACanadaOpenListingAgreement
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={YBAAListingAgreementRoles}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "csa-listing-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("csa-listing-agreement") && docNameArr.includes("csa-listing-agreement") &&
                                                    <li className={toggleAccordionList.includes("csa-listing-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("csa-listing-agreement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "csa-listing-agreement")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("csa-listing-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignCSAListingAgreement
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={csaListingAgreementsRoles}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "cyba-listing-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("cyba-listing-agreement") && docNameArr.includes("cyba-listing-agreement") &&
                                                    <li className={toggleAccordionList.includes("cyba-listing-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("cyba-listing-agreement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "cyba-listing-agreement")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("cyba-listing-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignCYBAListingAgreement
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={cybaListingAgreementsRoles}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "cyba-listing-agreement-amendment":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {
                                                    docState.includes("cyba-listing-agreement-amendment") && docNameArr.includes("cyba-listing-agreement-amendment") &&
                                                    <li className={toggleAccordionList.includes("cyba-listing-agreement-amendment") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("cyba-listing-agreement-amendment")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "cyba-listing-agreement-amendment")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("cyba-listing-agreement-amendment") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignCYBAListingAgreementAmendment
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={cybaListingAgreementAmendmentRoles}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "cyba-open-listing-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {docState.includes("cyba-open-listing-agreement") && docNameArr.includes("cyba-open-listing-agreement") &&
                                                    <li className={toggleAccordionList.includes("cyba-open-listing-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("cyba-open-listing-agreement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "cyba-open-listing-agreement")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("cyba-open-listing-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignCYBAOpenListingAgreement
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={CYBAOpenListingAgreement}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "ybaa-amendment-to-central-listing-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {docState.includes("ybaa-amendment-to-central-listing-agreement") && docNameArr.includes("ybaa-amendment-to-central-listing-agreement") &&
                                                    <li className={toggleAccordionList.includes("ybaa-amendment-to-central-listing-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("ybaa-amendment-to-central-listing-agreement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "ybaa-amendment-to-central-listing-agreement")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("ybaa-amendment-to-central-listing-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignYBAAAmendmentToCentralListingAgreement
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={CYBAOpenListingAgreement}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    case "nyba-listing-agreement":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {docState.includes("nyba-listing-agreement") && docNameArr.includes("nyba-listing-agreement") &&
                                                    <li className={toggleAccordionList.includes("nyba-listing-agreement") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("nyba-listing-agreement")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "nyba-listing-agreement")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("nyba-listing-agreement") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignNYBAListingAgreement
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={NYBAListingAgreement}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )
                                    case "cyba-exclusive-listing-agreement---net":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {docState.includes("cyba-exclusive-listing-agreement---net") && docNameArr.includes("cyba-exclusive-listing-agreement---net") &&
                                                    <li className={toggleAccordionList.includes("cyba-exclusive-listing-agreement---net") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("cyba-exclusive-listing-agreement---net")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "cyba-exclusive-listing-agreement---net")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("cyba-exclusive-listing-agreement---net") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignCYBAExclusiveListingAgreementNet
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={NYBAListingAgreement}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )
                                    case "cyba-exclusive-listing-agreement---gross":
                                        return (
                                            <Fragment key={docObj.id}>
                                                {docState.includes("cyba-exclusive-listing-agreement---gross") && docNameArr.includes("cyba-exclusive-listing-agreement---gross") &&
                                                    <li className={toggleAccordionList.includes("cyba-exclusive-listing-agreement---gross") && "uk-open"}>
                                                        <a onClick={() => onToggleAccordion("cyba-exclusive-listing-agreement---gross")} className="uk-accordion-title"><b>{dataEdocuments.find((doc) => doc.task_type == "cyba-exclusive-listing-agreement---gross")?.t_esign_template?.template?.template_name}</b><label className={"pull-right"} style={{ paddingTop: '2px', cursor: 'pointer' }}>{isDocumentOpenClose("cyba-exclusive-listing-agreement---gross") ? 'Close' : 'Open'}</label></a>
                                                        <div className="uk-accordion-content">
                                                            <EsignCYBAExclusiveListingAgreementGross
                                                                updateDocumentObj={updateDocumentObj}
                                                                makeDropDownSigners={makeDropDownSigners}
                                                                payload={documentObj}
                                                                selectedRole={selectedRole}
                                                                roles={NYBAListingAgreement}
                                                            />
                                                        </div>
                                                    </li>
                                                }
                                            </Fragment>
                                        )

                                    default:
                                        break;
                                }
                            })
                        }
                    </ul>

                </div>
            </div>
            <SignatureMessageModal show={showAdditionalModal} onClose={(isEmailSend, emailAdditionalContent = null) => onAdditionalMessageModalClose(isEmailSend, emailAdditionalContent)} emailDetail={signerData} isNoGenerateLink={true} />
        </>
    )
}

export default Signatures