import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Collapse, Divider, FormControl, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, ThemeProvider, Tooltip, Typography, createTheme, tooltipClasses } from '@mui/material';

import FormDataDrawer from './accordions/FormDataDrawer';
import { getTransactionEdocById, getTransactionEsignById, getTransactionInitialById } from '../../../../redux/actions/transaction';
import { JOINT_OWNER, SOLE_OWNER } from '../../../../utils/signer';

import './formDataSidebar.css';
import clsx from "clsx";
import Swal from 'sweetalert2';
import { faCalendarDay, faFileSignature, faSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FormDataSideBar = ({ transactionId, sliderFor, reloadPdf = null, isPdfEdit, signatureAssigner, setSignatureAssigner, style, theme, signatureRoles }) => {

    const dispatch = useDispatch();

    const dataRequiredFields = useSelector((state) => state.transactions.requiredFields);
    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataListing = dataTransaction || {};
    const dataEdocuments = dataListing.t_edocuments || [];
    const dataExclusion = dataListing.t_purchase_exclusion || [];
    const dataAddendum = dataListing.t_purchase_addendum || [];
    const dataTerms = dataListing?.t_terms || {};

    const exclusionDocuments = dataEdocuments.filter(doc => doc.task_type == 'listing-agreement-exclusion').sort((a, b) => {
        if (a.sort_order == b.sort_order) {
            return a.duplicate_occurrence > b.duplicate_occurrence ? 1 : -1
        }
        return a.sort_order > b.sort_order ? 1 : -1
    }) || [];

    const addendumDocuments = dataEdocuments.filter(doc => doc.task_type == 'listing-agreement-addendum').sort((a, b) => {
        if (a.sort_order == b.sort_order) {
            return a.duplicate_occurrence > b.duplicate_occurrence ? 1 : -1
        }
        return a.sort_order > b.sort_order ? 1 : -1
    }) || [];

    const materialDamageDocument = dataEdocuments.find(doc => doc.task_type == 'material-damage-disclosure') || null;

    const [asterix, setAsterix] = useState({});
    const [show, setShow] = useState(false);
    const [selectedForm, setSelectedForm] = useState(null);
    const [multiFormId, setMultiFormId] = useState(null)
    const [open, setOpen] = useState({
        exclusion: false,
        addendum: false,
        material_damage: false
    });

    useEffect(() => {
        if (transactionId && sliderFor == 'editor') {
            dispatch(getTransactionInitialById(transactionId)).then((data) => {
                dispatch(getTransactionEdocById(transactionId)).then((edoc) => {
                    dispatch(getTransactionEsignById(transactionId));
                });
            });
        }
    }, []);

    useEffect(() => {
        const vesselRequireds = dataRequiredFields.vessel;
        const sellerRequireds = dataRequiredFields.seller;
        const termsRequireds = dataRequiredFields.terms;
        const coSellerRequireds = dataRequiredFields.co_seller;
        const trailerRequireds = dataRequiredFields.trailer;

        let vesselAsterix = "empty";
        let sellerAsterix = "empty";
        let termsAsterix = "empty";
        let coSellerAsterix = "empty";
        let trailerAsterix = "empty";

        if (vesselRequireds.length > 0 && vesselRequireds.length < 7) vesselAsterix = "notFulfill";
        if (sellerRequireds.length > 0 && sellerRequireds.length < ((dataListing?.seller_type === SOLE_OWNER || dataListing?.seller_type === JOINT_OWNER) ? 4 : 8)) sellerAsterix = "notFulfill";
        if (sellerRequireds.length === 1 && sellerRequireds[0]['title']) sellerAsterix = "empty"
        if (termsRequireds.length > 0 && termsRequireds.length < (dataListing.association === 'YBAA' ? 2 : 4)) termsAsterix = "notFulfill";
        if (coSellerRequireds.length > 0 && coSellerRequireds.length < 4)
            coSellerAsterix = "notFulfill";

        if (vesselRequireds.length === 0) vesselAsterix = "fulfill";
        if (sellerRequireds.length === 0) sellerAsterix = "fulfill";
        if (termsRequireds.length === 0) termsAsterix = "fulfill";
        if (coSellerRequireds.length === 0) coSellerAsterix = "fulfill";
        if (trailerRequireds.length === 0) trailerAsterix = "fulfill";

        const val = {
            vessel: vesselAsterix,
            seller: sellerAsterix,
            terms: termsAsterix,
            coseller: coSellerAsterix,
            trailer: trailerAsterix
        };
        val.toys = dataListing.t_toys?.length > 0 ? "fulfill" : "empty";
        val.exclusions = dataListing.t_exclusion?.length > 0 ? "fulfill" : "empty";

        if ([null, false].includes(dataTerms?.materially_damaged)) {
            val.materially_damaged = "fulfill";
        }

        if (dataTerms?.materially_damaged) {
            val.materially_damaged = dataTerms?.material_damage_detail ? "fulfill" : "empty";
        }

        setAsterix(val);
    }, [dataRequiredFields, dataTransaction]);

    const handleOpenPanel = (formType, dataId = null) => {
        setSelectedForm(formType);
        setMultiFormId(dataId || null)
        setShow(true);
    }

    const handleClick = (type) => {
        setOpen({
            ...open,
            [type]: !open[type]
        });
    };

    const exclusionDataStatus = (exclusionId) => {
        const exclusion = dataExclusion.find(el => el.id === exclusionId);
        const blankExclusionStr = Array(20).fill().map((v, i) => "/:/").join("").slice(0, -3);
        return (exclusion?.exclusion && !["", blankExclusionStr].includes(exclusion?.exclusion)) ? "fulfill" : "empty";
    }

    const requiredFields = [
        { addendum_no: "Addendum Number" },
        { ammendment: "Provision #1" },
    ];

    const addendumDataStatus = (addendumId) => {
        let status = 'empty';
        let notfilledFields = [];
        const addendum = dataAddendum.find(el => el.t_edocument_id === addendumId);
        if (addendum) {
            requiredFields.forEach((rf) => {
                const field_key = Object.keys(rf)[0];
                if (field_key === 'ammendment') {
                    if (addendum[field_key] && addendum[field_key] === "/://://://:/") {
                        notfilledFields.push(rf);
                    }
                } else {
                    if (!addendum[field_key]) {
                        notfilledFields.push(rf);
                    }
                }
            });
            status = notfilledFields.length === 2
                ? "empty"
                : notfilledFields.length === 0
                    ? "fulfill"
                    : "notFulfill";
        } else {
            notfilledFields = requiredFields
            status = "empty";
        }
        return status;
    }

    useEffect(() => {
        if (show && selectedForm) {
            const alertUser = (e) => {
                if (e) {
                    e.preventDefault();
                    e.returnValue = "";
                }
            };

            window.addEventListener("beforeunload", alertUser);
            return () => {
                window.removeEventListener("beforeunload", alertUser);
            };
        }
    }, [show, selectedForm]);

    const onClosePanel = (isPDFReload) => {
        // if (isPDFReload) {
        setSelectedForm(null);
        setShow(false);
        if (reloadPdf != null) {
            reloadPdf(isPDFReload);
        }
        //     return;
        // }

        // Swal.fire({
        //     title: 'Close Form!',
        //     text: "Changes that you made may not be saved.",
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Yes',
        //     cancelButtonText: "No"
        // }).then((result) => {
        //     if (result.isConfirmed) {
        //         setSelectedForm(null);
        //         setShow(false);
        //     }
        // });
    }

    return (
        <>
            <div className="tm-sidebar-left uk-visible@m py-0">
                <div className="sidebar-container add-deal">
                    <Box role="presentation" >
                        <List>
                            <ListItemButton disablePadding sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }} className="my-1">
                                <ListItemText><span style={{ fontWeight: 'bold' }}>Form Data</span></ListItemText>
                            </ListItemButton>
                            <ListItemButton onClick={() => handleOpenPanel('Vessel')}>
                                <ListItemIcon>
                                    <i
                                        className={clsx(
                                            "fa",
                                            { "fa-check-circle stepbar": asterix.vessel === "fulfill" },
                                            { "fa-circle-o": asterix.vessel === "empty" || !asterix.vessel },
                                            { "fa-circle": asterix.vessel === "notFulfill" }
                                        )}
                                    ></i>
                                </ListItemIcon>
                                <ListItemText primary={'Vessel'} />
                            </ListItemButton>
                            <Divider />
                            <ListItemButton onClick={() => handleOpenPanel('Seller')}>
                                <ListItemIcon>
                                    <i
                                        className={clsx(
                                            "fa",
                                            { "fa-check-circle stepbar": asterix.seller === "fulfill" },
                                            { "fa-circle-o": asterix.seller === "empty" || !asterix.seller },
                                            { "fa-circle": asterix.seller === "notFulfill" }
                                        )}
                                    ></i>
                                </ListItemIcon>
                                <ListItemText primary={'Seller'} />
                            </ListItemButton>
                            <Divider />
                            <ListItemButton onClick={() => handleOpenPanel('Co-Seller')}>
                                <ListItemIcon>
                                    <i
                                        className={clsx(
                                            "fa",
                                            { "fa-check-circle stepbar": asterix.coseller === "fulfill" },
                                            { "fa-circle-o": asterix.coseller === "empty" || !asterix.coseller },
                                            { "fa-circle": asterix.coseller === "notFulfill" }
                                        )}
                                    ></i>
                                </ListItemIcon>
                                <ListItemText primary={'Co-Seller'} />
                            </ListItemButton>
                            <Divider />
                            <ListItemButton onClick={() => handleOpenPanel('Toys & Tenders')}>
                                <ListItemIcon>
                                    <i
                                        className={clsx(
                                            "fa",
                                            { "fa-check-circle stepbar": asterix.toys === "fulfill" },
                                            { "fa-circle-o": asterix.toys === "empty" || !asterix.toys },
                                            { "fa-circle": asterix.toys === "notFulfill" }
                                        )}
                                    ></i>
                                </ListItemIcon>
                                <ListItemText primary={'Toys & Tenders'} />
                            </ListItemButton>
                            <Divider />
                            <ListItemButton onClick={() => handleOpenPanel('Trailer')}>
                                <ListItemIcon>
                                    <i
                                        className={clsx(
                                            "fa",
                                            { "fa-check-circle stepbar": asterix.trailer === "fulfill" },
                                            { "fa-circle-o": asterix.trailer === "empty" || !asterix.trailer },
                                            { "fa-circle": asterix.trailer === "notFulfill" }
                                        )}
                                    ></i>
                                </ListItemIcon>
                                <ListItemText primary={'Trailer'} />
                            </ListItemButton>
                            <Divider />
                            <ListItemButton onClick={() => handleOpenPanel('Terms')}>
                                <ListItemIcon>
                                    <i
                                        className={clsx(
                                            "fa",
                                            { "fa-check-circle stepbar": asterix.terms === "fulfill" },
                                            { "fa-circle-o": asterix.terms === "empty" || !asterix.terms },
                                            { "fa-circle": asterix.terms === "notFulfill" }
                                        )}
                                    ></i>
                                </ListItemIcon>
                                <ListItemText primary={'Terms'} />
                            </ListItemButton>
                            <Divider />
                            <ListItemButton onClick={() => handleOpenPanel('Exclusions')}>
                                <ListItemIcon>
                                    <i
                                        className={clsx(
                                            "fa",
                                            { "fa-check-circle stepbar": asterix.exclusions === "fulfill" },
                                            { "fa-circle-o": asterix.exclusions === "empty" || !asterix.exclusions },
                                            { "fa-circle": asterix.exclusions === "notFulfill" }
                                        )}
                                    ></i>
                                </ListItemIcon>
                                <ListItemText primary={'Exclusions'} />
                            </ListItemButton>
                            <Divider />

                            {/* Form Data Button for Material Damage Disclosure */}
                            {materialDamageDocument &&
                                <Fragment>
                                    <ListItemButton onClick={() => handleOpenPanel('Material Damage Disclosure')}>
                                        <ListItemIcon>
                                            <i
                                                className={clsx(
                                                    "fa",
                                                    { "fa-check-circle stepbar": asterix.materially_damaged === "fulfill" },
                                                    { "fa-circle-o": asterix.materially_damaged === "empty" || !asterix.materially_damaged },
                                                    { "fa-circle": asterix.materially_damaged === "notFulfill" }
                                                )}
                                            ></i>
                                        </ListItemIcon>
                                        <ListItemText primary={'Material Damage Disclosure'} />
                                    </ListItemButton>
                                    <Divider />
                                </Fragment>
                            }

                            {/* Form Data Button for Listing Agreement Addendum */}
                            {addendumDocuments.length > 0 &&
                                <div className="my-1">
                                    <ListItemButton onClick={() => handleClick('addendum')} sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }}>
                                        <ListItemText primary="Listing Agreement Addendum" />
                                        {open['addendum'] ? <i class="fa fa-chevron-up"></i> : <i class="fa fa-chevron-down"></i>}
                                    </ListItemButton>
                                    <Collapse in={open['addendum']} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {addendumDocuments.map((addendum, ind) => (
                                                <ListItemButton sx={{ pl: 4 }} key={addendum.id} onClick={() => handleOpenPanel('IYBA Listing Agreement Addendum', addendum.id)}>
                                                    <ListItemIcon>
                                                        <i
                                                            className={clsx(
                                                                "fa",
                                                                { "fa-check-circle stepbar": addendumDataStatus(addendum.id) === "fulfill" },
                                                                { "fa-circle-o": addendumDataStatus(addendum.id) === "empty" || !addendumDataStatus(addendum.id) },
                                                                { "fa-circle": addendumDataStatus(addendum.id) === "notFulfill" }
                                                            )}
                                                        ></i>
                                                    </ListItemIcon>
                                                    <Tooltip title={`${addendum.title} ${ind > 0 ? '#' + ind : ''}`} placement='right'>
                                                        <ListItemText primary={`Form ${ind > 0 ? '#' + ind : ''}`} />
                                                    </Tooltip>
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Collapse>
                                    <Divider />
                                </div>
                            }

                            {/* Form Data Button for Listing Agreement Exclusions */}
                            {exclusionDocuments.length > 0 &&
                                <div className="my-1">
                                    <ListItemButton onClick={() => handleClick('exclusion')} sx={{ '&:hover': { background: '#0063A6' }, color: "#fff", background: "#0063A6", borderRadius: "6px" }}>
                                        <ListItemText primary="Listing Agreement Exclusions" />
                                        {open['exclusion'] ? <i class="fa fa-chevron-up"></i> : <i class="fa fa-chevron-down"></i>}
                                    </ListItemButton>
                                    <Collapse in={open['exclusion']} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {exclusionDocuments.map((exclusion, ind) => (
                                                <ListItemButton sx={{ pl: 4 }} key={exclusion.id} onClick={() => handleOpenPanel('IYBA Listing Agreement Exclusion', exclusion.id)}>
                                                    <ListItemIcon>
                                                        <i
                                                            className={clsx(
                                                                "fa",
                                                                { "fa-check-circle stepbar": exclusionDataStatus(exclusion.id) === "fulfill" },
                                                                { "fa-circle-o": exclusionDataStatus(exclusion.id) === "empty" || !exclusionDataStatus(exclusion.id) },
                                                                { "fa-circle": exclusionDataStatus(exclusion.id) === "notFulfill" }
                                                            )}
                                                        ></i>
                                                    </ListItemIcon>
                                                    <Tooltip title={`${exclusion.title} ${ind > 0 ? '#' + ind : ''}`} placement='right'>
                                                        <ListItemText primary={`Form ${ind > 0 ? '#' + ind : ''}`} />
                                                    </Tooltip>
                                                </ListItemButton>
                                            ))}
                                        </List>
                                    </Collapse>
                                    <Divider />
                                </div>
                            }
                        </List>
                        {(isPdfEdit && signatureAssigner) &&
                            <div className='mt-3 signers-div'>
                                <Typography mb={2}>Add Signatures</Typography>
                                <Box sx={{ minWidth: 120 }} mb={2}>
                                    <FormControl variant="outlined" size="small" sx={{ minWidth: 120, width: '100%' }}>
                                        <InputLabel>Signer</InputLabel>
                                        <ThemeProvider theme={theme}>
                                            <Select
                                                value={signatureAssigner}
                                                onChange={(e) => { setSignatureAssigner(e.target.value) }}
                                                displayEmpty
                                                label="Signer"
                                            >
                                                {signatureRoles?.map((roleObj, idx) => {
                                                    return (
                                                        <MenuItem key={idx + 2} value={roleObj?.role}>
                                                            <ListItemIcon sx={{ minWidth: '18px !important', margin: '5px' }}><div style={{ backgroundColor: roleObj?.bgColor, width: '18px', height: '18px' }}></div></ListItemIcon>
                                                            <ListItemText>{roleObj?.role}</ListItemText>
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </ThemeProvider>
                                    </FormControl>
                                </Box>

                                <Typography mb={1} mt={2}>Signature fields</Typography>
                                <Box mb={1}>
                                    <div draggable style={style} onDragStart={(e) => e.dataTransfer.setData('text', "Signature")}>
                                        <FontAwesomeIcon icon={faFileSignature} /> {"Signature"}
                                    </div>
                                </Box>

                                <Box mb={1}>
                                    <div draggable style={style} onDragStart={(e) => e.dataTransfer.setData('text', "Initials")}>
                                        <FontAwesomeIcon icon={faSignature} /> {"Initials"}
                                    </div>
                                </Box>

                                <Box mb={3} onDragStart={(e) => e.dataTransfer.setData('text', "Date Signed")}>
                                    <div draggable style={style}>
                                        <FontAwesomeIcon icon={faCalendarDay} /> {"Date Signed"}
                                    </div>
                                </Box>
                            </div>
                        }
                    </Box>
                </div>
            </div>

            <FormDataDrawer show={show} onClose={onClosePanel} formType={selectedForm} multiFormId={multiFormId} />
        </>
    )
}

export default FormDataSideBar;