import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";

import { Document, Page, pdfjs } from "react-pdf";
// import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { useLocation } from 'react-router-dom';

import moment from "moment";

import PDFSignature from "../signature/Signature";

// import "./PDFSignerViewer.css";

import { lightColors, darkColors } from "../../../utils/colors";
import PDFRequiredField from "../signature/RequiredFieldModal";

// pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFSignerViewer = (props) => {

  function useQueryParams() {
    return new URLSearchParams(useLocation().search);
  }
  const [numPages, setNumPages] = useState(null);
  const [signatureElemId, setSignatureElemId] = useState(null);
  const [requiredElementId, setRequiredElementId] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [requiredFieldType, setRequiredFieldType] = useState(null);
  const [fieldQuestion, setFieldQuestion] = useState(null);
  const signatureRefs = useRef([]);
  const query = useQueryParams();

  const [dataSignature, setDataSignature] = useState([])

  const viewport = { width: 856, height: props.height * (856 / props.width) };
  const { pdf, data, roles } = props;

  useEffect(() => {
    if (data.length) {

      if (data[0]?.section) {
        setDataSignature(data.filter(signatureElement => (signatureElement?.section ? signatureElement?.section == query.get("section") : signatureElement)))
      } else {
        setDataSignature(data)
      }
    }
  }, []);

  useEffect(() => {
    let queue = {};
    if (data[0]?.section) {
      data.filter(element => element.type === 'PDFDateSigned')
        .filter(signatureElement => signatureElement.section == query.get("section"))
        .forEach(date => {
          queue[date.key] = moment(new Date()).format('MM/DD/YYYY');
        });
    } else {
      data.filter(element => element.type === 'PDFDateSigned').forEach(date => {
        queue[date.key] = moment(new Date()).format('MM/DD/YYYY');
      });
    }
    props.updateSignerData(queue);
  }, []);

  const onLoadError = (error) => {
    console.log(error)
    console.log("Error while loading document!", error.message)
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const handleClickField = (element, event) => {
    switch (element.type) {
      case 'PDFSignature':
        setModalType('Signature');
        setSignatureElemId(event.target.id);
        break;

      case 'PDFInitial':
        setModalType('Initial');
        setSignatureElemId(event.target.id);
        break;

      case 'PDFRequiredTextField':
        setRequiredFieldType('RequiredTextbox');
        setRequiredElementId(event.target.id);
        setFieldQuestion(element.value);
        break;

      case 'PDFRequiredCheckBox':
        setRequiredFieldType('RequiredCheckbox');
        setRequiredElementId(event.target.id);
        setFieldQuestion(element.value);
        break;

      default:
        break;
    }
  }

  // Array for the required checkbox 
  const requireCheckboxArray = [
    'vessel_damage_no', 'vessel_damage_yes',
    'boat_loan_no', 'boat_loan_yes',
    'boat_run_yes', 'boat_run_no',
    'boat_on_water_yes', 'boat_on_water_no',
    'boat_sunk_yes', 'boat_sunk_no',
    'boat_rebuilt_yes', 'boat_rebuilt_no',
    'damage_gear_yes', 'damage_gear_no',
    'boat_collision_yes', 'boat_collision_no',
    'boat_fire_yes', 'boat_fire_no',
    'boat_repair_yes', 'boat_repair_no',
    'boat_mexico_yes', 'boat_mexico_no',
    'boat_other_yes', 'boat_other_no',
  ]

  const handleDamageAnswer = (signature) => {
    const checkboxValue = signature.questionValue?.replace(/_(yes|no)$/, "");

    const damageYes = dataSignature.find(elem => elem.value === `${checkboxValue}_yes`);
    const damageNo = dataSignature.find(elem => elem.value === `${checkboxValue}_no`);

    const damageYesElement = signatureRefs?.current.find(elem => elem.id === damageYes.key);
    const damageNoElement = signatureRefs?.current.find(elem => elem.id === damageNo.key);

    const damageYesCtx = damageYesElement?.getContext('2d');
    const damageNoCtx = damageNoElement?.getContext('2d');

    damageYesCtx.clearRect(0, 0, damageYesElement?.width, damageYesElement?.height);
    damageNoCtx.clearRect(0, 0, damageNoElement?.width, damageNoElement?.height);

    let queue = {};

    const image = new Image();
    image.onload = function () {
      if (signature.signatureText) {
        damageYesCtx.drawImage(image, 0, 0);
      } else {
        damageNoCtx.drawImage(image, 0, 0);
      }
    };

    image.src = `/icons/check-square.svg`;

    queue[damageYesElement.id] = !!signature.signatureText;
    queue[damageNoElement.id] = !signature.signatureText;

    props.updateSignerData(queue);
  }

  // function for manually wrap multiline text in canvas 
  const multilineTextWordwrap = (signature) => {
    const field = dataSignature.find(elem => elem.key === signature.elementId);
    const element = signatureRefs?.current.find(elem => elem.id === field?.key);
    const ctx = element?.getContext('2d');
    // clear canvas before new drawing
    ctx.clearRect(0, 0, element.width, element.height);
    // set font size
    ctx.font = "14px Arial";

    // words is array of all words separated by empty string (space) 
    const words = (signature?.signatureText).split(" ");
    // line and testLine for check line width and make one line for attachment
    let line = "";
    let testLine;
    let fieldCanvas;
    let queue = {};
    let metrics;
    // x and y axis is for alignment of text line
    let x = 10;
    let y = 30;
    let lineHeight = 20;
    let maxWidth = element.width - 20;

    // we are going throw every word
    for (let n = 0; n < words.length; n++) {
      // attach word one by one till width will comparable with canvas width
      testLine = line + words[n] + " ";
      // measureText will give object with width(px) property 
      metrics = ctx.measureText(testLine);
      const testWidth = metrics.width;

      // if width is comparable and word is not first then set that line in picture
      if (testWidth > maxWidth && n > 0) {
        ctx.fillText(line, x, y);
        line = words[n] + " ";
        // after draw one line increase y axis for new line alignment
        y += lineHeight;
      } else {
        // continue make line 
        line = testLine;
      }
    }
    // at last add remaining words at x and y alignment
    ctx.fillText(line, x, y);
    fieldCanvas = element.toDataURL("image/png");
    queue[element.id] = fieldCanvas;
    props.updateSignerData(queue);
  }
  function getDynamicFontSize(height) {
    if (height < 20) {
      
        return Math.max(10, height * 1); // Prevent font size from being too small
    } else if (height < 100) {
        return (height * 2 - 14) / 2; // Medium scaling for moderate heights
    } else {
        return (height * 2 - 14) / 2; // Larger scaling for bigger heights
    }
}

  const handleUpldateRequireField = (signature) => {
    if (requireCheckboxArray.includes(signature.questionValue)) {
      handleDamageAnswer(signature);
    } else if (['Material Damage Detail'].includes(signature.questionValue)) {
      multilineTextWordwrap(signature);
    } else {
      let fieldCanvas;
      const field = dataSignature.find(elem => elem.key === signature.elementId);

      const element = signatureRefs.current.find(elem => elem.id === field?.key);
      const ctx = element?.getContext('2d');
      ctx.clearRect(0, 0, element.width, element.height);

      // Scale the canvas for high-DPI screens
      const scale =  1;
      element.width *= scale;
      element.height *= scale;
      ctx.scale(scale, scale);

      let queue = {};

      if (signature.modalType == 'RequiredCheckbox') {
        if (signature.signatureText) {
          const image = new Image();
          image.onload = function () {
            ctx.drawImage(image, 0, 0);
          };
          image.src = `${signature.signatureText ? '/icons/check-square.svg' : '/icons/blank-box.svg'}`;
          // fieldCanvas = element.toDataURL("image/png");
          queue[element.id] = true;
        } else {
          queue[element.id] = false;
        }
      } else {
        ctx.font = `${getDynamicFontSize(element.height)}px Arial`; //element.height; added font family with the font size
        ctx.fillText(signature.signatureText, 5, (element.height * 2 - 8) / 2, element.width - 10);
        fieldCanvas = element.toDataURL("image/png");
        queue[element.id] = fieldCanvas;
      }

      props.updateSignerData(queue);
    }
  }

  const handleUpdateCanvas = (signature) => {
    let signatureCanvas;
    const field = dataSignature?.find(elem => elem?.key === signature?.elementId);

    const element = signatureRefs?.current.find(elem => elem?.id === field?.key);
    const ctx = element?.getContext('2d');
    ctx?.clearRect(0, 0, element.width, element.height);
    if (signature.drawType) {
      const image = new Image();
      image.onload = function () {
        ctx?.drawImage(this, 0, 0, signature.width, signature.height, 3, 3, element.width - 6, element.height - 6);
      };
      image.src = signature.canvasData;
      signatureCanvas = signature.canvasData;
    } else {

      ctx.font = `${signature.fontFamily == "Signatura" ? element.height : signature.fontFamily == "Meddon" ? (element.height / 1.8) : (element.height - 2)}px ` + signature.fontFamily;

      ctx?.fillText(signature.signatureText, 5, signature.fontFamily == "Meddon" ? (element.height + 3) / 1.8 : (element.height * 2 - 12) / 2, element.width - 10);
      signatureCanvas = element.toDataURL("image/png");
    }
    let queue = {};
    queue[element.id] = signatureCanvas;
    if (signature.drawType) {
      if (signature.isEmptyCanvas) {
        props.removeSignerData(signature.elementId)
      }

      if (!signature.isEmptyCanvas) {
        props.updateSignerData(queue);
      }
    }
    if (!signature.drawType && signature.signatureText) {
      props.updateSignerData(queue);
    }
    if (field.type === 'PDFInitial') {
      props.setTouched(true);
    }
  }

  const addToSignatureRefs = (el) => {
    if (el && !signatureRefs.current.includes(el)) {
      signatureRefs.current.push(el);
    }
  }

  const handleChangeText = (evt, id) => {
    let queue = {};
    queue[id] = evt.target.value;
    props.updateSignerData(queue);
  }

  // used useMemo to prevent unnecessary reloads
  const options = useMemo(() => {
    return {
      cMapUrl: `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
      cMapPacked: true,
    }
  }, [])

  return (
    <Document
      file={pdf}
      options={options}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadError={onLoadError}
      className="esign-template"
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} width={viewport.width} renderAnnotationLayer={false} renderTextLayer={true} >
          <div className="page_container">
            {dataSignature.length > 0 &&
              dataSignature.filter(field => field.page_number === index).map((element, idx) => {

                switch (element.type) {
                  case 'PDFSignature':
                  case 'PDFInitial':
                    return (
                      <a data-uk-toggle="target: #modal-signature" key={idx}>
                        <div
                          style={{
                            position: 'absolute',
                            cursor: 'pointer',
                            left: `${element.coord.x * viewport.width / props.width}px`,
                            top: element?.is_manually_added ? `${element.coord.y * viewport.height / props.height}px` : `${viewport.height - (element.coord.y + element.coord.height) * viewport.height / props.height}px`,
                            width: `${element.coord.width * viewport.width / props.width}px`,
                            height: `${element.coord.height * viewport.height / props.height}px`,
                            padding: 0,
                            border: element === props.activeField ? '2px solid #ff4d4fad' : null,
                            backgroundColor: props.role_idx ? lightColors[props.role_idx + 2] : '#d7f9ff',
                            boxSizing: 'unset'
                          }}
                        >
                          <canvas
                            id={element.key}
                            ref={addToSignatureRefs}
                            width={element.coord.width * viewport.width / props.width}
                            height={element.coord.height * viewport.height / props.height}
                            padding={10}
                            onClick={(event) => handleClickField(element, event)}
                          ></canvas>
                        </div>
                      </a>
                    );
                  case 'PDFRequiredTextField':
                  case 'PDFRequiredCheckBox':
                    return (
                      <a data-uk-toggle="target: #modal-required-field" key={idx}>
                        <div
                          style={{
                            position: 'absolute',
                            cursor: 'pointer',
                            left: `${element.coord.x * viewport.width / props.width}px`,
                            top: `${viewport.height - (element.coord.y + element.coord.height) * viewport.height / props.height}px`,
                            width: `${element.coord.width * viewport.width / props.width}px`,
                            height: `${element.coord.height * viewport.height / props.height}px`,
                            padding: 0,
                            border: element === props.activeField ? '2px solid #ff4d4fad' : null,
                            backgroundColor: props.role_idx ? lightColors[props.role_idx + 2] : '#d7f9ff',
                            boxSizing: 'unset'
                          }}
                        >
                          <canvas
                            id={element.key}
                            ref={addToSignatureRefs}
                            width={element.coord.width * viewport.width / props.width}
                            height={element.coord.height * viewport.height / props.height}
                            onClick={(event) => handleClickField(element, event)}
                          ></canvas>
                        </div>
                      </a>
                    );
                  case 'PDFDateSigned':
                    return (
                      <input
                        key={idx} style={{
                          position: 'absolute',
                          cursor: 'pointer',
                          left: `${element.coord.x * viewport.width / props.width}px`,
                          top: element?.is_manually_added ? `${element.coord.y * viewport.height / props.height}px` : `${viewport.height - (element.coord.y + element.coord.height) * viewport.height / props.height}px`,
                          width: `${element.coord.width * viewport.width / props.width}px`,
                          height: `${element.coord.height * viewport.height / props.height}px`,
                          padding: 0,
                          outline: 'none',
                          border: 'none',
                          background: 'transparent'
                        }}
                        id={element.key}
                        value={moment(new Date()).format('MM/DD/YYYY')}
                        readOnly
                      />
                    );
                  case 'PDFCheckBox':
                    return (
                      <div
                        key={idx}
                        style={{
                          position: 'absolute',
                          cursor: 'pointer',
                          left: `${element.coord.x * viewport.width / props.width}px`,
                          top: `${viewport.height - (element.coord.y + element.coord.height) * viewport.height / props.height}px`,
                          width: `${element.coord.width * viewport.width / props.width}px`,
                          height: `${element.coord.height * viewport.height / props.height}px`,
                          padding: 0,
                          outline: 'none',
                          border: element === props.activeField ? '2px solid #ff4d4fad' : null,
                          background: 'transparent'
                        }}
                      >
                        <i className="fa fa-check" aria-hidden="true"></i>
                      </div>
                    );
                  case 'PDFTextField':
                    return (
                      <input
                        key={idx} style={{
                          position: 'absolute',
                          cursor: element.assigner === 'Sender' ? 'pointer' : 'default',
                          left: `${element.coord.x * viewport.width / props.width}px`,
                          top: `${viewport.height - (element.coord.y + element.coord.height) * viewport.height / props.height}px`,
                          width: `${element.coord.width * viewport.width / props.width}px`,
                          height: `${element.coord.height * viewport.height / props.height}px`,
                          padding: 0,
                          outline: 'none',
                          border: props.activeField === element ? '2px solid #ff4d4fad' : '0px',
                          backgroundColor: props.role_idx ? lightColors[props.role_idx + 2] : '#d7f9ff',
                        }}
                        id={element.key}
                        defaultValue={element.assigner === 'Sender' ? element.value : ''}
                        onChange={(evt) => handleChangeText(evt, element.key)}
                        readOnly={element.assigner === 'Sender'}
                        autoComplete="off"
                      />
                    );
                  default:
                    return (
                      <input
                        key={idx} style={{
                          position: 'absolute',
                          cursor: 'pointer',
                          left: `${element.coord.x * viewport.width / props.width}px`,
                          top: `${viewport.height - (element.coord.y + element.coord.height) * viewport.height / props.height}px`,
                          width: `${element.coord.width * viewport.width / props.width}px`,
                          height: `${element.coord.height * viewport.height / props.height}px`,
                          padding: 0,
                          outline: 'none',
                          border: 'none',
                          background: 'transparent'
                        }}
                        id={element.key}
                        value={element.value}
                        readOnly
                      />
                    );
                }
              })
            }
          </div>
        </Page>
      ))}
      <PDFSignature updateSignerData={props.updateSignerData} handleUpdateCanvas={handleUpdateCanvas} removeSignerData={props.removeSignerData} elementId={signatureElemId} modalType={modalType} />
      <PDFRequiredField handleUpdateCanvas={handleUpldateRequireField} removeSignerData={props.removeSignerData} elementId={requiredElementId} modalType={requiredFieldType} questionValue={fieldQuestion} />
    </Document>
  );
}

export default PDFSignerViewer;