import React, { useEffect, useRef, useState } from "react";
import "./pdf-container.css"
import { Box, Button, Grid, Paper, Tooltip } from "@mui/material";
import PdfPagination from "./PdfPagination";
import PdfRender from "./PdfRender";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import { fontSize } from "@mui/system";
import { toast } from "react-toastify";



const PDfEditorContainer = ({
  doc,
  onSubmitUpdatedPDF,
  formData,
  savePDF,
  downloadPDF,
  mergeProps,
  getEditedPdfUrl,
  templatePageCount,
  textFields,
  logoFields,
  setLogoFields,
  setTextFields,
  checkboxFields,
  setCheckboxFields,
  onSaveDocumentVersion,
  onMergeDocument,
  signatureFields,
  setSignatureFields,
  signatureAssigner,
  getSignatureBgColor,
  onDeleteDocumentPage
}) => {

  const ref = useRef();

  const [pageNumber, setPageNumber] = useState(1);
  const [fieldsCount, setFieldsCount] = useState(0);
  // size state for pdf page zoom in zoom out
  const [size, setSize] = useState(1);

  // taking ref for paper scrollWidth
  const paperRef = useRef(null);

  // state for know horizontalscroll bar is there or not 
  const [isHorizontalScrollbarVisible, setIsHorizontalScrollbarVisible] = useState(false);

  // state for save scroll positions of pdf before zoom
  const [currentScrollPosition, setCurrentScrollPosition] = useState({ scrollTop: 0, scrollLeft: 0 });

  // store all formFields of pdf
  const [formFieldsForUndoRedo, setFormFieldsForUndoRedo] = useState([]);

  // object for track changes - used in undo redo functionality
  const [fieldTrackObj, setFieldTrackObj] = useState({});

  // array for track changes - used in undo redo functionality
  const [fieldTrackArr, setFieldTrackArr] = useState([]);

  // index for undo redo changes track
  const fieldTrackArrIndex = useRef(0);

  // index for at which place change occurs and it will help to track particular fields array
  const indexForTrackChange = useRef(0);

  // taken event on change for take value and name of that changed field
  const [tempEvent, setTempEvent] = useState({});

  // states for disable enable undo redo buttons
  const [disableUndo, setDisableUndo] = useState(true);
  const [disableRedo, setDisableRedo] = useState(true);

  const [isAddingText, setIsAddingText] = useState(false);
  const [isAddingLogo, setIsAddingLogo] = useState(false);
  const [isAddingCheckbox, setIsAddingCheckbox] = useState(false);


  const [numPages, setNumPages] = useState(null);

  // for merge pdf
  const { mergedPdfUrl, setMergedPdfUrl, files, setFiles, isConfirmForMerge, setIsConfirmForMerge } = mergeProps;

  // useEffect for disable enable undo redo button
  useEffect(() => {
    // if change tracker array have items then only undo button enable
    if (fieldTrackArr.length > 0 && fieldTrackArrIndex.current >= 0) {
      setDisableUndo(false);
    }
    else {
      setDisableUndo(true);
    }

    // if change tracker array length and undo-redo index are same then redo will disable
    if (fieldTrackArr.length > 0 && fieldTrackArrIndex.current === fieldTrackArr.length) {
      setDisableRedo(true);
    }
  }, [fieldTrackArr, fieldTrackArrIndex.current])

  // useEffect for set index for every change
  useEffect(() => {
    // this function will check if change is done before in that field then it will set index one more than last change's index
    const setIndexForTrackChange = () => {
      for (let i = fieldTrackArr.length - 1; i >= 0; i--) {
        if (fieldTrackArr[i]["fieldName"] === tempEvent.target.name) {
          return fieldTrackArr[i]["index"] + 1;
        }
      }
      return 1;
    }

    indexForTrackChange.current = setIndexForTrackChange();

  }, [tempEvent])

  // Function to handle form field value changes
  const handleFieldChange = async (e) => {
    e.preventDefault();

    // undo-redo functionality for all textbox
    if (["text", "textarea", "checkbox"].includes(e.target.type)) {

      // when new change comes then remove all changes after undo-redo Index in change tracker array (fieldTrackArr)
      setFieldTrackArr((prev) => {
        return prev.slice(0, fieldTrackArrIndex.current + 1);
      })

      // manage change index of particular change , tempEvent is useEffect's dependency
      if (["text", "textarea"].includes(e.target.type)) {
        setTempEvent(e);
      }

      // fill changes in fieldTrackArr with fieldName , index at change occur and value of change
      setFieldTrackArr((prev) => {


        // set undo-redo index at last change 
        fieldTrackArrIndex.current = prev.length;

        if (["text", "textarea"].includes(e.target.type)) {
          return [...prev, {
            "fieldName": e.target.name,
            "index": indexForTrackChange.current,
            "fieldValue": e.target.value,
            "fieldType": e.target.type,
          }]
        } else if (e.target.type === "checkbox") {
          // no need for remember checkbox value bcz it can't be multiple
          return [...prev, {
            "fieldName": e.target.name,
            "fieldValue": e.target.checked,
            "fieldType": e.target.type,
          }]
        }
      })

      // fill fieldTrackObj for particular field change, this obj is initialized at the time of renderPdf
      if (fieldTrackObj) {
        for (const [key] of Object.entries(fieldTrackObj)) {
          // condition for find particular field when change occur
          if (key === e.target.name) {
            setFieldTrackObj((prev) => {
              /* 
               * this slice is very important bcz when undo clicked by user 
               * and then user will do another change in same field then that field's arr should contain only changes till last undo's index
               * otherwise all values remain same till undo's index
               */
              prev[key] = prev[key].slice(0, indexForTrackChange.current)
              prev[key] = [...prev[key], e.target.value];
              return prev;
            })
          }
        }
      }

      setDisableUndo(false);
      setDisableRedo(true);
    }


    // Extract the field name and value from the event target
    const fieldName = e.target.name;
    const fieldValue =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    // Create a payload object to represent the field's name, value, and type
    const payload = {
      fieldName: fieldName,
      fieldValue: fieldValue,
      fieldType: e.target.type === "textarea" ? "text" : e.target.type,
    };

    // Check if the field is already in the formData array
    const prefilled = formData.current.find(
      (elem) => elem.fieldName === fieldName
    );

    // Update the formData array with the new or updated field data
    if (prefilled) {
      // setFormData((formData) =>
      //   formData.map((data) => (data.fieldName === fieldName ? payload : data))
      // );
      // updatePresent((state)=> state.map((data) => (data.fieldName === fieldName ? payload : data)))
      formData.current = formData.current.map((data) =>
        data.fieldName === fieldName ? payload : data
      );
    } else {
      // If the field is not in formData, add it to the array
      // setFormData((formData) => [...formData, payload]);
      // updatePresent((state)=>[...state,payload])
      formData.current = [...formData.current, payload];
    }
  };


  const renderPdf = (e, pageNum) => {

    // Access form fields when the page is rendered
    const pages = document.querySelectorAll(
      `div.react-pdf__Page[data-page-number="${pageNum}"]`
    );

    const page = pages[pages.length - 1];

    const formFields = page.querySelectorAll("input, select, textarea");

    // fieldTrackObj filling and event listener should add 
    formFields.forEach((field) => {
      const fieldName = field.getAttribute("name");
      const fieldValue = field.getAttribute("value")
      const fieldType = field.getAttribute("type");
      if (fieldName) {
        /*
         * make object of all textFields for track changes
         * this object will contain all fields array 
         * that arrays will contain sequence wise changes of that field only
         * we have to make this object only once so object condition added to check is it empty or not 
         */
        if (fieldName.includes("Textbox") || fieldName.includes("Text")) {

          setFieldTrackObj((prev) => {

            if (prev && !(prev.hasOwnProperty(fieldName))) {
              // field is empty then array will contain empty string other wise that field's value
              if (field.tagName === "TEXTAREA" && field.innerHTML) {
                prev[fieldName] = [field.innerHTML];
              }
              else if ((!fieldValue) || (field.tagName === "TEXTAREA" && !field.innerHTML)) {
                prev[fieldName] = [" "];
              } else {
                prev[fieldName] = [fieldValue];
              }
              return prev;
            }
            else {
              return prev;
            }

          });
        }
      }
    });

    // Add an event listener to track changes in form field values
    formFields.forEach((field) => {
      field.addEventListener("change", handleFieldChange);
    });

    if (formData.current) {
      formFields.forEach((field) => {
        const fieldName = field.getAttribute("name");

        formData.current.map((updateVal) => {
          if (fieldName === updateVal.fieldName) {
            switch (updateVal.fieldType) {
              case "text":
                field.value = updateVal.fieldValue;
                break;
              case "checkbox":
                field.checked = updateVal.fieldValue;
                break;
              default:
                break;
            }
          }
        });
      });
    }
  };

  // function which will run on undo button click
  const undoHandler = () => {

    // undo will happen only when fieldTrackArr have changes and undo-redo index should more than 0
    if (fieldTrackArr.length > 0 && fieldTrackArrIndex.current >= 0) {
      let undoDone = false;
      for (let i = 1; i <= numPages; i++) {

        // taken all pages of pdf
        const pages = document.querySelectorAll(
          `div.react-pdf__Page[data-page-number="${i}"]`
        );

        // taken rendered page for changes
        const page = pages[pages.length - 1];

        // select all form fields for track change
        const formFields = page.querySelectorAll("input, select, textarea");

        // taken fieldName and Index for do change in that field only
        let fieldNameForCheck = fieldTrackArr[fieldTrackArrIndex.current]["fieldName"];
        let fieldType = fieldTrackArr[fieldTrackArrIndex.current]["fieldType"];
        let undoValue = (["text", "textarea"].includes(fieldType)) ? "" : false;

        if (fieldTrackObj && (["text", "textarea"].includes(fieldType))) {
          let fieldIndex = fieldTrackArr[fieldTrackArrIndex.current]["index"] - 1;

          for (const [key] of Object.entries(fieldTrackObj)) {
            // go on field where we want to do undo value
            if (key === fieldNameForCheck) {

              // taken previous value which was there before change
              undoValue = fieldTrackObj[key][fieldIndex];

              // put that value on last changed field
              formFields.forEach((field) => {
                const fieldName = field.getAttribute("name");
                if (fieldName === key) {
                  field.value = undoValue;
                  undoDone = true;
                }
              })
            }
          }
        }
        else if (fieldType === "checkbox") {
          // change checkbox value like checked or not
          formFields.forEach((field) => {
            const fieldName = field.getAttribute("name");
            if (fieldName === fieldNameForCheck) {
              undoValue = !(field.checked)
              undoDone = true;
              field.checked = !(field.checked);
            }
          })
        }

        if (undoDone) {
          // need to update form data also
          formData.current = formData.current.map((changeObj, index) => {
            if (changeObj.fieldName === fieldNameForCheck) {
              return (
                {
                  ...changeObj,
                  fieldValue: undoValue
                }
              )
            }
            else {
              return changeObj;
            }
          })
          break;
        }

      }


      // decrease undo-redo index by one after undo done
      fieldTrackArrIndex.current = fieldTrackArrIndex.current - 1;

      //disable undo button if undo-redo index is less than 0
      setDisableUndo(fieldTrackArrIndex.current === -1 ? true : false);
      //disable redo button if undo-redo index is equals to fieldTrackArr's length
      setDisableRedo(fieldTrackArrIndex.current === fieldTrackArr.length ? true : false);
    }
    else {
      console.log("nothing changed");
    }
  }

  const redoHandler = () => {

    // redo will happen only when fieldTrackArr have changes and undo-redo index should less than fieldTrackArr's length
    if (fieldTrackArr.length > 0 && fieldTrackArrIndex.current < fieldTrackArr.length) {

      let redoDone = false;
      for (let i = 1; i <= numPages; i++) {

        // taken all pages of pdf
        const pages = document.querySelectorAll(
          `div.react-pdf__Page[data-page-number="${i}"]`
        );

        // taken rendered page for changes
        const page = pages[pages.length - 1];

        // select all form fields for track change
        const formFields = page.querySelectorAll("input, select, textarea");

        // taken fieldName and Index for do change in that field only
        let fieldNameForCheck = fieldTrackArr[fieldTrackArrIndex.current + 1]["fieldName"];
        let fieldType = fieldTrackArr[fieldTrackArrIndex.current + 1]["fieldType"];
        let undoValue = (["text", "textarea"].includes(fieldType)) ? "" : false;
        if (["text", "textarea"].includes(fieldType)) {
          let fieldIndex = fieldTrackArr[fieldTrackArrIndex.current + 1]["index"];

          if (fieldTrackObj) {
            for (const [key] of Object.entries(fieldTrackObj)) {
              // go on field where we want to do undo value
              if (key === fieldNameForCheck) {

                // taken previous value which was there before change
                undoValue = fieldTrackObj[key][fieldIndex];

                // put that value on last changed field
                formFields.forEach((field) => {
                  const fieldName = field.getAttribute("name");
                  if (fieldName === key) {
                    field.value = undoValue;
                    redoDone = true;

                  }
                })
              }
            }
          }
        }
        else if (fieldType === "checkbox") {
          // change checkbox value like checked or not
          formFields.forEach((field) => {
            const fieldName = field.getAttribute("name");
            if (fieldName === fieldNameForCheck) {
              undoValue = !(field.checked);
              redoDone = true;
              field.checked = !(field.checked);
            }
          })
        }

        if (redoDone) {
          // need to update form data also
          formData.current = formData.current.map((changeObj, index) => {
            if (changeObj.fieldName === fieldNameForCheck) {
              return (
                {
                  ...changeObj,
                  fieldValue: undoValue
                }
              )
            }
            else {
              return changeObj;
            }
          });

          break;

        }

      }



      // decrease undo-redo index by one after undo done
      fieldTrackArrIndex.current = fieldTrackArrIndex.current + 1;

      //disable redo button if undo-redo index is equals to fieldTrackArr's length
      setDisableRedo(fieldTrackArrIndex.current === fieldTrackArr.length - 1 ? true : false);
      //disable undo button if undo-redo index is less than 0
      setDisableUndo(fieldTrackArrIndex.current === -1 ? true : false);
    }
    else {
      console.log("nothing changed");
    }
  }

  const handleSelectFile = (e) => {
    document.getElementById('file').click();
  }

  const handleFileChange = async (e) => {
    // setFile(e.target.files[0]);
    // console.log(e.target.files[0], doc, "file");
    const url = URL.createObjectURL(e.target.files[0]);
    // setUrl(url)
    // console.log(url, "URL");

    let blobOfUrl;
    fetch(url).then((res) => { blobOfUrl = res.url; console.log(res, "REsponse") }).catch();
    // console.log(blobOfUrl, "blob of url");
  }


  // function for handle text change for custom added textbox
  const handleTextChange = (e, id) => {
    setTextFields(
      textFields.map((field) =>
        field.id === id ? { ...field, text: e.target.value } : field
      )
    );
  };

  const handleTextFontSize = (e, id) => {
    setTextFields(
      textFields.map((field) =>
        field.id === id ? {
          ...field, fontSize: e.target.value,
          initialWidth: ((Math.max(50, ((Math.max(field?.text.split('\n').map(line => line.length))) * (field?.fontSize) * (field?.size) / (1.6))))),
          initialHeight: ((Math.max(28, (((field?.text.split('\n').length) * ((field?.fontSize) * (1.5))) * (field?.size)))))
          // initialWidth: Math.max(50, (field?.text.split('\n').map(line => line.length) * (50 / 5) * size * (field?.fontSize / 15))),
          // initialHeight: Math.max(28, ((field?.text.split('\n').length + 1) * 28 * size * (field?.fontSize / 40)))

        } : field
      )
    );
  };


  const handleCheckboxSize = (size, id) => {
    setCheckboxFields(
      checkboxFields.map((field) =>
        field.id === id ? { ...field, size: size } : field
      )
    );
  };

  const handleLogoSize = (size, id) => {
    setLogoFields(
      logoFields.map((field) =>
        field.id === id ? { ...field, size: size } : field
      )
    );
  };

  const handleTextBackground = (isBackgroundTransparent, id) => {
    setTextFields(
      textFields.map((field) =>
        field.id === id ? { ...field, background: !isBackgroundTransparent } : field
      )
    );
  };

  const handleCheckboxBackground = (isBackgroundTransparent, id) => {
    setCheckboxFields(
      checkboxFields.map((field) =>
        field.id === id ? { ...field, background: !isBackgroundTransparent } : field
      )
    );
  };

  const handleLogoBackground = (isBackgroundTransparent, id) => {
    setLogoFields(
      logoFields.map((field) =>
        field.id === id ? { ...field, background: !isBackgroundTransparent } : field
      )
    );
  };

  const handleTextDelete = (e, id) => {
    setTextFields(
      textFields.filter((field) =>
        field.id !== id
      )
    );
  };

  // function for handle manually added signature/initials/date deletion
  const handleSignatureDelete = (e, key) => {
    setSignatureFields(
      signatureFields.filter((field) =>
        field.key !== key
      )
    );
  };

  const handleCheckboxDelete = (e, id) => {
    setCheckboxFields(
      checkboxFields.filter((field) =>
        field.id !== id
      )
    );
  };

  const handleLogoDelete = (e, id) => {
    setLogoFields(
      logoFields.filter((field) =>
        field.id !== id
      )
    );
  };

  // function for handle text change for custom added textbox
  const handleCheckboxChange = (e, id) => {
    setCheckboxFields(
      checkboxFields.map((field) =>
        field.id === id ? { ...field, checked: e.target.checked } : field
      )
    );
  };
  const handleCheckboxClick = (id) => {
    setCheckboxFields(
      checkboxFields.map((field) =>
        field.id === id ? { ...field, checked: field.checked === 2 ? 0 : field.checked + 1 } : field
      )
    );
  };

  const handleLogoClick = (id, file) => {
    if (!file) {
      return
    }
    if (!["image/png", "image/jpg", "image/jpeg"]?.includes(file.type)) {
      toast.error("Only .png, .jpg, .jpeg support.")
      return
    }
    const imageUrl = URL.createObjectURL(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      // Create a new Image instance to load the selected file
      const img = new Image();
      img.src = e.target.result; // Set the image source to the file's data URL

      // Once the image is fully loaded, calculate its dimensions
      img.onload = () => {
        const maxSize = 600; // Define the maximum dimension (width or height) for scaling
        let scaledWidth = img.width; // Initialize scaled width with the original width
        let scaledHeight = img.height; // Initialize scaled height with the original height

        // Check if the image's dimensions exceed the defined maximum size
        if (img.width > maxSize || img.height > maxSize) {
          if (img.width > img.height) {
            // If the image is in landscape orientation (width > height),
            // scale the width to the maximum size and adjust the height proportionally
            scaledWidth = maxSize;
            scaledHeight = (img.height / img.width) * maxSize;
          } else {
            // If the image is in portrait orientation (height >= width),
            // scale the height to the maximum size and adjust the width proportionally
            scaledHeight = maxSize;
            scaledWidth = (img.width / img.height) * maxSize;
          }
        }

        // Update the state with the scaled dimensions and additional data
        setLogoFields(
          logoFields.map((field) =>
            field.id === id
              ? {
                ...field,
                logo: imageUrl, // Store the image URL for rendering
                file: file, // Store the original file object
                initialWidth: scaledWidth, // Store the scaled width
                initialHeight: scaledHeight, // Store the scaled height
              }
              : field // Keep the other fields unchanged
          )
        );
      };
    };

    // Ensure `readAsDataURL` is called after defining the `reader.onload`
    // This reads the file as a data URL, triggering the `reader.onload` event
    reader.readAsDataURL(file);

  }


  // function which will store true if content going out horizontally from container
  const handleHiddenScrollContent = async () => {
    if (paperRef.current) {
      // Check if horizontal scrollbar is visible
      const isOverflowingHorizontally = paperRef.current.scrollWidth > paperRef.current.clientWidth;
      setIsHorizontalScrollbarVisible(isOverflowingHorizontally); // Set state based on overflow
    }
  };

  // Store scroll positions before zoom
  const saveScrollPosition = () => {
    if (paperRef.current) {
      return {
        scrollTop: paperRef.current.scrollTop,
        scrollLeft: paperRef.current.scrollLeft
      };
    }
    return { scrollTop: 0, scrollLeft: 0 };
  };

  // Restore scroll positions after zoom
  const restoreScrollPosition = (scrollPosition) => {
    if (paperRef.current) {
      // Use scrollTo with smooth behavior to restore the scroll position smoothly
      paperRef.current.scrollTo({
        top: scrollPosition.scrollTop,
        left: scrollPosition.scrollLeft,
        behavior: "auto"
      });
    }
  };

  // Track scroll position during scrolling
  const handleScroll = () => {
    saveScrollPosition();
  }

  // function for zoom in pdf
  const handleZoomInPdf = () => {
    setCurrentScrollPosition(saveScrollPosition());
    setSize((prev) => {
      return (prev + 0.2)
    });
  }

  // function for zoom out pdf
  const handleZoomOutPdf = () => {
    setCurrentScrollPosition(saveScrollPosition());
    setSize((prev) => {
      return (prev - 0.2)
    });
  }

  return (
    <Grid container className="h-100">
      <Grid item md={1} className="h-100">
        <Paper
          sx={{ fontSize: 1, overflow: "auto", position: "relative", boxShadow: '0' }}
          className="bg-light h-100  "
        >
          <PdfPagination
            doc={doc}
            pageProps={{ pageNumber, setPageNumber, numPages, setNumPages }}
            mergeProps={{ mergedPdfUrl, setMergedPdfUrl, files, setFiles, isConfirmForMerge, setIsConfirmForMerge }}
            getEditedPdfUrl={getEditedPdfUrl}
            undoProps={{ disableUndo, setDisableUndo }}
            redoProps={{ disableRedo, setDisableRedo }}
            setFieldTrackArr={setFieldTrackArr}
            onSaveDocumentVersion={onSaveDocumentVersion}
            onMergeDocument={onMergeDocument}
            onDeleteDocumentPage={onDeleteDocumentPage}
            templatePageCount={templatePageCount}
          />
          {/*           
          <div className="shadow d-flex align-items-center justify-content-center  add-pdf-button rounded p-1">
            <input id="file" type="file" accept="application/pdf" onChange={handleFileChange} ref={ref} hidden />
            <span className="h-100 w-100 d-flex align-items-center justify-content-center" onClick={handleSelectFile}>+</span>
          </div> */}

        </Paper>

      </Grid>

      <Grid item md={9} className="h-100">
        <div
          className="d-flex justify-content-end align-items-center pt-1"
          style={{ paddingBottom: "12px" }}
          sx={{ zIndex: 10 }}
        >
          <Tooltip title={"Logo"} placement="bottom"
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -11], // Adjust the second value (5) to control the distance
                  },
                },
              ],
            }}
          >
            <button
              className={`uk-button download-button ${!isAddingLogo && "disabled-undo-redo-button"}`}
              variant="text"
              onClick={() => setIsAddingLogo((prev) => {
                setIsAddingText(false);
                setIsAddingCheckbox(false);
                return (!prev);
              })}
            >
              <i class="fa fa-solid fa-image text-dark" aria-hidden="true" style={{ fontSize: "18px" }}></i>
            </button>
          </Tooltip>

          <Tooltip title={"Checkbox"} placement="bottom"
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -11], // Adjust the second value (5) to control the distance
                  },
                },
              ],
            }}
          >
            <button
              className={`uk-button download-button ${!isAddingCheckbox && "disabled-undo-redo-button"}`}
              variant="text"
              onClick={() => setIsAddingCheckbox((prev) => { setIsAddingText(false); return (!prev) })}
            >

              <i class="fa fa-check text-dark" aria-hidden="true" style={{ fontSize: "18px" }}></i>
            </button>
          </Tooltip>

          <Tooltip title={"Textbox"} placement="bottom"
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -11], // Adjust the second value (5) to control the distance
                  },
                },
              ],
            }}
          >
            <button
              className={`uk-button download-button ${!isAddingText && "disabled-undo-redo-button"}`}
              variant="text"
              onClick={() => setIsAddingText((prev) => { setIsAddingCheckbox(false); return (!prev) })}
            >
              <b className="text-dark" style={{ fontSize: "20px" }}>T</b>
            </button>
          </Tooltip>

          <div
            className="d-flex justify-content-center align-items-center"
          // style={{width: "40%" }}
          >
            <Tooltip title={"Undo"} placement="bottom"
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -11], // Adjust the second value (5) to control the distance
                    },
                  },
                ],
              }}
            >
              <button
                className={`uk-button download-button ${disableUndo && "disabled-undo-redo-button"}`}
                disabled={disableUndo}
                variant="text"
                onClick={() => undoHandler()}
              >
                <UndoIcon />
              </button>
            </Tooltip>

            <Tooltip title={"Redo"} placement="bottom"
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -11], // Adjust the second value (5) to control the distance
                    },
                  },
                ],
              }}
            >
              <button
                className={`uk-button download-button ${disableRedo && "disabled-undo-redo-button"}`}
                disabled={disableRedo}
                variant="text"
                onClick={() => redoHandler()}
              >
                <RedoIcon />
              </button>
            </Tooltip>
          </div>
          <div>

            <Tooltip title={"Zoom Out"} placement="bottom"
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -11], // Adjust the second value (5) to control the distance
                    },
                  },
                ],
              }}
            >
              <Button
                sx={size.toFixed(1) <= "0.4" ? { border: 1, borderRadius: '8px', borderColor: "lightgray", marginRight: "10px" } : { border: 1, borderRadius: '8px', borderColor: "#3e8eff", marginRight: "10px" }}
                disabled={size.toFixed(1) <= "0.4"}
                variant="text"
                onClick={handleZoomOutPdf}
              >
                <ZoomOutIcon />{" "}
              </Button>
            </Tooltip>

            <Tooltip title={"Zoom In"} placement="bottom"
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -11], // Adjust the second value (5) to control the distance
                    },
                  },
                ],
              }}
            >
              <Button
                sx={size.toFixed(1) >= 10 ? { border: 1, borderRadius: '8px', borderColor: "lightgray", marginRight: "10px" } : { border: 1, borderRadius: '8px', borderColor: "#3e8eff", marginRight: "10px" }}
                disabled={size.toFixed(1) >= 10}
                variant="text"
                onClick={handleZoomInPdf}
              >
                <ZoomInIcon />{" "}
              </Button>
            </Tooltip>
          </div>
        </div>
        <Paper
          ref={paperRef}
          sx={{ fontSize: 1, overflow: "auto", position: "relative", height: "92.75%", width: "100%", boxShadow: '0' }}
          className="bg-light square "
          onScroll={handleScroll}
        >
          <PdfRender
            currentScrollPosition={currentScrollPosition}
            restoreScrollPosition={restoreScrollPosition}
            handleHiddenScrollContent={handleHiddenScrollContent}
            isHorizontalScrollbarVisible={isHorizontalScrollbarVisible}
            props={{ formData }}
            renderPdf={renderPdf}
            doc={doc}
            pageProps={{ pageNumber, setPageNumber, size, setSize }}
            mergeProps={{ mergedPdfUrl, setMergedPdfUrl }}
            templatePageCount={templatePageCount}
            // handlePdfClick={handlePdfClick}
            textFields={textFields}
            setTextFields={setTextFields}
            logoFields={logoFields}
            setLogoFields={setLogoFields}
            setCheckboxFields={setCheckboxFields}
            handleTextChange={handleTextChange}
            handleTextDelete={handleTextDelete}
            handleLogoDelete={handleLogoDelete}
            handleTextFontSize={handleTextFontSize}
            handleLogoSize={handleLogoSize}
            checkboxFields={checkboxFields}
            handleCheckboxChange={handleCheckboxChange}
            handleCheckboxClick={handleCheckboxClick}
            handleLogoClick={handleLogoClick}
            handleTextBackground={handleTextBackground}
            handleLogoBackground={handleLogoBackground}
            handleCheckboxDelete={handleCheckboxDelete}
            handleCheckboxBackground={handleCheckboxBackground}
            handleCheckboxSize={handleCheckboxSize}
            isAddingText={isAddingText}
            setIsAddingText={setIsAddingText}
            setIsAddingCheckbox={setIsAddingCheckbox}
            isAddingCheckbox={isAddingCheckbox}
            isAddingLogo={isAddingLogo}
            setIsAddingLogo={setIsAddingLogo}
            signatureFields={signatureFields}
            setSignatureFields={setSignatureFields}
            signatureAssigner={signatureAssigner}
            getSignatureBgColor={getSignatureBgColor}
            handleSignatureDelete={handleSignatureDelete}
          />

        </Paper>
      </Grid>

      <Grid item md={2} className="h-100">
        <div className="h-100 square ">
          <div className="h-100 w-100 ">
            <div className="h-50 w-100 d-flex flex-column align-items-center justify-content-between">
              <span className="mt-1 text-center px-1"><b>Add given fields by clicking anywhere on the document</b></span>
              <div
                className="d-flex justify-content-center align-items-center w-50"
              // style={{width: "40%" }}
              >
                <Box component="a"  >
                  <img src={process.env.PUBLIC_URL + "/images/boatdox-logo-blue.png"} alt="logo" style={{ marginBottom: "80px" }} />
                </Box>
              </div>
            </div>

            <div className="h-50 d-flex flex-column align-items-center ">
              {/* 3 download button  */}
              <div>
                <button
                  className="uk-button download-button"
                  onClick={() => downloadPDF()}
                >
                  <FontAwesomeIcon icon={faDownload} />
                  <span> Download </span>
                </button>
              </div>

              {/* 4 submit document button */}
              <div className="mt-3">

                <button

                  className="uk-button submit-document-button "
                  onClick={() => savePDF()}
                >
                  {/* <FontAwesomeIcon icon={faCloud} /> */}
                  <span> Save Changes </span>
                </button>
              </div>

              {/* 5 some text like The information and document that you filled in will be transmitted toBoatDox  */}
              <div className="mt-5 d-flex justify-content-center">

                <p className="w-75 text-center">
                  The information and document that you filled in will be transmitted to <b>BoatDox</b>
                </p>
              </div>

              {/* 6 comps image */}
              <div className="mt-5 pt-4 d-flex justify-content-center">
                <img src={process.env.PUBLIC_URL + "/images/boatdox-comps-pdf_editor.png"} alt="boatdox-comps" width="87%" />
              </div>

            </div>
          </div>
        </div>
      </Grid>
    </Grid >
  );
};

export default PDfEditorContainer;
