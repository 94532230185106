import React, { useEffect, useMemo, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import MoonLoader from "react-spinners/MoonLoader";
import { Rnd } from "react-rnd"; // Keep Rnd for resizable
import { Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Loading from "../loading";
import { height, width } from "@mui/system";
// import transparentPng from ""
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function PdfRender({
  restoreScrollPosition,
  currentScrollPosition,
  handleHiddenScrollContent,
  isHorizontalScrollbarVisible,
  isAddingText,
  setIsAddingText,
  setIsAddingCheckbox,
  isAddingLogo,
  setIsAddingLogo,
  setCheckboxFields,
  isAddingCheckbox,
  pageProps,
  doc,
  renderPdf,
  mergeProps,
  templatePageCount,
  textFields,
  setTextFields,
  logoFields,
  setLogoFields,
  handleLogoClick,
  handleTextChange,
  handleCheckboxSize,
  handleTextDelete,
  handleTextFontSize,
  handleLogoBackground,
  handleLogoDelete,
  checkboxFields,
  handleCheckboxChange,
  handleCheckboxClick,
  handleTextBackground,
  handleCheckboxDelete,
  handleCheckboxBackground,
  signatureFields,
  setSignatureFields,
  signatureAssigner,
  getSignatureBgColor,
  handleSignatureDelete
}) {
  const currentPage = useRef(0);
  const { mergedPdfUrl, setMergedPdfUrl } = mergeProps;
  const [numPages, setNumPages] = useState(0);
  const { pageNumber, setPageNumber, size, setSize } = pageProps;
  const [isLoading, setIsLoading] = useState(true);
  const [renderedPageNumber, setRenderedPageNumber] = useState(null);
  const [renderedScale, setRenderedScale] = useState(null);
  const pdfRef = useRef(null);
  const pageRefs = useRef(Array(numPages).fill().map(() => React.createRef()));
  //get number of pages
  useEffect(() => {
    pageRefs.current = Array(numPages).fill().map(() => React.createRef());
  }, [numPages]);
  //scroll to page on page number click
  useEffect(() => {
    if (pageNumber > 0 && pageNumber <= numPages) {
      const index = pageNumber - 1;
      const ref = pageRefs.current[index]?.current;
      if (ref) {
        ref.scrollIntoView({ behavior: 'smooth' });
      } else {
        console.error(`Ref for page ${pageNumber} is not ready`);
      }
    }
  }, [pageNumber, numPages]);

  function onDocumentLoadSuccess({ numPages }) {
    setIsLoading(false);
    setNumPages(numPages);
  }

  const fileOptions = useMemo(() => ({ url: mergedPdfUrl || doc }), [mergedPdfUrl, doc]);

  useEffect(() => {
    setIsLoading(true);
  }, [fileOptions]);

  // Adjust text fields when zoom level changes
  useEffect(() => {
    setTextFields(prev => prev.map(textField => ({
      ...textField,
      size: size,

    })));
    setCheckboxFields(
      prev => prev.map(cFields => ({
        ...cFields,
        size: size,
      }))
    )
  }, [size]);

  // handle click over pdf
  const handlePdfClick = (e, pageNumber) => {
    const pageElement = pageRefs.current[pageNumber - 1]?.current;
    if (pageElement) {
      const rect = pageElement.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;
      const xPosition = offsetX / size;  // Store position in unscaled coordinates
      const yPosition = offsetY / size;  // Store position in unscaled coordinates
      if (isAddingText) {
        setTextFields([
          ...textFields,
          {
            x: xPosition,
            y: yPosition,
            text: '',
            id: Date.now(),
            fontSize: 15,
            background: false,
            size: size,
            pageNumber: pageNumber,
            initialWidth: 50,  // Store initial unscaled width
            initialHeight: 28,   // Store initial unscaled height
            showControls: true  // Show controls when text field is added
          }
        ]);
        setIsAddingText(false);
      } else if (isAddingCheckbox) {
        setCheckboxFields([
          ...checkboxFields,
          {
            x: xPosition,
            y: yPosition,
            pageNumber: pageNumber,
            checked: 0,
            id: Date.now(),
            background: true,
            size: size,
            initialWidth: 25,  // Store initial unscaled width
            initialHeight: 25,
          }
        ]);
        setIsAddingCheckbox(false);
      } else if (isAddingLogo) {
        setLogoFields([
          ...logoFields,
          {
            x: xPosition,
            y: yPosition,
            pageNumber: pageNumber,
            logo: "/images/logo-upload.svg",
            id: Date.now(),
            // background: true,
            size: size,
            file: {},
            initialWidth: 54,  // Store initial unscaled width
            initialHeight: 54,
          }
        ]);
        setIsAddingLogo(false);
      }
    }
  };

  // handle drop signature/initials/date over pdf
  const handleDrop = (e, pageNumber, type) => {
    e.preventDefault();
    const pageElement = pageRefs.current[pageNumber - 1]?.current;
    if (pageElement && ["Signature", "Initials", "Date Signed"].includes(type)) {
      const rect = pageElement.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;
      const xPosition = offsetX / size;  // Store position in unscaled coordinates
      const yPosition = offsetY / size;  // Store position in unscaled coordinates
      const fieldBgColor = getSignatureBgColor(signatureAssigner);
      let newItem = {
        coord: {
          x: xPosition,
          y: yPosition,
          width: 140,
          height: 26
        },
        page_number: pageNumber,
        assigner: signatureAssigner || '',
        bg_color: fieldBgColor,
        is_manually_added: true
      };
      switch (type) {
        case 'Signature':
          newItem = {
            key: `Signature_${Date.now()}`,
            value: `Signature_${Date.now()}`,
            type: 'PDFSignature',
            ...newItem
          };
          break;
        case 'Initials':
          newItem = {
            ...newItem,
            key: `Initials_${Date.now()}`,
            value: '',
            type: 'PDFInitial',
            coord: {
              ...newItem.coord,
              width: 80,
              height: 26
            },
          };
          break;
        case 'Date Signed':
          newItem = {
            key: `DateSignature_${Date.now()}`,
            value: '',
            type: 'PDFDateSigned',
            ...newItem
          };
          break;
        default:
          break;
      }
      setSignatureFields((prev) => [...prev, { ...newItem }]);
    }
  }

  const loading = renderedPageNumber !== pageNumber;

  let checkboxImagesUrl = ["/images/check-box-tick.png", "/images/check-box-cross.png"];
  return (
    <div className="bg-gradient">
      <div className="d-flex justify-content-center">
        <Document
          file={fileOptions}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) => {
            setIsLoading(false);
          }}
          className={`d-flex align-items-center pdf-document-viewer ${isHorizontalScrollbarVisible ? 'pdf-horizontal-scroll' : ''} ${loading ? 'pdf-loaded' : ''}`}
          onRender={() => <Loading loading={true} />}
          loading={() => <div style={{ height: '75vh', display: 'flex', alignItems: 'center' }}><MoonLoader color="#0063A6" /></div>}
        >
          <div ref={pdfRef}>
            {Array.from(new Array(numPages)).map((_, index) => (
              <div key={index} className="mb-2 shadow" ref={pageRefs.current[index]}>
                <Page
                  onClick={(e) => handlePdfClick(e, index + 1)}
                  onDragOver={(e) => { e.preventDefault(); }}
                  onDrop={(e) => { handleDrop(e, index + 1, e.dataTransfer.getData('text')) }}
                  key={`${index}@${90}_canvas@${size}_canvas`}
                  scale={size}
                  wrap={true}
                  renderMode="canvas"
                  onRenderSuccess={() => {
                    handleHiddenScrollContent();
                    restoreScrollPosition(currentScrollPosition);
                  }}
                  pageNumber={index + 1}
                  enhanceTextSelection={true}
                  onLoadError={(error) => console.log('Error while loading document! ' + error.message)}
                  renderAnnotationLayer={true}
                  renderForms={true}
                  onRenderAnnotationLayerSuccess={(e) => { renderPdf(e, index + 1); setRenderedPageNumber(index + 1); }}
                  loading={() => <Loading loading={true} />}
                  className={`position-relative react-pdf__Page custom-editor-page ${(mergedPdfUrl || (templatePageCount && numPages > templatePageCount)) && "merge-pdf__page"}`}
                >
                  {/* Render Text Fields */}
                  {textFields?.map((field) => (
                    field.pageNumber === index + 1 && (
                      <Rnd
                        bounds="parent"
                        key={field.id}
                        position={{ x: field.x * size, y: field.y * size }} // Scale position by zoom factor
                        size={{
                          width: field.initialWidth * size,  // Scale width by zoom factor
                          height: field.initialHeight * size  // Scale height by zoom factor
                        }}
                        minHeight={10 * size}
                        minWidth={15 * size}
                        dragHandleClassName="handle-textbox-drag"
                        enableResizing={{
                          top: false,    // Disable top resize
                          right: false,  // Disable right resize
                          bottom: false, // Disable bottom resize
                          left: false,   // Disable left resize
                          topRight: false,    // Enable top-right corner resize
                          bottomRight: true, // Enable bottom-right corner resize
                          bottomLeft: false,  // Enable bottom-left corner resize
                          topLeft: false,     // Enable top-left corner resize
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                          // After resizing, store unscaled width and height
                          setTextFields(prevState => prevState.map(tField => {
                            if (tField.id === field.id) {
                              return {
                                ...tField,
                                initialWidth: ref.offsetWidth / size,  // Store unscaled width
                                initialHeight: ref.offsetHeight / size,  // Store unscaled height
                                width: ref.offsetWidth,  // Store scaled width
                                height: ref.offsetHeight  // Store scaled height
                              };
                            }
                            return tField;
                          }));
                        }}
                        onDragStop={(e, data) => {
                          // Update x/y based on zoom level
                          setTextFields(prevState => prevState.map(tField => {
                            if (tField.id === field.id) {
                              return {
                                ...tField,
                                x: data.x / size,  // Store in unscaled coordinates
                                y: data.y / size,  // Store in unscaled coordinates
                              };
                            }
                            return tField;
                          }));
                        }}
                        style={{
                          outline: "none",
                          border: "2px solid skyblue",
                          zIndex: 3,
                        }}
                      >
                        <div
                          className="border border-0"
                          style={{
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <textarea
                            value={field.text}
                            onChange={(e) => {
                              const textArea = e.target
                              let newWidth = Math.min(((textArea.scrollWidth) * (field?.size)), ((Math.max(...field?.text.split('\n').map(line => line.length))) * (field?.fontSize) * (field?.size)))
                              // let newWidth = Math.min((textArea.scrollWidth),(textArea.value.split('\n').map(line => line.length) * (minWidth) * size * (field?.fontSize/15)));
                              let minWidth = 50
                              let minHeight = 28
                              let newHeight = Math.min(((textArea.scrollHeight) * (field?.size)), (((field?.text.split('\n').length) * ((field?.fontSize) * (1.5))) * (field?.size) + (20)))
                              // let newHeight = Math.min((textArea.scrollHeight), ((textArea.value.split('\n').length + 1) * (minHeight) * size * (field?.fontSize/25)));
                              setTextFields(prev => prev.map((tField) =>
                                tField.id === field.id ? {
                                  ...tField,
                                  text: textArea.value,
                                  initialWidth: Math.max(newWidth, minWidth) / size,
                                  initialHeight: Math.max(minHeight, newHeight) / size
                                } : tField
                              )
                              );
                              // handleTextChange(e, field.id)
                            }}
                            style={{
                              height: '100%',
                              width: '100%',
                              backgroundColor: field.background === true ? "transparent" : "#d9e5ff",
                              border: '2px solid black',
                              fontSize: `${(field.fontSize) * size}px`,
                              color: 'black',
                              verticalAlign: "middle",
                              textWrap: "nowrap",
                              outline: "none",
                              overflow: "hidden"
                            }}
                            className="p-1 border border-0 outline-none"
                          />
                          <div className="d-flex justify-content-center align-items-center m-1 gap-2">
                            <div className="handle-textbox-drag border border-1 bg-light" style={{ cursor: "grab" }}>
                              <i className="fa fa-arrows-alt fa-lg m-1 "
                                aria-hidden="true"
                                style={{ fontSize: `${20 * size}px`, cursor: "all-scroll" }}
                                draggable={false}
                              ></i>
                            </div>
                            <i
                              className="fa fa-trash fa-lg text-danger m-1"
                              aria-hidden="true"
                              style={{ fontSize: `${25 * size}px`, cursor: "pointer" }}
                              onClick={(e) => handleTextDelete(e, field.id)}
                              draggable={false}
                            ></i>
                            <input type="number" min={6} max={50}
                              onChange={(e) => handleTextFontSize(e, field.id)}
                              value={field.fontSize}
                              className="rounded size-changer mx-1 p-0"
                              style={{
                                height: `${20 * size}px`,
                                width: `${40 * size}px`,
                                border: '2px solid black',
                                fontSize: `${15 * size}px`,
                                textAlign: 'center'
                              }}
                            />
                          </div>
                        </div>
                        <div
                          className="position-absolute m-1"
                          onClick={(e) => handleTextBackground(field?.background, field.id)}
                          style={{
                            backgroundColor: "#D9E5FF",
                            border: "1px solid black",
                            cursor: "pointer",
                            width: `${20 * size}px`,
                            height: `${20 * size}px`,
                            left: "100%"
                          }}>
                          {
                            !field?.background && <img src="/images/transparent.png" draggable={false}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover"
                              }} alt="transparent" />
                          }
                        </div>
                      </Rnd>
                    )
                  ))}
                  {/* Render Checkbox Fields */}
                  {checkboxFields?.map((field) => (
                    field.pageNumber === index + 1 && (
                      <Rnd
                        bounds="parent"
                        key={field.id}
                        position={{ x: field.x * size, y: field.y * size }} // Scale position by zoom factor
                        size={{
                          width: field?.initialWidth * size,  // Dynamically adjust width, fallback to 50 if not defined
                          height: field?.initialHeight * size, // Dynamically adjust height, fallback to 50 if not defined
                        }}
                        lockAspectRatio={true} // Lock aspect ratio to make resizing square
                        dragHandleClassName="handle-checkbox-drag"
                        onDragStop={(e, data) => {
                          // Update x/y based on zoom level for checkboxes
                          setCheckboxFields(prevState => prevState.map(cField => {
                            if (cField.id === field.id) {
                              return {
                                ...cField,
                                x: data.x / size,  // Store in unscaled coordinates
                                y: data.y / size,  // Store in unscaled coordinates
                              };
                            }
                            return cField;
                          }));
                        }}
                        enableResizing={{
                          top: false,    // Disable top resize
                          right: false,  // Disable right resize
                          bottom: false, // Disable bottom resize
                          left: false,   // Disable left resize
                          topRight: false,    // Enable top-right corner resize
                          bottomRight: true, // Enable bottom-right corner resize
                          bottomLeft: false,  // Enable bottom-left corner resize
                          topLeft: false,     // Enable top-left corner resize
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                          // Resize checkbox based on Rnd size and maintain square aspect ratio
                          setCheckboxFields(prevState => prevState.map(cField => {
                            if (cField.id === field.id) {
                              const newSize = ref.offsetWidth; // The width and height will be the same
                              return {
                                ...cField,
                                initialWidth: newSize / size,  // Store unscaled width
                                initialHeight: newSize / size,  // Store unscaled height
                                width: newSize,  // Store scaled width
                                height: newSize,  // Store scaled height
                              };
                            }
                            return cField;
                          }));
                        }}
                        style={{
                          border: "2px solid skyblue",
                          zIndex: 3,
                          padding: "0px",
                          margin: "0px"
                        }}
                      >
                        <div
                          className="p-0 m-0 position-relative"
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: field.background ? 'rgba(0,0,0,0.1)' : 'transparent',
                          }}
                        >
                          {/* checkbox image */}
                          <div className="w-100 h-100"
                            style={{
                              backgroundColor: `${field.background ? "white" : "transparent"}`,
                              cursor: "pointer",
                              userSelect: "none"
                            }}
                            onClick={(e) => { handleCheckboxClick(field.id) }}
                          >
                            {
                              field?.checked !== 2 && <img className="m-0 p-0" draggable={false}
                                src={checkboxImagesUrl[field?.checked]} style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain"
                                }} alt="checkbox" />
                            }
                          </div>
                          {/* checkbox controls */}
                          <div className="position-absolute d-flex  align-items-center justify-content-between  p-0" style={{ top: "100%", width: `${50}px`, marginTop: `${5 * size}px` }}>
                            {/* checkbox drag button */}
                            <div className="handle-checkbox-drag border border-1 bg-light" style={{ cursor: "grab" }}>
                              <i className="fa fa-arrows-alt fa-lg m-1 "
                                aria-hidden="true"
                                style={{ fontSize: `${20 * size}px`, cursor: "all-scroll" }}
                                draggable={false}
                              ></i>
                            </div>
                            <i
                              className="fa fa-trash fa-lg text-danger m-1"
                              aria-hidden="true"
                              style={{ fontSize: `${20 * size}px`, cursor: "pointer", }}
                              onClick={(e) => handleCheckboxDelete(e, field.id)}
                              draggable={false}

                            ></i>

                          </div>
                          {/* checkbox transparency button */}
                          <div
                            className="position-absolute m-1"
                            onClick={(e) => handleCheckboxBackground(field?.background, field.id)}
                            style={{
                              backgroundColor: "#D9E5FF",
                              border: "1px solid black",
                              cursor: "pointer",
                              width: `${15 * size}px`,
                              height: `${15 * size}px`,
                              left: "100%",
                              userSelect: "none"
                            }}>
                            {
                              field?.background && <img src="/images/transparent.png" draggable={false}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover"
                                }} alt="transparent" />
                            }
                          </div>
                        </div>
                      </Rnd>
                    )
                  ))}
                  {/* Render Logo */}
                  {logoFields?.map((field) => (
                    field.pageNumber === index + 1 && (
                      <Rnd
                        bounds="parent"
                        key={field.id}
                        position={{ x: field.x * size, y: field.y * size }} // Scale position by zoom factor
                        size={{
                          width: field?.initialWidth * size,  // Dynamically adjust width, fallback to 50 if not defined
                          height: field?.initialHeight * size, // Dynamically adjust height, fallback to 50 if not defined
                        }}
                        lockAspectRatio={false} // allow any size not only square
                        dragHandleClassName="handle-logo-drag"
                        onDragStop={(e, data) => {
                          // Update x/y based on zoom level for checkboxes
                          setLogoFields(prevState => prevState.map(cField => {
                            if (cField.id === field.id) {
                              return {
                                ...cField,
                                x: data.x / size,  // Store in unscaled coordinates
                                y: data.y / size,  // Store in unscaled coordinates
                              };
                            }
                            return cField;
                          }));
                        }}
                        enableResizing={{
                          top: false,    // Disable top resize
                          right: false,  // Disable right resize
                          bottom: false, // Disable bottom resize
                          left: false,   // Disable left resize
                          topRight: false,    // Enable top-right corner resize
                          bottomRight: true, // Enable bottom-right corner resize
                          bottomLeft: false,  // Enable bottom-left corner resize
                          topLeft: false,     // Enable top-left corner resize
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                          // Resize checkbox based on Rnd size and maintain square aspect ratio
                          setLogoFields(prevState => prevState.map(cField => {
                            if (cField.id === field.id) {
                              const newWidth = ref.offsetWidth; // Width as per resizing
                              const newHeight = ref.offsetHeight; // Height as per resizing
                              return {
                                ...cField,
                                initialWidth: newWidth / size,  // Store unscaled width
                                initialHeight: newHeight / size,  // Store unscaled height
                                width: newWidth,  // Store scaled width
                                height: newHeight,  // Store scaled height
                              };
                            }
                            return cField;
                          }));
                        }}
                        style={{
                          border: "2px solid skyblue",
                          zIndex: 1000,
                          padding: "0px",
                          margin: "0px"
                        }}
                      >
                        <div
                          className="p-0 m-0 position-relative"
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            background: field.background ? 'rgba(0,0,0,0.1)' : 'transparent',
                          }}
                        >
                          {/* logo image */}
                          <div className="w-100 h-100"
                            style={{
                              backgroundColor: "whitesmoke",
                              cursor: "pointer",
                              userSelect: "none",
                              position: "relative",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: field.initialHeight,
                              width: field.initialWidth
                            }}
                          // onClick={(e) => { handleLogoClick(field.id) }}
                          >
                            <input type="file"
                              className="m-0 p-0"
                              style={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                                opacity: 0
                              }}
                              accept="image/png, image/jpeg, image/jpg"
                              onChange={(e) => {
                                handleLogoClick(field.id, e.target.files[0]);
                              }}
                              alt="logo"
                            />
                            <img src={field.logo} style={{
                              width: field.logo === "/images/logo-upload.svg" ? "50%" :"100%",
                              height: field.logo === "/images/logo-upload.svg" ? "50%" : "100%",
                            }} />
                          </div>
                          {/* Logo controls */}
                          < div className="position-absolute d-flex  align-items-center justify-content-between  p-0" style={{ top: "100%", width: `${50}px`, marginTop: `${5 * size}px` }}>
                            {/* Logo drag button */}
                            <div className="handle-logo-drag border border-1 bg-light" style={{ cursor: "grab" }}>
                              <i className="fa fa-arrows-alt fa-lg m-1 "
                                aria-hidden="true"
                                style={{ fontSize: `${20 * size}px`, cursor: "all-scroll" }}
                                draggable={false}
                              ></i>
                            </div>
                            <i
                              className="fa fa-trash fa-lg text-danger m-1"
                              aria-hidden="true"
                              style={{ fontSize: `${20 * size}px`, cursor: "pointer", }}
                              onClick={(e) => handleLogoDelete(e, field.id)}
                              draggable={false}

                            ></i>

                          </div>
                          {/* Logo transparency button */}
                          < div className="position-absolute d-flex m-1 align-items-center justify-content-between  p-0" style={{ left: "100%", border: "1px solid #3e8eff", marginTop: `${5 * size}px` }}>
                            <i className=" fa fa-copy mx-1 m-2"
                              aria-hidden="true"
                              style={{ fontSize: `${10 * size}px`, cursor: "pointer", color: "#3e8eff" }}
                              onClick={(e) => {
                                const pageElement = pageRefs.current[pageNumber - 1]?.current;
                                if (pageElement) {
                                  const rect = pageElement.getBoundingClientRect();
                                  const offsetX = e.clientX - rect.left;
                                  const offsetY = e.clientY - rect.top;
                                  const xPosition = offsetX / size;  // Store position in unscaled coordinates
                                  const yPosition = offsetY / size;
                                  setLogoFields([
                                    ...logoFields,
                                    {
                                      x: xPosition,
                                      y: yPosition,
                                      pageNumber: pageNumber,
                                      logo: field.logo,
                                      file: field.file ?? {},
                                      id: Date.now(),
                                      size: size,
                                      initialWidth: field.initialWidth,  // Store initial unscaled width
                                      initialHeight: field.initialHeight,
                                    }
                                  ]);
                                }
                              }}
                              draggable={false}
                            ></i>
                          </div>
                        </div>
                      </Rnd>
                    )
                  ))}
                  {/* render signature fields  */}
                  {signatureFields?.map((field) => (
                    field.page_number === index + 1 && (
                      <Rnd
                        bounds="parent"
                        key={field.key}
                        position={{ x: field.coord.x * size, y: field.coord.y * size }} // Scale position by zoom factor
                        size={{
                          width: field.coord.width * size,  // Scale width by zoom factor
                          height: field.coord.height * size  // Scale height by zoom factor
                        }}
                        minHeight={10 * size}
                        minWidth={15 * size}
                        enableResizing={{
                          top: false,    // Disable top resize
                          right: false,  // Disable right resize
                          bottom: false, // Disable bottom resize
                          left: false,   // Disable left resize
                          topRight: false,    // Enable top-right corner resize
                          bottomRight: true, // Enable bottom-right corner resize
                          bottomLeft: false,  // Enable bottom-left corner resize
                          topLeft: false,     // Enable top-left corner resize
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => {
                          // After resizing, store unscaled width and height
                          setSignatureFields(prevState => prevState.map(sField => {
                            if (sField.key === field.key) {
                              return {
                                ...sField,
                                coord: { ...sField.coord, width: ref.offsetWidth / size, height: ref.offsetHeight / size }
                              };
                            }
                            return sField;
                          }));
                        }}
                        onDragStop={(e, data) => {
                          // Update x/y based on zoom level
                          setSignatureFields(prevState => prevState.map(sField => {
                            if (sField.key === field.key) {
                              return {
                                ...sField,
                                coord: { ...sField.coord, x: data.x / size, y: data.y / size }
                              };
                            }
                            return sField;
                          }));
                        }}
                        style={{
                          outline: "none",
                          border: "1px solid black",
                          zIndex: 3,
                        }}
                      >
                        <div
                          className="border border-0"
                          style={{
                            position: 'absolute',
                            top: '0px',
                            left: '0px',
                            width: '100%',
                            height: '100%',
                          }}
                        >
                          <input
                            style={{
                              fontSize: "15px",
                              color: 'black',
                              verticalAlign: "middle",
                              textWrap: "nowrap",
                              overflow: "hidden",
                              cursor: 'move',
                              width: '100%',
                              height: '100%',
                              padding: '5px',
                              outline: 'none',
                              borderRadius: '2px',
                              backgroundColor: field.bg_color,
                              border: '1px solid black'
                            }}
                            id={field.key}
                            onClick={(event) => event.stopPropagation()}
                            className="p-1 border border-0 outline-none"
                            value={field.type === "PDFSignature" ? "Signature" : (field.type === "PDFInitial" ? "XX" : "Date")}
                            readOnly
                          />
                        </div>
                        <div
                          className="position-absolute p-1"
                          style={{
                            left: "100%"
                          }}>
                          <i
                            className="fa fa-trash fa-lg text-danger"
                            aria-hidden="true"
                            style={{ fontSize: `${25 * size}px`, cursor: "pointer" }}
                            onClick={(e) => handleSignatureDelete(e, field.key)}
                            draggable={false}
                          ></i>
                        </div>
                      </Rnd>
                    )
                  ))}
                </Page>
              </div>
            ))}
          </div>
        </Document>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default PdfRender;
