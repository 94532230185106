import { createTheme, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setLoadingStatus } from "../../redux/actions/loading";
import EDocService from "../../services/EDocService";
import TransactionService from "../../services/TransactionService";
import Auth from "../../utils/auth";
import PDfEditorContainer from "./PDFContainer";
import "./index.css";
import { uploadPdf } from "../../utils/s3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormDataSideBarListing from '../listing_agreement/View/layouts/FormDataSideBar';
import FormDataSideBarDeal from '../deals/View/layouts/FormDataSideBar';
import {
  faCloud,
  faDownload,
  faReply,
  faRely,

} from "@fortawesome/free-solid-svg-icons";

import { v4 as uuidv4 } from "uuid";
import { Buffer } from "buffer";
import { PDFDocument, PDFName, createPDFAcroFields, setFontAndSize, rgb } from "pdf-lib";
import { getPdfPageCountFromUrl } from "../../utils/functions";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  pdf_box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    position: "relative",
    height: "700px",
    overflow: "scroll",
  },
});

// style for section of add signature/initials/date 
const style = {
  border: '1px solid gray',
  backgroundColor: 'white',
  padding: '3px 10px',
  color: '#212529 !important',
  marginBottom: '0.5rem',
  cursor: 'move',
  float: 'left',
  width: '100%',
  opacity: 'transparent'
};

// theme for section of add signature/initials/date 
const theme = createTheme({
  components: {
    // Name of the component
    MuiSelect: {
      styleOverrides: {
        // Name of the slot
        select: {
          // Some CSS
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
      },
    },
  },
});


const EditCustomEditor = (props) => {
  const formData = useRef([]);
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const propData = props.location.state;

  const [editorPdf, setEditorPdf] = useState(propData.file_url);
  const [timeStamp, setTimeStamp] = useState(new Date().getTime() + Math.random(10));
  const [refresh, setRefresh] = useState(false)
  // for merge pdf
  const [mergedPdfUrl, setMergedPdfUrl] = useState(propData?.is_pdf_merge ? propData?.file_url : null);

  // state for manage files for merge
  const [files, setFiles] = useState([]);

  // state for manage confirmation of merge and handle files dependancy
  const [isConfirmForMerge, setIsConfirmForMerge] = useState(false);

  // state to manage manually added text fields
  const [textFields, setTextFields] = useState([]);

  // state to manage manually added checkbox fields
  const [checkboxFields, setCheckboxFields] = useState([]);
  const [logoFields, setLogoFields] = useState([]);

  // state to manage manually added signature/initials/date fields
  const [signatureFields, setSignatureFields] = useState([]);

  // state to manage assigner of signature/initial/date
  const [signatureAssigner, setSignatureAssigner] = useState("");

  // state to manage roles for manually adding signature/initials/date
  const [signatureRoles, setSignatureRoles] = useState([]);

  // for main template page count
  const [templatePageCount, setTemplatePageCount] = useState(null);

  // by this useEffect it will get page count of main template Doc
  useEffect(() => {
    // if (propData?.haveEditVersions) {
    setTemplatePages();
    // }
  }, [propData?.templateLink]);

  // by this useEffect it will get roles of main template Doc and set for add signature/initials/date
  useEffect(() => {
    if (propData?.roles) {
      setSignatureRoles(() => {
        let allRoles = [{ role: "Seller", bgColor: "#8ae2f4" },
        { role: "Owner", bgColor: "#8ae2f4" },
        { role: "Co-Seller", bgColor: "#f59600" },
        { role: "Co-Owner", bgColor: "#f59600" },
        { role: "Buyer", bgColor: "#74dd90" },
        { role: "Co-Buyer", bgColor: "#0063a6" }
        ]
        setSignatureAssigner(allRoles.filter(element => propData?.roles.includes(element.role))[0]?.role);
        return (allRoles.filter(element => propData?.roles.includes(element.role)));
      });
    }
  }, [propData?.roles]);

  // fill manually added signature/initials/date fields 
  useEffect(() => {
    if (propData?.updated_elements) {
      setSignatureFields((prev) => [...prev, ...propData?.updated_elements.map((element) => {
        let bgColor = element?.assigner ? getSignatureBgColor(element?.assigner) : "#ffffff";
        return {
          ...element,
          bg_color: bgColor,
          page_number: element.page_number + 1
        }
      })]);
    }
  }, [propData?.updated_elements]);

  // function to get role wise background color for signature/initials/date
  const getSignatureBgColor = (signatureAssigner) => {
    switch (signatureAssigner) {
      case 'Seller':
        return "#8ae2f4";
      case 'Owner':
        return "#8ae2f4";
      case 'Co-Seller':
        return "#f59600";
      case 'Co-Owner':
        return "#f59600";
      case 'Buyer':
        return "#74dd90";
      case 'Co-Buyer':
        return "#0063a6";
      default:
        return "#ffffff";
    }
  }

  // function for set template pages with wait
  const setTemplatePages = async () => {
    setTemplatePageCount(await getPdfPageCountFromUrl(propData?.templateLink));
  }

  const logged_user = Auth.getInstance().getUserData();

  const onPreviousPage = () => {
    history.push(`/${propData.type}/view/${propData.transactionId}/main`);
  };

  /**
   * Saves the updated pdf version in database with confirmation
   * @param {*} documentData
   */
  const onSaveDocumentVersion = async (documentData) => {
    //Open a Warning pop-up
    Swal.fire({
      title: 'Attention!',
      html: `Saving any “PDF Edits” to this page will disconnect all future Form Data changes from entering into this PDF! <br/>  <br/>  <br/>All future updates to this document must be made from here - the PDF Editor ONLY!`,
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      confirmButtonText: 'I UNDERSTAND',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setLoadingStatus(true));
        try {
          // EDocService.updateEditedDocumentPdf(apiBody).then(
          //     response => {
          //         dispatch(setLoadingStatus(false));
          //         toast.success('Your PDF updated successfully.')
          //         onPreviousPage();
          //     }
          // ).catch(
          //     error => {
          //         dispatch(setLoadingStatus(false));
          //         toast.error('Cannot update PDF right now!');
          //         console.log(error)
          //     });
          // alert("sdfbjhurdeb jfge");

          uploadPdf(documentData, "pdf-documents", propData.uniqueId, propData.companyUId)
            .then((response) => {

              const apiBody = {
                file_name: response,
                transaction_edocument_id: propData.documentId,
                is_current: true,
                transaction_id: propData.transactionId,
                activity_title: propData.documentName,
                activity_task_type: `pdf_edited`,
                activity_action_by: logged_user.id,
                uniqueId: propData.uniqueId,
                companyUId: propData.companyUId,
                is_elements_edit: signatureFields.length > 0 ? true : false,
                updated_elements: [...signatureFields?.map((element) => {
                  let payloadObj = { ...element } // Make a copy of the original object
                  delete payloadObj?.bg_color;      // Delete 'bg_color' from the new object
                  return { ...payloadObj, page_number: (element?.page_number - 1) };
                })],
                is_pdf_merge: propData?.is_pdf_merge ? true : (mergedPdfUrl ? true : false)
              };
              EDocService.updateEditedDocumentPdf(apiBody).then((response) => {
                dispatch(setLoadingStatus(false));
                toast.success("Your PDF updated successfully.");
                onPreviousPage();
              });
              dispatch(setLoadingStatus(false));
            })
            .catch((error) => {
              dispatch(setLoadingStatus(false));
              toast.error("Cannot update PDF right now!");
              console.log(error);
            });
        } catch (error) {
          dispatch(setLoadingStatus(false));
          console.log(error);
        }
      }
    });
  };

  const onMergeDocument = async (mergedPdfUrl) => {
    //Open a Warning pop-up
    Swal.fire({
      title: 'Attention!',
      html: `Please complete any "PDF Edits" before adding additional file as no further changes will be allowed after merging files !`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "red",
      cancelButtonText: "Go Back",
      confirmButtonColor: "#3085d6",
      confirmButtonText: 'Continue',
    }).then((result) => {
      if (result.isConfirmed) {
        setMergedPdfUrl(mergedPdfUrl);
      } else {
        setIsConfirmForMerge(true); // do true when confirmation declines
        setFiles((prev) => [...prev.slice(0, -1)]); // pop last file in don't merge
      }
    }).catch((err) => console.log(err));
  };

  // function for set new edited pdf url to render with deleted page
  const onDeleteDocumentPage = async (editedPdfUrl, pageNumberToDelete, isMainDoc = false) => {
    //Open a Warning pop-up
    Swal.fire({
      title: 'Attention!',
      html: `This will permanently delete the selected page.<br>Are you sure?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      cancelButtonText: "Cancel",
      confirmButtonColor: "red",
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        if (!isMainDoc) {
          setMergedPdfUrl(editedPdfUrl);

          // set fields for deleted page
          setTextFields((prev) => {
            let settledTextFields = [...prev.filter((tField) => tField?.pageNumber !== pageNumberToDelete)].map((elem) => {
              if (elem?.pageNumber > pageNumberToDelete) {
                return { ...elem, pageNumber: elem.pageNumber - 1 };
              }
              return elem;
            })
            return settledTextFields;
          });

          setCheckboxFields((prev) => {
            let settledCheckFields = [...prev.filter((cField) => cField?.pageNumber !== pageNumberToDelete)].map((elem) => {
              if (elem?.pageNumber > pageNumberToDelete) {
                return { ...elem, pageNumber: elem.pageNumber - 1 };
              }
              return elem;
            })
            return settledCheckFields;
          });

          setLogoFields((prev) => {
            let settledLogoFields = [...prev.filter((logoField) => logoField?.pageNumber !== pageNumberToDelete)].map((elem) => {
              if (elem?.pageNumber > pageNumberToDelete) {
                return { ...elem, pageNumber: elem.pageNumber - 1 };
              }
              return elem;
            })
            return settledLogoFields;
          });

          setSignatureFields((prev) => {
            let settledSignatureFields = [...prev.filter((signField) => signField?.page_number !== pageNumberToDelete)].map((elem) => {
              if (elem?.page_number > pageNumberToDelete) {
                return { ...elem, page_number: elem.page_number - 1 };
              }
              return elem;
            })
            return settledSignatureFields;
          });
        } else {
          getPdfPageCountFromUrl(editorPdf).then((docPageCount) => {
            // if document is main in doc then only it will render and editable
            ((templatePageCount === docPageCount) && (!(propData?.is_pdf_merge))) ? setMergedPdfUrl(null) : setMergedPdfUrl(editedPdfUrl);
          }
          );
        }
      }
    }).catch((err) => console.log(err));
  };

  /**
   * Call Reset pdf version API and reload pdf in editor with response pdf link
   */
  const onResetPdfVersion = () => {
    Swal.fire({
      title: "Are you sure!",
      text: "Resetting a PDF will remove your updated data from it.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(setLoadingStatus(true));
        try {
          TransactionService.resetPdfEditorVersion({
            id: propData.documentId,
            transaction_id: propData.transactionId,
            activity_title: propData.documentName,
            activity_task_type: `pdf_reset`,
            activity_action_by: logged_user.id,
            activity_document_link: editorPdf
              ? editorPdf.split("/")[editorPdf.split("/").length - 1]
              : null,
          })
            .then((response) => {
              if (response.status == 200) {
                TransactionService.getPreview(
                  propData.documentName,
                  propData.transactionId,
                  "preview"
                )
                  .then((response) => {
                    dispatch(setLoadingStatus(false));
                    if (response.data && response.data.url) {
                      onPreviousPage();

                    }
                  })
                  .catch((error) => {
                    toast.error("Error while reset PDF!");
                    dispatch(setLoadingStatus(false));
                    console.log(error);
                  });
                toast.success("PDF reset successfully.");
              } else {
                toast.error("Something went wrong!");
              }
            })
            .catch((error) => {
              dispatch(setLoadingStatus(false));
              toast.error("Cannot reset PDF!");
              console.log(error);
            });
        } catch (error) {
          dispatch(setLoadingStatus(false));
          console.log(error);
        }
      }
    });
  };

  /*
   * call refresh
   */
  // const onRefresh = () => {
  //   // formData.current = [];
  //   // dispatch(setLoadingStatus(true)).then((ews)=>{dispatch(setLoadingStatus(false))})

  //   Swal.fire({
  //     title: "Are you sure!",
  //     text: "Refresh all updated data.",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes",
  //     cancelButtonText: "No",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       formData.current = [];
  //       setRefresh(prev => !prev)
  //     }
  //   });
  // };
  //Download pdf :
  const urlValue = editorPdf;
  const downloadPDF = async () => {
    // Fetch the PDF file using the URL provided in 'urlValue'
    let existingPdfBytes = await fetch(urlValue).then((res) =>
      res.arrayBuffer()
    );

    if (mergedPdfUrl) {
      existingPdfBytes = await fetch(mergedPdfUrl).then((res) =>
        res.arrayBuffer()
      );
    }
    // Load the PDF document from the fetched bytes
    let pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Access the form in the PDF
    const form = pdfDoc.getForm();

    // Get all form fields in the PDF
    let fields = form.getFields();

    // Iterate over the form fields and update them with data from 'formData'
    await Promise.all(
      fields.map(async (field) => {
        const key = field.getName();
        const updateData = formData.current.find(
          (elem) => elem.fieldName === key
        );

        if (updateData) {
          switch (updateData.fieldType) {
            case "text":
              // Update a text field
              field.setText(updateData.fieldValue.toString());
              break;
            case "checkbox":
              // Update a checkbox field
              const kids = createPDFAcroFields(field.acroField.Kids()).map((_) => _[0]);
              kids.forEach((kid) => {
                if (updateData.fieldValue === true) {
                  field.check(); // Check the checkbox
                  kid.setValue(kid.getOnValue()); // Check that particular checkbox.
                } else {
                  field.uncheck(); // Check the checkbox
                  kid.setValue(PDFName.of("Off")); // Uncheck the checkbox
                }
              });
              break;
            default:
              break;
          }
        }
      })
    );

    // Get page and page height
    const page = await pdfDoc.getPage(0); // Get the first page
    const pageHeight = page.getHeight();

    // Add custom text fields
    textFields.forEach((field) => {
      const textField = form.createTextField(`textbox_${field.id}`);
      textField.setText(field.text);
      textField.enableMultiline() // show multiline text
      // Set font size and font in the DA (default appearance) string
      const da = textField.acroField.getDefaultAppearance() ?? '';
      const newDa = da + '\n' + setFontAndSize('Courier', Number(field?.fontSize)).toString(); //setFontAndSize() method came to resuce
      textField.acroField.setDefaultAppearance(newDa);

      // Conditional background color based on field?.backgroundTransparent
      const backgroundColor = field?.background ? undefined : rgb(1, 1, 1);  // Transparent if true, red if false

      // Apply scaling to Y position and X position for text fields
      const adjustedY = pageHeight - (field.y) - (field.initialHeight);  // Adjust Y position based on scaling
      textField.addToPage(pdfDoc.getPage(field.pageNumber - 1 ?? 0), {
        x: field.x,  // Adjust X position based on scaling
        y: adjustedY,
        backgroundColor: backgroundColor,
        borderWidth: 0,
        borderColor: undefined,
        width: field?.initialWidth,  // Scale width by zoom factor
        height: field?.initialHeight,
        initialWidth: field?.initialWidth,
        initialHeight: field?.initialHeight
      });
    });
    // ----custom checbox images
    await Promise.all(checkboxFields.map(async (field) => {
      const page = pdfDoc.getPage(field.pageNumber - 1); // Get the corresponding page
      const adjustedY = pageHeight - field.y - (field?.initialHeight ?? 50); // Adjust Y position

      // Determine the icon URL based on the checked status of the checkbox
      let iconUrl = field.checked === 0
        ? field?.background ? '/images/check-box-solid-tick.png' : '/images/check-box-tick.png'  // Checked icon
        : field?.background ? '/images/check-box-solid-cross.png' : '/images/check-box-cross.png'; // Unchecked icon
      if (field.checked === 2) {
        if (field?.background) {
          iconUrl = "/images/check-box-solid-white.png"
        } else {
          iconUrl = ""
        }
      }

      if (iconUrl) {
        // Fetch the icon image as a byte array
        const iconBytes = await fetch(iconUrl).then(res => res.arrayBuffer());

        // Embed the image in the PDF using pdf-lib's embedPng
        const iconImage = await pdfDoc.embedPng(iconBytes);

        // Draw the custom icon (image) at the checkbox position on the page
        page.drawImage(iconImage, {
          x: field.x,  // Horizontal position
          y: adjustedY,  // Vertical position (adjusted with page height)
          width: field?.initialWidth ?? 50,  // Width of the image
          height: field?.initialHeight ?? 50,  // Height of the image
        });
      }
    }));
    // ----custom logo images
    await Promise.all(logoFields.map(async (field) => {
      const page = pdfDoc.getPage(field.pageNumber - 1); // Get the corresponding page
      const adjustedY = pageHeight - field.y - (field?.initialHeight ?? 50); // Adjust Y position
      if (field.logo && field.file.type) {
        // Fetch the icon image as a byte array
        const iconBytes = await fetch(field.logo).then(res => res.arrayBuffer());
        // Embed the image in the PDF using pdf-lib's embedPng
        const iconImage = field.file.type == "image/png" ?
          await pdfDoc.embedPng(iconBytes) : await pdfDoc.embedJpg(iconBytes)
        // Conditional background color based on field?.backgroundTransparent
        const backgroundColor = field?.background ? undefined : rgb(1, 1, 1);
        // Draw the custom icon (image) at the checkbox position on the page
        page.drawImage(iconImage, {
          x: field.x,  // Horizontal position
          y: adjustedY,  // Vertical position (adjusted with page height),
          backgroundColor: backgroundColor,
          width: field?.initialWidth ?? 50,  // Width of the image
          height: field?.initialHeight ?? 50,  // Height of the image
          opacity: 1
        });
      }
    }));
    // Save the modified PDF as base64 data
    const pdfBytes = await pdfDoc.saveAsBase64({ dataUri: true });

    // Generate a unique PDF name (UUID)
    const pdfName = uuidv4();

    // Create a download link for the PDF
    const downloadLink = document.createElement("a");
    const fileName = `${pdfName}.pdf`;
    downloadLink.href = pdfBytes;
    downloadLink.download = fileName;
    downloadLink.click();
  };


  const savePDF = async () => {
    dispatch(setLoadingStatus(true));
    // Fetch the PDF file using the URL provided in 'urlValue'
    let existingPdfBytes = await fetch(urlValue).then((res) =>
      res.arrayBuffer()
    );

    if (mergedPdfUrl) {
      existingPdfBytes = await fetch(mergedPdfUrl).then((res) =>
        res.arrayBuffer()
      );
    }
    // Load the PDF document from the fetched bytes
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Access the form in the PDF
    const form = pdfDoc.getForm();

    // Get all form fields in the PDF
    let fields = form.getFields();

    // Iterate over the form fields and update them with data from 'formData'
    await Promise.all(
      fields.map(async (field) => {
        const key = field.getName();
        const updateData = formData.current.find(
          (elem) => elem.fieldName === key
        );

        if (updateData) {
          switch (updateData.fieldType) {
            case "text":
              // Update a text field
              field.setText(updateData.fieldValue.toString());
              break;
            case "checkbox":
              // Update a checkbox field
              const kids = createPDFAcroFields(field.acroField.Kids()).map(
                (_) => _[0]
              );
              kids.forEach((kid) => {
                if (updateData.fieldValue === true) {
                  field.check(); // Check the checkbox
                  kid.setValue(kid.getOnValue()); // Check that particular checkbox.
                } else {
                  field.uncheck(); // Check the checkbox
                  kid.setValue(PDFName.of("Off")); // Uncheck the checkbox
                }
              });
              break;

            default:
              break;
          }
        }
      })
    );

    // for custom text boxes
    const page = await pdfDoc.getPage(0); // Get the first page
    const pageHeight = page.getHeight();
    // Add custom text fields
    textFields.forEach((field) => {
      const textField = form.createTextField(`textbox_${field.id}`);
      textField.setText(field.text);
      textField.enableMultiline() // show multiline text
      // Set font size and font in the DA (default appearance) string
      const da = textField.acroField.getDefaultAppearance() ?? '';
      const newDa = da + '\n' + setFontAndSize('Courier', Number(field?.fontSize)).toString(); //setFontAndSize() method came to resuce
      textField.acroField.setDefaultAppearance(newDa);

      // Conditional background color based on field?.backgroundTransparent
      const backgroundColor = field?.background ? undefined : rgb(1, 1, 1);  // Transparent if true

      // Apply scaling to Y position and X position for text fields
      const adjustedY = pageHeight - (field.y) - (field.initialHeight);  // Adjust Y position based on scaling
      textField.addToPage(pdfDoc.getPage(field.pageNumber - 1 ?? 0), {
        x: field.x,  // Adjust X position based on scaling
        y: adjustedY,
        backgroundColor: backgroundColor,
        borderWidth: 0,
        borderColor: undefined,
        width: field?.initialWidth,  // Scale width by zoom factor
        height: field?.initialHeight,
        initialWidth: field?.initialWidth,
        initialHeight: field?.initialHeight
      });
    });

    // ----custom checbox images
    await Promise.all(checkboxFields.map(async (field) => {
      const page = pdfDoc.getPage(field.pageNumber - 1); // Get the corresponding page
      const adjustedY = pageHeight - field.y - (field?.initialHeight ?? 50); // Adjust Y position

      // Determine the icon URL based on the checked status of the checkbox
      let iconUrl = field.checked === 0
        ? field?.background ? '/images/check-box-solid-tick.png' : '/images/check-box-tick.png'  // Checked icon
        : field?.background ? '/images/check-box-solid-cross.png' : '/images/check-box-cross.png'; // Unchecked icon
      if (field.checked === 2) {
        if (field?.background) {
          iconUrl = "/images/check-box-solid-white.png"
        } else {
          iconUrl = ""
        }
      }

      if (iconUrl) {
        // Fetch the icon image as a byte array
        const iconBytes = await fetch(iconUrl).then(res => res.arrayBuffer());

        // Embed the image in the PDF using pdf-lib's embedPng
        const iconImage = await pdfDoc.embedPng(iconBytes);

        // Draw the custom icon (image) at the checkbox position on the page
        page.drawImage(iconImage, {
          x: field.x,  // Horizontal position
          y: adjustedY,  // Vertical position (adjusted with page height)
          width: field?.initialWidth ?? 50,  // Width of the image
          height: field?.initialHeight ?? 50,  // Height of the image
        });
      }
    }));

    await Promise.all(logoFields.map(async (field) => {
      const page = pdfDoc.getPage(field.pageNumber - 1); // Get the corresponding page
      const adjustedY = pageHeight - field.y - (field?.initialHeight ?? 50); // Adjust Y position

      // Determine the icon URL based on the checked status of the checkbox
      let iconUrl = field.checked === 0
        ? field?.background ? '/images/check-box-solid-tick.png' : '/images/check-box-tick.png'  // Checked icon
        : field?.background ? '/images/check-box-solid-cross.png' : '/images/check-box-cross.png'; // Unchecked icon
      if (field.checked === 2) {
        if (field?.background) {
          iconUrl = "/images/check-box-solid-white.png"
        } else {
          iconUrl = ""
        }
      }
      if (field.logo && field.file.type) {
        // Fetch the icon image as a byte array
        const iconBytes = await fetch(field.logo).then(res => res.arrayBuffer());
        // Embed the image in the PDF using pdf-lib's embedPng
        const iconImage = field.file.type == "image/png" ?
          await pdfDoc.embedPng(iconBytes) : await pdfDoc.embedJpg(iconBytes)
        // Conditional background color based on field?.backgroundTransparent
        const backgroundColor = field?.background ? undefined : rgb(1, 1, 1);
        // Draw the custom icon (image) at the checkbox position on the page
        page.drawImage(iconImage, {
          x: field.x,  // Horizontal position
          y: adjustedY,  // Vertical position (adjusted with page height),
          backgroundColor: backgroundColor,
          width: field?.initialWidth ?? 50,  // Width of the image
          height: field?.initialHeight ?? 50,  // Height of the image
          opacity: 1
        });
      }
    }));
    // Save the modified PDF as base64 data
    const pdfBytes = await pdfDoc.saveAsBase64({ dataUri: true });

    // Convert the base64 PDF data to a Buffer
    const base64Data = Buffer.from(
      pdfBytes.replace(/^data:application\/pdf;base64,/, ""), // Assuming pdfBytes is a data URL for a PDF
      "base64"
    );

    onSaveDocumentVersion(base64Data);
    dispatch(setLoadingStatus(false));
  };

  // function for get updated pdf before merge with other pdf
  const getEditedPdfUrl = async (urlValue) => {
    // Fetch the PDF file using the URL provided in 'urlValue'
    let editedPdfBytes = await fetch(urlValue).then((res) =>
      res.arrayBuffer()
    );

    // Load the PDF document from the fetched bytes
    let pdfDoc = await PDFDocument.load(editedPdfBytes);

    // Access the form in the PDF
    const form = pdfDoc.getForm();

    // Get all form fields in the PDF
    let fields = form.getFields();

    // Iterate over the form fields and update them with data from 'formData'
    await Promise.all(
      fields.map(async (field) => {
        const key = field.getName();
        const updateData = formData.current.find(
          (elem) => elem.fieldName === key
        );

        if (updateData) {
          switch (updateData.fieldType) {
            case "text":
              // Update a text field
              field.setText(updateData.fieldValue.toString());
              break;
            case "checkbox":
              // Update a checkbox field
              const kids = createPDFAcroFields(field.acroField.Kids()).map((_) => _[0]);
              kids.forEach((kid) => {
                if (updateData.fieldValue === true) {
                  field.check(); // Check the checkbox
                  kid.setValue(kid.getOnValue()); // Check that particular checkbox.
                } else {
                  field.uncheck(); // Check the checkbox
                  kid.setValue(PDFName.of("Off")); // Uncheck the checkbox
                }
              });
              break;

            default:
              break;
          }
        }
      })
    );

    // Save the modified PDF as base64 data
    const pdfBytes = await pdfDoc.saveAsBase64({ dataUri: true });

    // Removes 'data:application/pdf;base64,' if included
    const base64String = pdfBytes.split(',')[1];

    // Decode the Base64 string
    const byteCharacters = atob(base64String);
    const byteNumbers = Array.from(byteCharacters).map(char => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob from the byte array
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Generate an object URL from the Blob
    const pdfUrl = URL.createObjectURL(blob);

    return pdfUrl;

  }

  const refreshContent = async (isReload) => {
    if (isReload && !propData?.haveEditVersions) {
      dispatch(setLoadingStatus(true));
      let id = propData.transactionId;
      const dataTransaction = (await TransactionService.getTransactionEsignById(id)).data;
      switch (propData.documentName) {
        case "Listing Agreement Exclusion":
        case "Listing Agreement Addendum":
          id = propData.documentId;
          break;
        case "Purchase & Sale Agreement Exclusion":
        case "Purchase & Sale Agreement Addendum":
        case "IYBA Acceptance of Vessel":
          id = `${propData.documentId}`;
          break;
        case "Seller Closing Statement":
          const dataSellerClosing = dataTransaction.t_seller_closing ? dataTransaction.t_seller_closing : null;
          id = dataSellerClosing?.id || id;
          break;
        case "Buyer Closing Statement":
          const dataBuyerClosing = dataTransaction.t_buyer_closing ? dataTransaction.t_buyer_closing : null;
          id = dataBuyerClosing?.id || id;
          break;
        case "Trade-In Vessel":
          const dataTradeInVessel = dataTransaction?.t_trade_in_vessel ? dataTransaction?.t_trade_in_vessel : null;
          id = dataTradeInVessel?.id || id;
          break;
        case "CYBA Counter Offer":
        case "Bill of Sale - Notary":
        case "Bill of Sale - Notary (Seller Only)":
        case "YBAA Amendment to PSA":
        case "YBAA Amendment to PSA (Short Form)":
        case "Counter Offer":
        case "NYBA Counter Offer":
          id = `${propData.transactionId}/${propData.documentId}`;
          break;
        default:
          id = propData.transactionId;
      }
      TransactionService.getPreview(propData.documentName, id, { module: "preview" }).then(
        (response) => {

          // setEditorPdf(() => '');
          const updatedLink = response.data.url;
          setTimeStamp(new Date().getTime());
          setEditorPdf(`${updatedLink}?tm=${timeStamp}`);
          dispatch(setLoadingStatus(false));
          setRefresh((prev) => !prev)
        }).catch((error) => {
          dispatch(setLoadingStatus(false));
        });
    }
  }
  return (
    <div className="create-container">
      <div className=" rsw-wrapper">
        {propData.type == 'listing-agreement' &&
          <FormDataSideBarListing
            transactionId={propData.transactionId}
            sliderFor='editor'
            reloadPdf={refreshContent}
            isPdfEdit={true}
            signatureAssigner={signatureAssigner}
            setSignatureAssigner={setSignatureAssigner}
            style={style}
            theme={theme}
            signatureRoles={signatureRoles}
          />
        }
        {propData.type == 'deals' &&
          <FormDataSideBarDeal
            transactionId={propData.transactionId}
            sliderFor='editor'
            reloadPdf={refreshContent}
            isPdfEdit={true}
            signatureAssigner={signatureAssigner}
            setSignatureAssigner={setSignatureAssigner}
            style={style}
            theme={theme}
            signatureRoles={signatureRoles}
          />
        }
        <div>
          <div className="nav-bar">
            <div style={{ position: "absolute", left: "30px" }}>
              <button className="btn btn-continue" onClick={onPreviousPage}>
                Back
              </button>
            </div>

            {/* if pdf is edited before then reset button will appear */}
            {(propData.haveEditVersions) &&
              <div style={{ position: "absolute", right: "30px" }}>
                <button className="btn btn-reset " onClick={onResetPdfVersion}>
                  Reset
                </button>
              </div>
            }
          </div>

          <div className={`${classes.container} `}>
            <Grid container>
              <Grid item xs={12}>
                <Box className={`${classes.pdf_box}`}>
                  {/* savePDF is function which will execute on submitdoc button click */}
                  {/* downloadPDF is function which will execute on download button click */}

                  <PDfEditorContainer
                    doc={editorPdf}
                    formData={formData}
                    onSubmitUpdatedPDF={onSaveDocumentVersion}
                    savePDF={savePDF}
                    downloadPDF={downloadPDF}
                    mergeProps={{ mergedPdfUrl, setMergedPdfUrl, files, setFiles, isConfirmForMerge, setIsConfirmForMerge }}
                    getEditedPdfUrl={getEditedPdfUrl}
                    templatePageCount={templatePageCount}
                    textFields={textFields}
                    setTextFields={setTextFields}
                    checkboxFields={checkboxFields}
                    setCheckboxFields={setCheckboxFields}
                    logoFields={logoFields}
                    setLogoFields={setLogoFields}
                    signatureFields={signatureFields}
                    setSignatureFields={setSignatureFields}
                    signatureAssigner={signatureAssigner}
                    getSignatureBgColor={getSignatureBgColor}
                    onSaveDocumentVersion={onSaveDocumentVersion}
                    onMergeDocument={onMergeDocument}
                    onDeleteDocumentPage={onDeleteDocumentPage}
                  />
                </Box>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCustomEditor;
