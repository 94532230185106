import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createOffice, updateOffice } from "../../redux/actions/offices";
import { Link, useHistory } from "react-router-dom";
import moment from 'moment';

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import clsx from "clsx";

import OfficeService from "../../services/OfficeService";
import TimezoneList from "./TImezone";
import Country from "../../constants/Country";
import StatesForUSA from "../../constants/StatesForUSA";
// import { phoneFigure, phoneNumber } from "../../utils/phonenumber";
import { setLoadingStatus } from "../../redux/actions/loading";
import { findCompanyByName } from "../../redux/actions/companies";
import Auth from "../../utils/auth";
import { removeOfficeUsers, retrieveBrokers, retrieveCompanyUsers, retrieveOfficeUsers } from "../../redux/actions/users";
import BrokerageService from "../../services/BrokerageService";
import FormRow from "../common/FormRow";
import FormInputWrapper from "../common/FormInputWapper";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { makeStyles } from '@mui/styles';
import "./Form.css"
import { DatePicker } from "antd";
import dayjs from "dayjs";

const schema = yup.object().shape({
  office_name: yup.string().required(),
});

const useStyles = makeStyles({
  phone_input: {
    padding: '12px 12px 12px 50px !important',
    width: '100% !important'
  },
});


const OfficeForm = (props) => {
  const mlsApiFields = {
    "address_1": "AddressLineOne",
    "address_2": "AddressLineTwo",
    "address_3": "AddressLineThree",
    "city": "City",
    "state": "State",
    "postal_code": "Zip",
    "country": "Country",
    "office_name": "OfficeName",
    "office_phone": "Phone"

  }
  const office_id = props.location.state ? props.location.state.id : null;
  const [showMlsOfficeId, setShowMlsOfficeId] = useState(false);
  const [mlsOffices, setMlsOffices] = useState(null);
  const isAddMode = !office_id;

  const classes = useStyles();

  const officeUsers = useSelector((state) => state.users.officeUsers);
  officeUsers.sort((a, b) => a.last_name < b.last_name ? -1 : 1)

  const [brokerProfile, setBrokerProfile] = useState(null);
  const logged_user = Auth.getInstance().getUserData();

  const history = useHistory();
  const dataCompanies = useSelector((state) => state.companies.companies || []);
  const loggedUser = Auth.getInstance().getUserData();
  const isAdmin = loggedUser && loggedUser.UserInRole.UserRole.name === 'Boatdox Admin';

  /* Define State Variables */
  const [isSelectedUSA, setSelectedUSA] = useState(true);
  const [isFinishSubmit, setFinishSubmit] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(findCompanyByName(""));
  }, [])

  /* Form Validation */
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });

  const fetchMlsOffices = async (company_id) => {
    try {
      const { data } = await OfficeService.retrieveMlsOffices(company_id);
      if (data.credentialsFound) {
        setShowMlsOfficeId(true);
        setMlsOffices(data.data.Offices ? data.data.Offices : []);
      } else {
        setMlsOffices(null);
        setShowMlsOfficeId(false);
      }
    } catch (error) {
      setMlsOffices(null);
      setShowMlsOfficeId(false);
    }
  }
  /* If isAddMode=false, get office by id */
  useEffect(() => {

    if (!isAddMode) {
      dispatch(setLoadingStatus(true));
      OfficeService.get(office_id).then((office) => {

        if (office.data?.company_id) {
          fetchMlsOffices(office.data?.company_id);
          if (!mlsOffices || mlsOffices.length == 0) {
            setValue(
              'mls_office_id',
              null
            )
          }
        }

        fetchBrokerData(logged_user?.company_id);
        const fields = [
          "office_name",
          "address_1",
          "address_2",
          "address_3",
          "employing_broker_id",
          "mls_office_id",
          "city",
          "state",
          "postal_code",
          "sales_tax_id",
          "office_phone",
          "timezone",
          "country",
        ];
        fields.forEach((field) => {
          if (field.slice(-4) === 'date') {
            setValue(
              field,
              office.data[field] ? moment(office.data[field]).utc().format("yyyy-MM-DD") : null
            );
          } else if (field == "mls_office_id") {
            setValue(
              field,
              office.data[field] ? office.data[field] : null
            )
          } else {
            setValue(field, office.data[field])
          }
        });

        setBrokerProfile(office?.data["employing_broker_id"] || null)

        office.data.country === "USA" ? setSelectedUSA(true) : setSelectedUSA(false);
        if (isAdmin) { setValue('company_id', office.data['company_id']) }
        dispatch(setLoadingStatus(false));
      }).catch(err => {
        console.log(err);
        dispatch(setLoadingStatus(false));
      });
    } else {
      getCompanyBroker(logged_user?.company_id);
      fetchMlsOffices(logged_user?.company_id);
      dispatch(removeOfficeUsers());
      setValue("country", "");
      setSelectedUSA(true);
      setValue("timezone", "US/Eastern");
    }
  }, []);

  useEffect(() => {
    if (getValues("employing_broker_id")) {
      getBrokerProfileData(getValues("employing_broker_id"))
    }
  }, [watch("employing_broker_id")])

  // useEffect(() => {

  //   setBrokerProfile(null);
  //   officeUsers.map((broker) => {
  //     if (broker.is_office_manager) {
  //       setValue("employing_broker_id", broker.id);
  //       getBrokerProfileData(broker.id)
  //     }
  //   })
  // }, [officeUsers])


  const fetchBrokerData = (company_id) => {
    setBrokerProfile(null);
    if (office_id) {
      dispatch(retrieveOfficeUsers(office_id, company_id));
    } else {
      // setValue("main_office_name", "");
      dispatch(removeOfficeUsers());
    }
  }

  const getCompanyBroker = (company_id) => {
    dispatch(retrieveCompanyUsers(company_id));
  }

  const getBrokerProfileData = async (brokerId) => {
    if (brokerId) {
      const brokerProfile = await BrokerageService.getBrokerProfile(brokerId);
      setBrokerProfile(brokerProfile.data);
    } else {
      setBrokerProfile(null);
    }
  }

  useEffect(() => {
    if (getValues("country") === "USA") {
      setSelectedUSA(true);
    } else {
      setSelectedUSA(false);
    }
  }, [watch("country")]);



  /* Handle Function for From Inputs */
  const handleRegistration = (formdata) => {
    const office = {};
    dispatch(setLoadingStatus(true));
    Object.keys(formdata).forEach(key => {
      if (formdata[key] && formdata[key] !== '') {
        office[key] = formdata[key];
      }
    })
    office['employing_broker_id'] = formdata['employing_broker_id'] || null;
    // office.office_phone = phoneFigure(office.office_phone);
    if (isAddMode) {
      dispatch(createOffice(office))
        .then((response) => {
          setFinishSubmit(true);
          dispatch(setLoadingStatus(false));
          setTimeout(() => {
            history.push("/offices");
          }, 2000);
        })
        .catch((e) => {
          dispatch(setLoadingStatus(false));
          alert(e.message);
        });
    } else {
      dispatch(updateOffice(office_id, office))
        .then((response) => {
          setFinishSubmit(true);
          dispatch(setLoadingStatus(false));
          setTimeout(() => {
            history.push("/offices");
          }, 2000);
        })
        .catch((err) => {
          dispatch(setLoadingStatus(false));
          alert(err.message);
        });
    }
  };

  const handleError = (errors) => {
    console.log("Errors = ", errors);
  };

  const setAddressFields = (office_id) => {
    const officeData = mlsOffices.find((office) => office.OfficeId == office_id);
    if (!officeData) {
      for (const key in mlsApiFields) {
        setValue(key, null);
      }
      return;
    }
    for (const key in mlsApiFields) {
      if (mlsApiFields.hasOwnProperty.call(mlsApiFields, key)) {
        const element = mlsApiFields[key];
        setValue(key, officeData[mlsApiFields[key]] ? officeData[mlsApiFields[key]] : null);
      }
    }
  }

  // useEffect(() => {
  //   let number = phoneFigure(getValues('office_phone'));
  //   setValue('office_phone', phoneNumber(number));
  // }, [watch('office_phone')]);

  return (
    <div className="uk-container page">
      <ul className="uk-breadcrumb">
        <li>
          <Link to={"/offices"}>Offices</Link>
        </li>
        <li className="uk-disabled">
          <a>{isAddMode ? "Add a Office" : "Edit a Office"}</a>
        </li>
      </ul>

      <h1>{isAddMode ? "Add Office" : "Edit Office"}</h1>

      {isFinishSubmit ? (
        isAddMode ? (
          <div className="uk-alert uk-alert-primary" data-uk-alert>
            <p>Added Successfully</p>
          </div>
        ) : (
          <div className="uk-alert uk-alert-primary" data-uk-alert>
            <p>Updated Successfully</p>
          </div>
        )
      ) : undefined}

      <div className="form-container uk-margin-small">
        <form
          onSubmit={handleSubmit(handleRegistration, handleError)}
          className="uk-grid-small form"
          data-uk-grid
          id="addform"
          method="POST"
          onReset={reset}
        >
          <div className="form-row uk-first-column">
            {isAdmin && (
              <div className="one-column uk-padding-small">
                <label className="uk-form-label" htmlFor="company_name">
                  Company*
                </label>
                <select className="uk-select" {...register("company_id")}>
                  <option disabled selected defaultValue={null}>Select a Company</option>
                  {dataCompanies && dataCompanies.length > 0 && dataCompanies.map((company) => {
                    return (
                      <option value={company.id} key={company.id}>
                        {company.company_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="office_name">
                Office Name*
              </label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.office_name,
                })}
                type="text"
                id="office_name"
                {...register("office_name")}
              />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="name">Office Phone</label>
              <input type="hidden" required name="office_phone" {...register("office_phone")} />
              <PhoneInput
                placeholder='+'
                country={'us'}
                inputClass={classes.phone_input}
                containerClass={classes.phone_container}
                autoFocus={false}
                onFocus={() => { }}
                value={getValues("office_phone")}
                onChange={phone => { setValue('office_phone', phone, { shouldValidate: true }) }}
              />
            </div>
            {
              !isAdmin && showMlsOfficeId && mlsOffices &&
              <div className="one-column uk-padding-small">
                <label className="uk-form-label" htmlFor="mls_office_id">
                  MLS Office ID
                </label>
                <select className="uk-select" {...register("mls_office_id")}
                  defaultValue=""
                  onChange={(event) => {
                    setValue("mls_office_id", event.target.value)
                    setAddressFields(event.target.value);
                  }}
                >
                  <option selected value="">Select Office</option>
                  {mlsOffices.map((mlsOffice) => {
                    return (
                      <option value={mlsOffice.OfficeId} key={mlsOffice.OfficeId}>
                        {`${mlsOffice.OfficeId} - ${mlsOffice.OfficeName}`}
                      </option>
                    );
                  })}
                </select>
              </div>
            }
          </div>
          <div className="form-row uk-first-column">
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="address_1">
                Address 1
              </label>
              <input
                className="uk-input"
                type="text"
                id="address_1"
                {...register("address_1")}
              />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="address_2">
                Address 2
              </label>
              <input
                className="uk-input"
                type="text"
                id="address_2"
                {...register("address_2")}
              />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="address_3">
                Address 3
              </label>
              <input
                className="uk-input"
                type="text"
                id="address_3"
                {...register("address_3")}
              />
            </div>
          </div>
          <div className="form-row uk-first-column">
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="country">
                Country
              </label>
              <select className="uk-select" {...register("country")}>
                {Country.map((country) => {
                  return (
                    <option value={country.code} key={country.code}>
                      {country.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="state">
                State
              </label>
              {isSelectedUSA ? (
                <select className="uk-select" {...register("state")}>
                  <option value="" key=""></option>
                  {StatesForUSA.map((state) => {
                    return (
                      <option value={state.code} key={state.code}>
                        {state.name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <input
                  className="uk-input"
                  type="text"
                  id="state"
                  {...register("state")}
                />
              )}
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="city">
                City
              </label>
              <input
                className="uk-input"
                type="text"
                id="city"
                {...register("city")}
              />
            </div>
          </div>
          <div className="form-row uk-first-column">
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="postal_code">
                Postal Code
              </label>
              <input
                className="uk-input"
                type="text"
                id="postal_code"
                {...register("postal_code")}
              />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label" htmlFor="timezone">
                Time Zone
              </label>
              {/* <input className='uk-input' type='text' id='timezone' {...register('timezone')} /> */}
              <TimezoneList register={register} />
            </div>
            <div className="one-column uk-padding-small">
              <label className="uk-form-label ">Sales Tax ID#</label>
              <input
                className={clsx("uk-input", {
                  "uk-form-danger": errors.sales_tax_id,
                })}
                type="text"
                {...register("sales_tax_id")}
              />
            </div>
          </div>
          <div className="form-row uk-first-column">
            <div className="one-column uk-padding-small">
              <div class="uk-grid">
                {/* <div class="uk-width-1-2">...</div> */}
                <div class="uk-width-1-1">
                  <div class="uk-grid">
                    <div class="uk-width-1-2 uk-width-medium-2-5 uk-pull-2-5"><label className="uk-form-label">Employing Broker</label></div>

                    <div class="uk-width-1-2 uk-width-medium-2-5 uk-push-3-5">
                      <div className="uk-flex uk-flex-center@m uk-flex-right@l">
                        <button type="button" onClick={() => history.push("/users")} className="uk-button uk-button-link" style={{ marginRight: "7px", fontSize: "small", textTransform: "none" }}>+Add New</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <select
                className={clsx("uk-select", { 'uk-form-danger': errors.employing_broker_id })}
                {...register('employing_broker_id')}
                onChange={(event) => getBrokerProfileData(event.target.value)}
              // onChange={(event) => fetchBrokerData(event.target.value)}
              >
                <option value="" key={`Default_broker`}>
                  Please select broker
                </option>
                {officeUsers && officeUsers.length > 0 && officeUsers.map((broker) => {
                  return (
                    <option value={broker.id} key={broker.id} selected={getValues("employing_broker_id") == broker.id}>
                      {`${broker.last_name} ${broker.first_name}`}
                    </option>
                  );
                })}
              </select>
            </div>
            {
              brokerProfile &&
              <>
                <FormInputWrapper label='License #'>
                  <input
                    className="uk-input"
                    type="text"
                    value={brokerProfile.license_code ? brokerProfile.license_code : ""}
                    disabled
                  />
                </FormInputWrapper>
                <FormInputWrapper label="License Expiration Date">
                  <DatePicker
                    className="uk-input"
                    style={{ cursor: "pointer", color: "#000" }}
                    format={'MM/DD/YYYY'}
                    disabled={true}
                    value={dayjs(moment(brokerProfile?.expiration_date).utc().format("yyyy-MM-DD"))}
                  />
                </FormInputWrapper>
              </>
            }
          </div>
          <div className="uk-width-1-3@s uk-column-1-1">
            <input
              type="submit"
              className="uk-button iy-button-primary"
              id="add-office-save"
              value={isAddMode ? "Add" : "Save"}
            />
            <Link to={"/offices"}>
              <button className="uk-button uk-button-default" id="add-cancel">
                Cancel
              </button>
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OfficeForm;
