import React, { Fragment } from 'react';
import { Button, Drawer, Space } from 'antd';

import PanelFormVessel from '../forms/PanelFormVessel';
import PanelFormBuyer from '../forms/PanelFormBuyer';
import PanelFormCoBuyer from '../forms/PanelFormCoBuyer';
import PanelFormSeller from '../forms/PanelFormSeller';
import PanelFormCoSeller from '../forms/PanelFormCoSeller';
import PanelFormToysAndTenders from '../forms/PanelFormToysAndTenders';
import PanelFormTrailer from '../forms/PanelFormTrailer';
import PanelFormOffer from '../forms/PanelFormOffer';
import PanelFormTerms from '../forms/PanelFormTerms';
import PanelFormExclusions from '../forms/PanelFormExclusions';
import PanelFormCoBrokerAndCommission from '../forms/PanelFormCoBrokerAndCommission';
import PanelFormTradeInVessel from '../forms/PanelFormTradeInVessel';
import PanelFormIYBAPurchaseAndSaleAddendum from '../forms/PanelFormIYBAPurchaseAndSaleAddendum';
import PanelFormIYBAPurchaseAndSaleAgreementExclusion from '../forms/PanelFormIYBAPurchaseAndSaleAgreementExclusion';
import PanelFormAcceptanceOfVessel from '../forms/PanelFormAcceptanceOfVessel';
import PanelFormSellerClosingStatement from '../forms/PanelFormSellerClosingStatement';
import PanelFormBuyerClosingStatement from '../forms/PanelFormBuyerClosingStatement';
import { Checkbox, FormControlLabel } from '@mui/material';
import PanelFormYBAAAmendmentToPSAShortForm from '../forms/PanelFormYBAAAmendmentToPSA(Short_Form)';

const FormDataDrawer = ({ show, onClose, formType, multiFormId = null, menuType ,handleOpenPanel}) => {

    const renderForms = () => {
        if (show) {
            switch (formType) {
                case 'Vessel':
                    return <PanelFormVessel closePanel={onClose} />

                case 'Buyer':
                    return <PanelFormBuyer closePanel={onClose} menuType={menuType} />

                case 'Co-Buyer':
                    return <PanelFormCoBuyer closePanel={onClose} menuType={menuType} />

                case 'Seller':
                    return <PanelFormSeller closePanel={onClose} menuType={menuType} />

                case 'Co-Seller':
                    return <PanelFormCoSeller closePanel={onClose} menuType={menuType} />

                case 'Toys & Tenders':
                    return <PanelFormToysAndTenders closePanel={onClose} />

                case 'Trailer':
                    return <PanelFormTrailer closePanel={onClose} />

                case 'Offer':
                    return <PanelFormOffer closePanel={onClose} />

                case 'Terms':
                    return <PanelFormTerms closePanel={onClose} />

                case 'Exclusions':
                    return <PanelFormExclusions closePanel={onClose} />

                case 'Co-Broker & Commission':
                    return <PanelFormCoBrokerAndCommission closePanel={onClose} />

                case 'Trade-In Vessel':
                    return <PanelFormTradeInVessel closePanel={onClose} />

                case 'Seller Closing':
                    return <PanelFormSellerClosingStatement closePanel={onClose} menuType={menuType} />

                case 'Buyer Closing':
                    return <PanelFormBuyerClosingStatement closePanel={onClose} menuType={menuType} handleOpenPanel={handleOpenPanel}/>

                case 'IYBA Purchase & Sale Addendum':
                    return <PanelFormIYBAPurchaseAndSaleAddendum closePanel={onClose} addendumId={multiFormId} />

                case 'IYBA Purchase & Sale Agreement Exclusion':
                    return <PanelFormIYBAPurchaseAndSaleAgreementExclusion closePanel={onClose} exclusionId={multiFormId} />

                case 'IYBA Acceptance of Vessel':
                    return <PanelFormAcceptanceOfVessel closePanel={onClose} acceptanceId={multiFormId} />

                case 'YBAA Amendment to PSA (Short Form)':
                    return <PanelFormYBAAAmendmentToPSAShortForm closePanel={onClose} addendumId={multiFormId} />
                default:
                    return <></>;
            }
        }
    }

    return (
        <Drawer
            rootClassName="form-drawer"
            keyboard={false}
            maskClosable={false}
            title={formType==="YBAA Amendment to PSA (Short Form)" ?formType: `${formType} Form`}
            placement="left"
            size='large'
            onClose={() => onClose(false)}
            closable={false}
            open={show}
            extra={
                <Space>
                    {(formType === "Buyer Closing" || formType === "Seller Closing") && <span className='text-danger'>Click "Save" to verify & activate</span>}
                    <Button type='text' onClick={() => onClose(false)}><i className="fa fa-times-circle fa-lg"></i></Button>
                </Space>
            } >
            <Fragment>
                {renderForms()}
            </Fragment>
        </Drawer>
    )
}

export default FormDataDrawer;