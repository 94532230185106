import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { toast } from "react-toastify";
import { setLoadingStatus } from "../../../../../redux/actions/loading";
import { eSignAcceptance, getTransactionEdocById, updateOptionsRequiredFields } from "../../../../../redux/actions/transaction";
import Auth from "../../../../../utils/auth";
import { convertFirstCharIntoSmallCase, isSigned, renderMaskedValue, unauthorizedErrorToast } from "../../../../../utils/functions";
import { useQueryParams } from "../../../../../utils/query-params";
import {
  CORPORATE_OWNED,
  ENTITY_AND_OR_ASSIGN,
  JOINT_OWNER,
  LEGAL_AND_OR_ASSIGN,
  LEGAL_OR_ASSIGN,
  LEGAL_OWNER,
  LLC_OWNED,
  OWNER_OF_RECORD,
  TRUST_OWNED
} from "../../../../../utils/signer";
import FormInputWrapper from "../../../../common/FormInputWapper";
import FormRow from "../../../../common/FormRow";
import HandSignUploadModal from "../../../../common/HandSignUploadModal";
import SignatureMessageModal from "../../../../common/SignatureMessageModal";
import { ViewDocumentsModal } from "../../../../common/ViewDocumentsModal";
import CustomActionButtons from "./CustomActionButtons";
import CustomRenderStatus from "./CustomRenderStatus";

const useStyles = makeStyles({
  previewSendDiv: {
    width: "250px !important"
  },
  handSignedDiv: {
    marginTop: "-4px"
  }
});

/**
 * @param {Function} updateDocumentObj // It is used to update payload object
 * @param {String} selectedRole //It is used to check which role is selected from signature tab
 * @param {Object} payload //Prepared object for the back-end
 * @param {Function} makeDropDownSigners // Send signers array to the signature tab for displaying signer drop-down
 * @returns 
 */
const EsignVesselAcceptance = ({ transactionId, makeDropDownSigners, updateDocumentObj, selectedRole, payload, docId, roles, menuType }) => {

  const dispatch = useDispatch();

  const query = useQueryParams();
  const doc_id = docId ? docId : query.get('doc_id') || null;

  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [showAdditionalModal, setShowAdditionalModal] = useState(false);
  const [emailDetailsToSend, setEmailDetailsToSend] = useState(null);
  const [showHandsignModal, setShowHandsignModal] = useState(false);
  const [handSignDetails, setHandSignDetails] = useState(null);
  const [showDocument, setShowDocument] = useState(false);
  const [document, setDocument] = useState(null);
  const loginEmail = Auth.getInstance().getUserData().email;
  const [acceptance, setAcceptance] = useState(null);
  //Get logged User
  const user = Auth.getInstance().getUserData();
  const classes = useStyles();

  const deals = useSelector((state) => state.transactions);

  const dataTransaction = deals?.transaction || {};
  const dataBuyer = deals.transaction?.buyer || null;
  const dataCoBuyer = deals.transaction?.co_buyer || null;
  const dataSeller = deals.transaction?.seller || null;
  const dataCoSeller = deals.transaction?.co_seller || null;
  const dataVessel = deals.transaction?.t_vessel || null;
  const dataAcceptance = deals.transaction?.t_acceptance || [];
  const requiredFields = deals?.requiredOptionFields[`acceptanceOfVessel_${acceptance?.status??0}`] || [];

  //Take a state for the store the acceptance data

  const eDoc = (dataTransaction?.t_edocuments || []).find(doc => doc.id === doc_id) || null;
  const signers = eDoc && eDoc.request_signature ? eDoc.request_signature.signers : [];

  const typeOfAcceptance = [
    "Accept",
    "Conditional Accept",
    "Conditional Acceptance with Survey Allowance",
    "Reject",
  ];

  const titles = [
    "Acceptance of Vessel",
    "Conditional Acceptance of Vessel",
    "Conditional Acceptance with Survey Allowance of Vessel",
    "Rejection of Vessel",
  ];

  //Define links for this page
  const links = [
    { label: "Deals", link: `/deals/view/${transactionId}/main` },
    {
      label:
        acceptance && acceptance.status
          ? titles[acceptance.status]
          : "Acceptance of Vessel",
      link: "",
    },
  ];

  //useEffect to find the exclusion data.
  useEffect(() => {
    const doc_id = docId ? docId : query.get('doc_id') || null;
    const acceptance = dataAcceptance.find(el => el.id == doc_id);
    if (doc_id && acceptance) {
      setAcceptance(acceptance);
    }
  }, [dataAcceptance, query, dataTransaction]);

  /**
* useEffect to generate the array of signers which is available 
* for this document only
*/
  useEffect(() => {

    if (!updateDocumentObj || !isValidSend()) {
      return;
    }

    let signerArr = [];

    if (dataTransaction?.co_buyer && !isSigned(signers, "co_buyer")) {
      signerArr.push("co_buyer");
    }

    if (dataTransaction?.co_seller && !isSigned(signers, "co_seller")) {
      signerArr.push("co_seller");
    }

    if (dataTransaction?.buyer && !isSigned(signers, "buyer")) {
      signerArr.push("buyer");
    }

    if (dataTransaction?.seller && !isSigned(signers, "seller")) {
      signerArr.push("seller");
    }

    makeDropDownSigners(signerArr);

  }, [dataTransaction, signers.length])


  useEffect(() => {
    registerRequiredFields(acceptance);
  }, [dataTransaction]);

  const registerRequiredFields = (acceptance) => {
    switch (acceptance?.status) {
      case 0:
        dispatch(
          updateOptionsRequiredFields({
            [`acceptanceOfVessel_${acceptance?.status}`]: [],
          })
        );
        break;
      case 1:
        let fields = [];
        if (!acceptance.days_to_complete_conditions) {
          fields.push({ days_to_complete_conditions: "Days to Complete Conditions" });
        }
        if (acceptance.condition_1 || acceptance.condition_2) {
          dispatch(
            updateOptionsRequiredFields({
              [`acceptanceOfVessel_${acceptance?.status}`]: [],
            })
          );
        } else {
          fields.push({ condition_1: "Condition 1" })
          dispatch(
            updateOptionsRequiredFields({
              [`acceptanceOfVessel_${acceptance?.status}`]: fields,
            })
          );
        }
        break;
      case 2:
        if (
          !!acceptance.adjustment_amount &&
          (!!acceptance.condition_1 || !!acceptance.condition_2)
        ) {
          dispatch(
            updateOptionsRequiredFields({
              [`acceptanceOfVessel_${acceptance?.status}`]: [],
            })
          );
        } else {
          let fields = [];
          if (!acceptance.adjustment_amount) {
            fields.push({ adjustment_amount: "Adjustment Amount" });
          }
          if (!acceptance.condition) {
            fields.push({ condition: "Condition" });
          }

          dispatch(
            updateOptionsRequiredFields({
              [`acceptanceOfVessel_${acceptance?.status}`]: fields,
            })
          );
        }
        break;
      case 3:
        if (!acceptance.reason_for_rejection) {
          dispatch(
            updateOptionsRequiredFields({
              [`acceptanceOfVessel_${acceptance?.status}`]: [{ reason_for_rejection: "Reason For Rejection" }],
            })
          );
        } else {
          dispatch(
            updateOptionsRequiredFields({
              [`acceptanceOfVessel_${acceptance?.status}`]: [],
            })
          );
        }
        break;
      default:
        break;
    }
  };

  //Apply use Effect when role is changed from the signature tab
  useEffect(() => {

    /**
    * Check selected role is included for this document &
    * Check the selected role's status is not equal to signed &
    * Check this document has proper data for the sign this document.
    */
    if (selectedRole && roles.includes(selectedRole)
      && (signers && signers.find(signer => convertFirstCharIntoSmallCase(signer.request_signer.role) === selectedRole)?.status != "Signed")
      && dataTransaction && isValidSend()) {


      switch (selectedRole) {
        case "co_buyer":
          if (!dataTransaction.co_buyer) {
            return;
          }
          break;
        case "co_seller":
          if (!dataTransaction?.co_seller) {
            return;
          }
          break;
        default:
          break;
      }

      const doc_id = docId ? docId : query.get('doc_id') || null;
      const acceptanceData = dataAcceptance.find(el => el.id == doc_id);

      //Prepare object for the payload
      let obj = {}
      obj[selectedRole] = {
        document_name: "acceptance-of-vessel",
        id: acceptanceData?.id
      };

      //Update payload object
      updateDocumentObj(obj, selectedRole);
      return;
    }
  }, [selectedRole])


  //Define vessel name to show
  const vesselName = `${dataVessel?.length || ""}' ${dataVessel?.make || ""} ${dataVessel?.model || ""} ${dataVessel?.year || ""}` !== "'   "
    ? `${dataVessel?.length || ""}' ${dataVessel?.make || ""} ${dataVessel?.model || ""} ${dataVessel?.year || ""}`
    : "N/A";

  //Define co buyer name to show
  const coBuyerName = dataCoBuyer
    ? `${dataCoBuyer.first_name || ""} ${dataCoBuyer.last_name || ""}` === " "
      ? "N/A"
      : `${dataCoBuyer.first_name || ""} ${dataCoBuyer.last_name || ""}`
    : "N/A";

  //Define co seller name to show
  const coSellerName = dataCoSeller
    ? `${dataCoSeller.first_name || ""} ${dataCoSeller.last_name || ""}` === " "
      ? "N/A"
      : `${dataCoSeller.first_name || ""} ${dataCoSeller.last_name || ""}`
    : "N/A";

  /**
   * @param {Object} evt // Event Object
   * @param {String} role
   */
  const previewByStep = (evt, role) => {

    if (!acceptance?.id) {
      toast.error("Please add acceptance detail.", {
        autoClose: 2000
      });
      return;
    }

    dispatch(setLoadingStatus(true));
    dispatch(eSignAcceptance(acceptance?.id, "preview", role)).then((res) => {
      dispatch(setLoadingStatus(false));
      if (res.status !== 200) {
        throw res;
      } else {
        window.open(res.data.url + '?tm=' + (new Date()).getTime(), '_blank');
      }
    }).catch((err) => {
      dispatch(setLoadingStatus(false));
      switch (err.status) {
        case 400:
          setMessage(`${err.data.error.message}`);
          break;
        case 401:
          toast.error("You are not authorized to access this action.")
          break;
        default:
          setMessage(`${err.data.message}`);
          break;
      }
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
    });
  }

  /**
   * @param {Object} evt // Event Object
   * @param {String} mode //It can be "send" or "hand-signed"
   * @param {*} role 
   * @param {*} data // selected date for the hand signed
   */
  const sendByStep = (mode, role, data = null) => {
    let obj = {};
    if (data) {
      if (mode == 'signed') {
        obj["signed_date"] = data.signed_date;
        obj["signed_document"] = JSON.stringify(data.documents);
      } else {
        obj["additional_email_content"] = data.additional_email_content || '';
      }
      obj["selected_recipients"] = data.selectedUser
    }
    obj["is_email_send"] = data.is_email_send ? 'yes' : 'no';
    obj["requested_by"] = user.id;
    dispatch(setLoadingStatus(true));
    dispatch(eSignAcceptance(acceptance?.id, mode, role, obj)).then(res => {
      dispatch(setLoadingStatus(false));
      if (res.status === 200) {
        dispatch(getTransactionEdocById(dataTransaction.id, menuType === 'invitation', dataTransaction?.company_id));
        if (data.signed_date) {
          setMessage("Signed Date saved successfully");
          toast.success(`Hand signed documents uploaded successfully.`);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(undefined);
          }, 3000);
        } else {
          if (!data.is_email_send) {
            setShowAdditionalModal(false);
            toast.success('E-Sign Link generated successfully.');
          } else {
            setMessage("Document sent for eSignature successfully.");
            setSuccess(true);
            setTimeout(() => {
              setSuccess(undefined);
            }, 3000);
          }
        }
      } else {
        switch (res.status) {
          case 400:
            setMessage(`${res.data.error.message}`);
            break;
          case 401:
            unauthorizedErrorToast()
            break;
          default:
            toast.error(`Something went wrong while upload hand signed documents.`);
            setMessage(`${res.data.message}`);
            break;
        }
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 5000);
      }
    });
  }

  const onAdditionalMessageModalOpen = (mode, role, data = null) => {
    const selectedRecipients = eDoc.request_signature?.signers?.find((signer) => signer.request_signer?.role === role) || {};
    setEmailDetailsToSend({ mode: mode, role: role, data: data, selectedRecipients: selectedRecipients.selected_recipients || [] });
    setShowAdditionalModal(true);
  }

  const onCloseAdditionalEmailModal = (isEmailSend, emailAdditionalContent = null) => {
    setShowAdditionalModal(false);
    if (isEmailSend) {
      sendByStep(emailAdditionalContent.mode, emailAdditionalContent.role, { ...emailAdditionalContent.data, is_email_send: true, selectedUser: emailAdditionalContent.data.selected_recipients })
    }
  }

  const onGenerateEsignatureLink = (emailAdditionalContent) => {
    const signatureLink = signers.find(signer => signer.request_signer.role === emailAdditionalContent.role)?.doc_signer_link || '';
    if (signatureLink) {
      setShowAdditionalModal(false);
      toast.success('E-Sign link generated successfully.');
    } else {
      sendByStep(emailAdditionalContent.mode, emailAdditionalContent.role, { ...emailAdditionalContent.data, is_email_send: false, selectedUser: emailAdditionalContent.data.selected_recipients });
    }
  }

  /**
   * On open Hand Sign document selection modal after selecting date
   * @param {*} role 
   * @param {*} signDate 
  */
  const onHandSignUploadModalOpen = (role, signDate) => {
    const selectedRecipients = eDoc.request_signature?.signers?.find((signer) => signer.request_signer?.role === role) || {};
    const client = dataTransaction[role.toLowerCase().replace('-', '_')];
    setHandSignDetails({
      documentName: eDoc.t_esign_template?.template?.template_name,
      signDate: signDate,
      role: role,
      client: client,
      selectedRecipients: selectedRecipients.selected_recipients || []
    });
    if (signDate && signDate != null) {
      setShowHandsignModal(true);
    }
  }

  /**
   * On close Hand Sign modal and submit uploaded document to sendByStep() function
   * @param {*} isUploaded 
   * @param {*} documentUploadFiles 
  */
  const onCloseHandsignModal = (isUploaded, documentUploadFiles = null, selectedUser = []) => {
    setShowHandsignModal(false);
    if (isUploaded) {
      sendByStep("signed", handSignDetails.role, { signed_date: handSignDetails.signDate, documents: documentUploadFiles, selectedUser: selectedUser })
    }
    setHandSignDetails(null);
  }

  /**
   * On open view hand signed uploaded modal
   * @param {*} document 
  */
  const handleOpenViewDocumentModal = (document) => {
    setDocument(document);
    setShowDocument(true);
  }

  /**
   * On close view hand signed uploaded modal
  */
  const handleCloseModal = () => {
    setDocument(null);
    setShowDocument(false);
  }

  /**
   * Check this document is valid for preview or not
   * @returns True or False
   */
  const isValidPreview = () => {
    return !deals.requiredFields.buyer.some(obj => Object.keys(obj).includes('email')) &&
      deals.requiredOptionFields[`acceptanceOfVessel_${acceptance?.status??0}`]?.length === 0;
  };

  /**
   * Check this document is valid for send or not
   * @returns True or False
   */
  const isValidSend = () => {
    let valid = !deals.requiredFields.buyer.some(obj => Object.keys(obj).includes('email')) &&
      deals.requiredOptionFields[`acceptanceOfVessel_${acceptance?.status??0}`]?.length === 0;

    if (dataTransaction.co_buyer_contact_id) {
      valid = valid && !deals.requiredFields.co_buyer.some(obj => Object.keys(obj).includes('email'))
    }

    return valid;
  };


  /**
   * This function is used to show a signed date of document.
   * @param {String} role 
   * @param {Date} signedDate 
   * @returns Date
   */
  const handleSignedDate = (role, signedDate) => {
    if (signedDate) {
      return moment(signedDate ? signedDate : null).utc().format("MM-DD-yyyy")
    }

    if (!signedDate) {
      if (eDoc.request_signature.signers.find(signer => signer.request_signer.role === role)?.status === 'Signed') {
        const timezone = moment.tz(moment.tz.guess()).zoneAbbr();
        return `${moment(eDoc.request_signature.signers.find(signer => signer.request_signer.role === role)?.esigned_date || null).local().format("MM-DD-yyyy hh:mm A")} ${timezone}`;
      }
    }
    return null;
  }

  /**
   * Provides the eSign link for email send for signature
   * @param {*} role 
   * @returns 
  */
  const getEsignLink = (role) => {
    return signers.find(signer => signer.request_signer.role === role)?.doc_signer_link || '';
  }

  const [tooltipText, setTooltipText] = useState('Copy E-Sign Link');

  /**
   * Renders the button for eSign Link according to role of client
   * @param {*} role 
   * @returns Button for Link
   */
  const renderLinkButtons = (role) => {
    if (role.includes('broker') || role.includes('Broker')) {
      return <button style={{ height: '26px' }}
        className="uk-button uk-button-primary"
        onClick={() => { window.open(getEsignLink(role), '_blank'); }}>
        Sign </button>
    }

    return <Tooltip title={tooltipText} arrow>
      <button style={{ height: '26px', padding: '0 4px 0' }}
        className="uk-button uk-button-primary"
        onClick={() => {
          navigator.clipboard.writeText(getEsignLink(role));
          setTooltipText('E-Sign Link Copied..')
          setTimeout(() => {
            setTooltipText('Copy E-Sign Link');
          }, 800);
        }}>Copy E-Sign Link </button>
    </Tooltip>
  }



  /**
   * This fucntion is used to change the payload 
   * as per user manually checks the checkbox of particular checkbox
   * @param {Object} e //Event Object 
   * @param {String} role 
   * @returns 
   */
  const checkBoxandler = (e, role) => {
    let obj = {};
    obj[role] = {
      document_name: "acceptance-of-vessel",
      id: acceptance?.id
    };
    updateDocumentObj(obj, role, !e.target.checked, true);
  }
  const renderAction = (role) => {
    return (
      <CustomActionButtons
        role={role}
        signers={signers}
        isValidSend={isValidSend}
        isValidPreview={isValidPreview}
        handleOpenViewDocumentModal={handleOpenViewDocumentModal}
        previewByStep={previewByStep}
        onAdditionalMessageModalOpen={onAdditionalMessageModalOpen}
        onHandSignUploadModalOpen={onHandSignUploadModalOpen}
        dataTransaction={dataTransaction}
        deals={deals}
        menuType={menuType}
      />
    )
  }
  const renderStatus = (role) => {
    return (<CustomRenderStatus
      role={role}
      signedDate={signers.find(signer => signer.request_signer.role === role)?.signed_date}
      signers={signers}
      isValidSend={isValidSend}
      isValidPreview={isValidPreview}
      handleOpenViewDocumentModal={handleOpenViewDocumentModal}
      previewByStep={previewByStep}
      onAdditionalMessageModalOpen={onAdditionalMessageModalOpen}
      onHandSignUploadModalOpen={onHandSignUploadModalOpen}
      dataTransaction={dataTransaction}
      deals={deals}
      eDoc={eDoc}
      getEsignLink={getEsignLink}
      renderLinkButtons={renderLinkButtons}
      handleSignedDate={handleSignedDate}
    />
    )
  }
  return (
    <>
      <div className="uk-container uk-container-small uk-position-relative m-0">
        <div className="scroll-div">
          <div className="form-container scroll-fix-div">
            <div className="esign-send listing-agreement">
              {!updateDocumentObj &&
                <>
                  {isSuccess
                    ? <div className="uk-alert uk-alert-primary" data-uk-alert>
                      <p>{message}</p>
                    </div>
                    : isError
                      ? <div className="uk-alert uk-alert-danger" data-uk-alert>
                        <p>{message}</p>
                      </div>
                      : null}
                </>
              }
              <h4>
                <b>
                  {acceptance && acceptance.status
                    ? titles[acceptance.status]
                    : eDoc?.is_duplicate ? eDoc?.duplicate_title : eDoc?.t_esign_template?.template?.template_name}
                </b>
              </h4>

              <FormRow><hr /></FormRow>
              <FormRow>
                <FormInputWrapper label="Vessel">{vesselName}</FormInputWrapper>
              </FormRow>
              <FormRow><hr /></FormRow>

              {dataSeller && acceptance && (acceptance.status == 2 || acceptance.status == 1) && (
                <>
                  <div className="client-row">
                    <FormInputWrapper label="Seller" className="signer-name-block">
                      {[LEGAL_OWNER, JOINT_OWNER].indexOf(dataTransaction?.seller_type) > -1 && (
                        (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_seller_hide_by_invited_broker)
                          ? renderMaskedValue(`${dataSeller?.first_name} ${dataSeller?.last_name}`.length)
                          : `${dataSeller?.first_name} ${dataSeller?.last_name}`
                      )}
                      {[OWNER_OF_RECORD].indexOf(dataTransaction?.seller_type) > -1 && (
                        (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_seller_hide_by_invited_broker)
                          ? renderMaskedValue(`Owner of Record`.length)
                          : `Owner of Record`
                      )}
                      {[TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.seller_type) > -1 && (
                        (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_seller_hide_by_invited_broker)
                          ? renderMaskedValue(`${dataSeller?.trust_name}`.length)
                          : `${dataSeller?.trust_name}`
                      )}
                      {!([LEGAL_OWNER, TRUST_OWNED, OWNER_OF_RECORD, JOINT_OWNER, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.seller_type) > -1) && (
                        `N/A`
                      )}
                    </FormInputWrapper>
                    <FormInputWrapper label="Seller's Email" className="signer-email-block">
                      {[LEGAL_OWNER, OWNER_OF_RECORD, JOINT_OWNER].indexOf(dataTransaction?.seller_type) > -1 && (
                        (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_seller_hide_by_invited_broker)
                          ? renderMaskedValue(`${dataSeller?.email}`.length)
                          : `${dataSeller?.email}`
                      )}
                      {[TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.seller_type) > -1 && (
                        (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_seller_hide_by_invited_broker)
                          ? renderMaskedValue(`${dataSeller?.trust_signer_email}`.length)
                          : `${dataSeller?.trust_signer_email}`
                      )}
                      {!([LEGAL_OWNER, TRUST_OWNED, OWNER_OF_RECORD, JOINT_OWNER, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.seller_type) > -1) && (
                        `N/A`
                      )}
                    </FormInputWrapper>
                    <div className={!updateDocumentObj && "signers_div"}>
                      {signers && signers.find(signer => signer.request_signer.role === 'Seller')?.status
                        ? renderStatus("Seller")
                        : renderAction("Seller")}
                    </div>

                    {eDoc && updateDocumentObj && !isSigned(signers, "Seller") &&
                      <div className="col-1">
                        <input
                          type="checkbox"
                          className="signatures_checkbox"
                          onChange={(e) => { checkBoxandler(e, "seller"); }}
                          checked={payload?.seller?.find((obj) => obj.document_name == eDoc.task_type ? true : false) || false}
                          disabled={
                            (!dataTransaction || !isValidSend(deals.requiredFields)) || selectedRole && selectedRole != "seller" || (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_seller_hide_by_invited_broker)
                          }
                        />
                      </div>
                    }
                  </div>
                  <FormRow><hr /></FormRow>
                </>
              )}

              {dataCoSeller && acceptance && (acceptance.status === 2 || acceptance.status === 1) && (
                <>
                  <div className="client-row">
                    <FormInputWrapper label="Co-Seller" className="signer-name-block">{
                      (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_co_seller_hide_by_invited_broker)
                        ? renderMaskedValue(`${coSellerName}`.length)
                        : `${coSellerName}`
                    }</FormInputWrapper>
                    <FormInputWrapper label="Co-Seller's Email" className="signer-email-block">
                      {
                        (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_co_seller_hide_by_invited_broker)
                          ? renderMaskedValue(`${dataCoSeller.email}`.length)
                          : dataCoSeller.email || "N/A"
                      }
                    </FormInputWrapper>
                    <div className={!updateDocumentObj && "signers_div"}>
                      {signers && signers.find(signer => signer.request_signer.role === "Co-Seller")?.status
                        ? renderStatus("Co-Seller")
                        : renderAction("Co-Seller")}
                    </div>
                    {eDoc && updateDocumentObj && !isSigned(signers, "Co-Seller") &&
                      <div className="col-1">
                        <input
                          type="checkbox"
                          className="signatures_checkbox"
                          onChange={(e) => { checkBoxandler(e, "co_seller"); }}
                          checked={payload?.co_seller?.find((obj) => obj.document_name == eDoc.task_type ? true : false) || false}
                          disabled={
                            (!dataTransaction || !isValidSend(deals.requiredFields)) || selectedRole && selectedRole != "co_seller" || (menuType === 'invitation' && dataTransaction?.co_broker?.selling_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_co_seller_hide_by_invited_broker)
                          }
                        />
                      </div>
                    }
                  </div>
                  <FormRow><hr /></FormRow>
                </>
              )}

              {/* {
                  menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail ? <></> : */}
              <>
                <div className="client-row">
                  <FormInputWrapper label="Buyer" className="signer-name-block">
                    {[LEGAL_OWNER, LEGAL_OR_ASSIGN, JOINT_OWNER, LEGAL_AND_OR_ASSIGN].indexOf(dataTransaction?.buyer_type) > -1 && (
                      (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_buyer_hide_by_invited_broker)
                        ? renderMaskedValue(`${dataBuyer?.first_name} ${dataBuyer?.last_name}`.length)
                        : `${dataBuyer?.first_name} ${dataBuyer?.last_name}`
                    )}
                    {[ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.buyer_type) > -1 && (
                      (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_buyer_hide_by_invited_broker)
                        ? renderMaskedValue(`${dataBuyer?.trust_signer_name}`.length)
                        : `${dataBuyer?.trust_signer_name}`
                    )}
                    {!([LEGAL_OWNER, LEGAL_OR_ASSIGN, JOINT_OWNER, LEGAL_AND_OR_ASSIGN, ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.buyer_type) > -1) && (
                      `N/A`
                    )}
                  </FormInputWrapper>
                  <FormInputWrapper label="Buyer's Email" className="signer-email-block">
                    {[LEGAL_OWNER, LEGAL_OR_ASSIGN, JOINT_OWNER, LEGAL_AND_OR_ASSIGN].indexOf(dataTransaction?.buyer_type) > -1 && (
                      (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_buyer_hide_by_invited_broker)
                        ? renderMaskedValue(`${dataBuyer?.email}`.length)
                        : `${dataBuyer?.email}`
                    )}
                    {[ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.buyer_type) > -1 && (
                      (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_buyer_hide_by_invited_broker)
                        ? renderMaskedValue(`${dataBuyer?.trust_signer_email}`.length)
                        : `${dataBuyer?.trust_signer_email}`
                    )}
                    {!([LEGAL_OWNER, LEGAL_OR_ASSIGN, JOINT_OWNER, LEGAL_AND_OR_ASSIGN, ENTITY_AND_OR_ASSIGN, TRUST_OWNED, LLC_OWNED, CORPORATE_OWNED].indexOf(dataTransaction?.buyer_type) > -1) && (
                      `N/A`
                    )}
                  </FormInputWrapper>
                  <div className={!updateDocumentObj && "signers_div"}>
                    {signers && signers.find(signer => signer.request_signer.role === 'Buyer')?.status
                      ? renderStatus("Buyer")
                      : renderAction("Buyer")}
                  </div>

                  {eDoc && updateDocumentObj && !isSigned(signers, "Buyer") &&
                    <div className="col-1">
                      <input
                        type="checkbox"
                        className="signatures_checkbox"
                        onChange={(e) => { checkBoxandler(e, "buyer"); }}
                        checked={payload?.buyer?.find((obj) => obj.document_name == eDoc.task_type ? true : false) || false}
                        disabled={
                          (!dataTransaction || !isValidSend(deals.requiredFields)) || selectedRole && selectedRole != "buyer" || (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_buyer_hide_by_invited_broker)
                        }
                      />
                    </div>
                  }
                </div>
                <FormRow><hr /></FormRow>
              </>

              {dataCoBuyer && (
                <>
                  <div className="client-row">
                    <FormInputWrapper label="Co-Buyer" className="signer-name-block">{
                      (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_co_buyer_hide_by_invited_broker)
                        ? renderMaskedValue(`${coBuyerName}`.length)
                        : `${coBuyerName}`
                    }</FormInputWrapper>
                    <FormInputWrapper label="Co-Buyer's Email" className="signer-email-block">
                      {
                        (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_co_buyer_hide_by_invited_broker)
                          ? renderMaskedValue(`${dataCoBuyer.email}`.length)
                          : dataCoBuyer.email || "N/A"
                      }
                    </FormInputWrapper>
                    <div className={!updateDocumentObj && "signers_div"}>
                      {signers && signers.find(signer => signer.request_signer.role === "Co-Buyer")?.status
                        ? renderStatus("Co-Buyer")
                        : renderAction("Co-Buyer")}
                    </div>

                    {eDoc && updateDocumentObj && !isSigned(signers, "Co-Buyer") &&
                      <div className="col-1">
                        <input
                          type="checkbox"
                          className="signatures_checkbox"
                          onChange={(e) => { checkBoxandler(e, "co_buyer"); }}
                          checked={payload?.co_buyer?.find((obj) => obj.document_name == eDoc.task_type ? true : false) || false}
                          disabled={
                            (!dataTransaction || !isValidSend(deals.requiredFields)) || selectedRole && selectedRole != "co_buyer" || (menuType === 'invitation' && dataTransaction?.co_broker?.listing_broker_email === loginEmail) || (menuType !== 'invitation' && dataTransaction.is_co_buyer_hide_by_invited_broker)
                          }
                        />
                      </div>
                    }
                  </div>
                  <FormRow><hr /></FormRow>
                </>
              )}

              <br />
              {requiredFields && requiredFields.length > 0 && !isValidSend(deals.requiredFields) && (
                <>
                  <p>Required fields for Acceptance of Vessel.</p>
                  <div className="form-row uk-padding-small">
                    <label className="uk-form-label" htmlFor="name">
                      {typeOfAcceptance[acceptance?.status]}
                    </label>
                    <div>
                      {requiredFields.map((obj, key) => {
                        return key === requiredFields.length - 1
                          ? `${Object.values(obj)[0]}`
                          : `${Object.values(obj)[0]}, `;
                      })}
                    </div>
                  </div>
                  {deals.requiredFields.buyer && deals.requiredFields.buyer.some(obj => Object.keys(obj).includes('email')) && (
                    <div className="form-row uk-padding-small">
                      <p style={{ color: '#5479A3' }}>Required fields for Buyer.</p>
                      <div>
                        {deals.requiredFields.buyer.map((obj, key) => {
                          return Object.values(obj)[0] == 'Email'
                            ? `${Object.values(obj)[0]}`
                            : ``;
                        })}
                      </div>
                    </div>
                  )}

                  {dataCoBuyer && deals.requiredFields.co_buyer.some(obj => Object.keys(obj).includes('email')) && (
                    <div className="form-row uk-padding-small">
                      <p style={{ color: '#5479A3' }}>Required fields for Co-Buyer.</p>
                      <div>
                        {deals.requiredFields.co_buyer.map((obj, key) => {
                          return Object.values(obj)[0] == 'Email'
                            ? `${Object.values(obj)[0]}`
                            : ``;
                        })}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div >
        </div >

        <br />
      </div >
      <SignatureMessageModal
        show={showAdditionalModal}
        onClose={(isEmailSend, emailAdditionalContent = null) => onCloseAdditionalEmailModal(isEmailSend, emailAdditionalContent)}
        emailDetail={emailDetailsToSend}
        onGenerateLink={onGenerateEsignatureLink} />
      {showHandsignModal && <HandSignUploadModal show={showHandsignModal} onClose={onCloseHandsignModal} signData={handSignDetails} />}
      {showDocument && <ViewDocumentsModal documentTask={document} onClose={handleCloseModal} />}
    </>
  );
};

export default EsignVesselAcceptance;
