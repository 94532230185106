import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import Form from "../../../../common/Form";
import FormRow from "../../../../common/FormRow";
import FormInputWrapper from "../../../../common/FormInputWapper";
import { setLoadingStatus } from '../../../../../redux/actions/loading';
import { getFormattedData, getPlainTransactionData } from "../../../../../utils/stepFilter";
import { getTransactionEdocById, getTransactionEsignById, upsertTransaction } from "../../../../../redux/actions/transaction";
import { ClassicEditor, Bold, Essentials, Italic, Mention, Paragraph, Undo, Strikethrough, Heading, Subscript, Superscript, List, TodoList, Underline, ListProperties, Markdown } from 'ckeditor5';
import clsx from "clsx";
import * as yup from "yup";
import { CKEditor } from '@ckeditor/ckeditor5-react';

const PanelFormYBAAAmendmentToPSAShortForm = ({ closePanel, addendumId }) => {

    const schema = yup.object().shape({
        ammendment: yup.string(),
        addendum_no: yup.string().required()
    });

    const { register, handleSubmit, setValue, getValues, formState: { isDirty, errors } } = useForm({
        mode: "all",
        resolver: yupResolver(schema),
        shouldFocusError: true,
    });

    const dispatch = useDispatch();

    const [addendum, setAddendum] = useState({});

    const dataTransaction = useSelector((state) => state.transactions.transaction);
    const dataDeal = dataTransaction || {};
    const dataAddendum = dataDeal.t_purchase_addendum;
    const doc_id = addendumId;

    useEffect(() => {
        const ammendment = dataAddendum?.find(el => el.t_edocument_id === doc_id);
        if (ammendment) {
            setAddendum(ammendment);
            setValue('addendum_no', ammendment.addendum_no);
            setValue('ammendment', ammendment.ammendment);
        }
    }, [dataAddendum]);

    const handleRegistration = (ammendment) => {
        dispatch(setLoadingStatus(true));
        let dataStr = "";
        ammendment.ammendment = getValues('ammendment');
        ammendment.addendum_no = getValues('addendum_no');
        if (doc_id) {
            ammendment.id = doc_id;
        }
        if (addendum && addendum.id) {
            ammendment.id = addendum.id;
        }
        dispatch(upsertTransaction({
            step: {
                db: "ybaa_psa_short_esign_addendum",
                main: ammendment,
            },
            transaction: {
                isBelonged: false,
                main: { ...getPlainTransactionData(dataDeal), task_type: "deal" },
            },
        }, "YBAA Amendment to PSA")
        ).then((data) => {
            dispatch(setLoadingStatus(false));
            dispatch(getTransactionEdocById(data.id));
            dispatch(getTransactionEsignById(data.id));
            closePanel(true);
        }).catch((error) => {
            dispatch(setLoadingStatus(false));
            console.log(error);
        });
    };

    const handleError = (error) => {
        console.log(error);
    };

    return (
        <div className="form-container panel-form">
            <Form onSubmit={handleSubmit(handleRegistration, handleError)}>
                <FormRow>
                    <FormInputWrapper label='Amendment Number *' size="two">
                        <input type="text" className={clsx("uk-input", {
                            "uk-form-danger": errors.addendum_no,
                        })} {...register("addendum_no")} />
                    </FormInputWrapper>
                    <FormRow></FormRow>
                    <p>This Purchase and Sale Agreement is subject to the following amendments:</p>
                        <CKEditor
                            name='ammendment'
                            data={getValues('ammendment')}
                            editor={ClassicEditor}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setValue('ammendment', data);
                            }}
                            config={{
                                toolbar: {
                                    items: [
                                        // 'heading',
                                        '|', 'undo', 'redo',
                                        // '|', 'bold', 'italic', 'underline', 'strikethrough', 'subscript', 'superscript',
                                        '|', 'bulletedList', 'numberedList'
                                    ],
                                },
                                plugins: [
                                    Markdown, Undo, Bold, Essentials, Italic, Underline, Mention, Paragraph, Heading, Strikethrough, Subscript, Superscript, List
                                ],
                                placeholder: 'Add Notes'
                            }}
                        />
                </FormRow>
                <FormRow style={{ marginTop: "16px" }}>
                    <button type="submit" className='uk-button uk-button-primary pull-right'>Save</button>
                    <button type="button" className='uk-button uk-button-orange pull-right' onClick={() => closePanel(false)}>Close</button>
                </FormRow>
            </Form>
        </div>
    )
}

export default PanelFormYBAAAmendmentToPSAShortForm;